<nav aria-label="breadcrumb">
    <ol *ngIf="ruta$ | async as ruta" class="breadcrumb">
        <li class="breadcrumb-item"><h6>Productos</h6></li>
        <li class="breadcrumb-item"><h6>{{this.ruta1Str}}</h6></li>
        <li *ngIf="ruta[2]" class="breadcrumb-item"><h6>{{this.ruta2Str}}</h6></li>
    </ol>
</nav>

<app-detalle-producto *ngFor="let r of reset" [hidden]="!productoSeleccionado.id" id="detalleProducto" [productoSeleccionado]="productoSeleccionado" [versionMovil]="esUnDispositivoMovil"></app-detalle-producto>

<div class="row">
    <div class="col">
        <button [disabled]="disablePrev" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="prevPage()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
            </svg>
            {{paginationClickedCount > 0 ? paginationClickedCount : ''}}
        </button>
    </div>
    <div class="col text-right">
        <button [disabled]="disableNext" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="nextPage()">
            {{(paginationClickedCount + 1)}}
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
            </svg>
        </button>
    </div>
</div>

<div *ngIf="tableData as productos" class="row row-cols-1 row-cols-lg-4 mt-3">
    <div *ngFor="let producto of productos" class="col mb-4">
        <div class="card h-100 text-center">
            <a *ngIf="!esUnDispositivoMovil" class="link" (click)="alConsultarProducto(producto)" data-toggle="modal" data-target="#detalleProductoModal">
                <img [src]="producto.urlImagen" class="card-img-top mt-3" style="max-width: 75%; height: auto;">
            </a>
            <a *ngIf="esUnDispositivoMovil" [href]="ruta + '#detalleProducto'" class="link" (click)="alConsultarProducto(producto)">
                <img [src]="producto.urlImagen" class="card-img-top mt-3" style="max-width: 75%; height: auto;">
            </a>
            <div class="card-body">
                <p class="card-text"><small>{{ producto.nombre }}</small></p>
            </div>
            <ul *ngIf="producto.presentaciones as presentaciones" class="list-group list-group-flush">
                <ng-container *ngFor="let presentacion of presentaciones">
                <li *ngIf="presentacion.precio > 0" class="list-group-item">
                    <div class="row">
                        <div class="col">
                            <p class="card-text text-left"><small class="text-muted">{{ presentacion.descripcion }}</small></p>
                        </div>
                        <div class="col text-right">
                            <a class="link" (click)="alAgregarAlCarrito(producto, presentacion)">
                                <small>{{ presentacion.precio | currency: 'MXN' }}</small>
                                <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cart-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                                    <path fill-rule="evenodd" d="M8.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 .5-.5z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </li>
                </ng-container>
            </ul>
        </div>
    </div>
</div>
<div class="row">
    <div class="col">
        <button [disabled]="disablePrev" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="prevPage()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
            </svg>
            {{paginationClickedCount > 0 ? paginationClickedCount : ''}}
        </button>
    </div>
    <div class="col text-right">
        <button [disabled]="disableNext" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="nextPage()">
            {{(paginationClickedCount + 1)}}
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
            </svg>
        </button>
    </div>
</div>