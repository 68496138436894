import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CatalogoMarcasComponent } from './catalogos/catalogo-marcas/catalogo-marcas.component';
import { CatalogoEspeciesComponent } from './catalogos/catalogo-especies/catalogo-especies.component';
import { CatalogoProductosComponent } from './catalogos/catalogo-productos/catalogo-productos.component';
import { CatalogoEdadesComponent } from './catalogos/catalogo-edades/catalogo-edades.component';
import { CatalogoTiposProductoComponent } from './catalogos/catalogo-tipos-producto/catalogo-tipos-producto.component';
import { AparadorComponent } from './aparador/aparador.component';
import { ListadoProductosComponent } from './listado-productos/listado-productos.component';
import { CarritoDeComprasComponent } from './carrito-de-compras/carrito-de-compras.component';
import { RegistroComponent } from './usuarios/registro/registro.component';
import { IngresoComponent } from './usuarios/ingreso/ingreso.component';
import { MiPerfilComponent } from './usuarios/mi-perfil/mi-perfil.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { MisPedidosComponent } from './usuarios/mis-pedidos/mis-pedidos.component';
import { RecuperaContraseniaComponent } from './usuarios/ingreso/recupera-contrasenia/recupera-contrasenia.component';

const routes: Routes = [
  {
    path: '',
    component: AparadorComponent
  },
  {
    path: 'ListadoProductos/Perro/Cachorro',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Perro/Adulto',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Perro/Adulto_7_mas',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Perro/Todas_las_edades',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Gato/Gatito',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Gato/Adulto',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Gato/Adulto_7_mas',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Gato/Todas_las_edades',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Hills',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Diamond',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Royal_Canin',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Nupec',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Pro_Plan',
    component: ListadoProductosComponent
  },
  {
    path: 'ListadoProductos/Eukanuba',
    component: ListadoProductosComponent
  },
  {
    path: 'CatalogoMarcas',
    component: CatalogoMarcasComponent
  },
  {
    path: 'CatalogoEspecies',
    component: CatalogoEspeciesComponent
  },
  {
    path: 'CatalogoEdades',
    component: CatalogoEdadesComponent
  },
  {
    path: 'CatalogoTiposProducto',
    component: CatalogoTiposProductoComponent
  },
  {
    path: 'CatalogoProductos',
    component: CatalogoProductosComponent
  },
  {
    path: 'CarritoDeCompras',
    component: CarritoDeComprasComponent
  },
  {
    path: 'Usuarios/Registro',
    component: RegistroComponent
  },
  {
    path: 'Usuarios/Ingreso',
    component: IngresoComponent
  },
  {
    path: 'Usuarios/Ingreso/RecuperarContrasenia',
    component: RecuperaContraseniaComponent
  },
  {
    path: 'Usuarios/MiPerfil',
    component: MiPerfilComponent
  },
  {
    path: 'Usuarios/MisPedidos',
    component: MisPedidosComponent
  },
  {
    path: 'Pedidos',
    component: PedidosComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
