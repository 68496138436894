export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBQeL8I6m5p931yvVFvP5Siap0ulBDHvPY',
    authDomain: 'morros-y-colas.firebaseapp.com',
    databaseURL: 'https://morros-y-colas.firebaseio.com',
    projectId: 'morros-y-colas',
    storageBucket: 'morros-y-colas.appspot.com',
    messagingSenderId: '847581278222',
    appId: '1:847581278222:web:1215be81ce17bcaddacc5f',
    measurementId: 'G-7S368KTZCX'
  }
};

/*
export const environment = {
  production: false,
  firebaseConfig: {
  apiKey: 'AIzaSyB_IZPbYTyS4BaElrPlcmOaar7XrZMswlM',
  authDomain: 'tianguis-electronico.firebaseapp.com',
  databaseURL: 'https://tianguis-electronico.firebaseio.com',
  projectId: 'tianguis-electronico',
  storageBucket: 'tianguis-electronico.appspot.com',
  messagingSenderId: '960159913984',
  appId: '1:960159913984:web:746664b4263a0ef20a60e5',
  measurementId: 'G-KSF1N5XQSY'
  }
};
*/