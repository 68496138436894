<div class="jumbotron">
    <div class="text-center">
      <img
      src="https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FAparador%2Flogo1.png?alt=media&token=7f589627-e2e4-43d9-8d9a-2db1c4a9fdd6"
      class="img-fluid" style="max-width: 65%;">
    </div>
</div>

<h3>Busca alimento por especie</h3>
<div *ngIf="this.especies$ | async as especies" class="row row-cols-1 row-cols-lg-2">
  <div *ngFor="let especie of especies" class="col mb-4">
    <div class="card h-100">
      <div class="row">
        <div class="col text-center">
          <img
          [src]="especie.urlImagen"
          class="card-img-top"
          style="width: 50%;">
        </div>
      </div>
      <div class="card-header">
        {{ especie.nombre }}
      </div>
      <ul class="list-group list-group-flush">
        <li *ngIf="especie.nombre=='Perro'" class="list-group-item"><a [routerLink]="['ListadoProductos', especie.nombre, 'Cachorro']">Cachorro</a></li>
        <li *ngIf="especie.nombre=='Gato'" class="list-group-item"><a [routerLink]="['ListadoProductos', especie.nombre, 'Gatito']">Gatito</a></li>
        <li class="list-group-item"><a [routerLink]="['ListadoProductos', especie.nombre, 'Adulto']">Adulto</a></li>
        <li class="list-group-item"><a [routerLink]="['ListadoProductos', especie.nombre, 'Adulto_7_mas']">Adulto 7+</a></li>
        <li class="list-group-item"><a [routerLink]="['ListadoProductos', especie.nombre, 'Todas_las_edades']">Todas las edades</a></li>
      </ul>
  </div>
  </div>
</div>

<h3>Busca alimento por marca</h3>
<div *ngIf="this.marcas$ | async as marcas" class="row row-cols-1 row-cols-lg-3 mt-3">
  <div *ngFor="let marca of marcas" class="col mb-4">
    <div class="card text-center h-100">
      <div class="row">
        <div class="col">
          <a [routerLink]="['ListadoProductos', marca.ruteo]">
            <img
              [src]="marca.urlImagen"
              class="card-img-top"
              style="width: 50%;"
              >
          </a>  
        </div>
      </div>
        <div class="card-body">
          <p class="card-text">{{marca.slogan}}</p>
        </div>
        <ul class="list-group list-group-flush">
          <li class="list-group-item"><a [routerLink]="['ListadoProductos', marca.ruteo]">Encuentra productos {{ marca.nombre }}</a></li>
          <li class="list-group-item"><a [href]="marca.sitioWeb" target="_blank">Conoce más de {{ marca.nombre }}...</a></li>
        </ul>
    </div>
  </div>
</div>

<h3>¿Quienes somos?</h3>
<div class="row row-cols-1 row-cols-lg-3 mt-3">
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Nuestra Misión</h5>
        <p class="card-text">Expertos en recomendaciones sobre el cuidado y la alimentación en el perro y el gato, de acuerdo a su etapa o estilo de vida, durante la salud y la enfermedad. Proveer alimento para mascotas de primera calidad, de marcas reconocidas, a la puerta de su casa.</p>
      </div>
    </div>  
  </div>
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Nuestro compromiso</h5>
        <ul>
          <li>Ofrecer solamente alimento de primera calidad de marcas mundialmente reconocidas y con un amplio catálogo de opciones</li>
          <li>Asesorarte en la elección del mejor alimento para tu mascota</li>
          <li>Hacer fácil la compra en nuestro sitio web</li>
          <li>Ofrecerte múltiples medios de pago</li>
          <li>Entregarte en la comodidad de tu domicilio</li>
          <li>Dar seguimiento a todos tus pedidos y resolver todas tus dudas</li>
          <li>Ofrecerte los mejores precios</li>
        </ul>
      </div>
    </div>  
  </div>
  <div class="col mb-4">
    <div class="card h-100">
      <div class="card-body">
        <h5 class="card-title">Nuestra cultura</h5>
        <ul>
          <li>Pasión: Nos apasiona el cuidado y bienestar de las mascotas</li>
          <li>Cercanía: Permanecemos cerca de nuestros clientes en todo momento</li>
          <li>Esfuerzo: Nos esforzamos cada día para lograr la satisfacción de todos nuestros clientes</li>
        </ul>
      </div>
    </div>  
  </div>
</div>
