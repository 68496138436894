import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

/*External Modules */
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { NgxPayPalModule } from 'ngx-paypal';

/*App components */
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MenuProductosComponent } from './menu-productos/menu-productos.component';
import { CatalogoProductosComponent } from './catalogos/catalogo-productos/catalogo-productos.component';
import { CatalogoMarcasComponent } from './catalogos/catalogo-marcas/catalogo-marcas.component';
import { CrearEditarMarcaComponent } from './catalogos/catalogo-marcas/crear-editar-marca/crear-editar-marca.component';
import { CatalogoEspeciesComponent } from './catalogos/catalogo-especies/catalogo-especies.component';

import { environment } from 'src/environments/environment';
import { CrearEditarEspecieComponent } from './catalogos/catalogo-especies/crear-editar-especie/crear-editar-especie.component';
import { CatalogoEdadesComponent } from './catalogos/catalogo-edades/catalogo-edades.component';
import { CrearEditarEdadComponent } from './catalogos/catalogo-edades/crear-editar-edad/crear-editar-edad.component';
import { CatalogoTiposProductoComponent } from './catalogos/catalogo-tipos-producto/catalogo-tipos-producto.component';
import { CrearEditarTipoProductoComponent } from './catalogos/catalogo-tipos-producto/crear-editar-tipo-producto/crear-editar-tipo-producto.component';
import { CrearEditarProductoComponent } from './catalogos/catalogo-productos/crear-editar-producto/crear-editar-producto.component';
import { ImagenesComponent } from './catalogos/catalogo-productos/imagenes/imagenes.component';
import { AparadorComponent } from './aparador/aparador.component';
import { ListadoProductosComponent } from './listado-productos/listado-productos.component';
import { PresentacionesComponent } from './catalogos/catalogo-productos/presentaciones/presentaciones.component';
import { DetalleProductoComponent } from './listado-productos/detalle-producto/detalle-producto.component';
import { CarritoDeComprasComponent } from './carrito-de-compras/carrito-de-compras.component';
import { RegistroComponent } from './usuarios/registro/registro.component';
import { IngresoComponent } from './usuarios/ingreso/ingreso.component';
import { MiPerfilComponent } from './usuarios/mi-perfil/mi-perfil.component';
import { CrearEditarEtapasDeVidaComponent } from './catalogos/catalogo-especies/crear-editar-etapas-de-vida/crear-editar-etapas-de-vida.component';
import { CargaProductosComponent } from './catalogos/catalogo-productos/carga-productos/carga-productos.component';
import { MisPedidosComponent } from './usuarios/mis-pedidos/mis-pedidos.component';
import { PedidosComponent } from './pedidos/pedidos.component';
import { RecuperaContraseniaComponent } from './usuarios/ingreso/recupera-contrasenia/recupera-contrasenia.component';


@NgModule({
  declarations: [
    AppComponent,
    MenuProductosComponent,
    CatalogoProductosComponent,
    CatalogoMarcasComponent,
    CrearEditarMarcaComponent,
    CatalogoEspeciesComponent,
    CrearEditarEspecieComponent,
    CatalogoEdadesComponent,
    CrearEditarEdadComponent,
    CatalogoTiposProductoComponent,
    CrearEditarTipoProductoComponent,
    CrearEditarProductoComponent,
    ImagenesComponent,
    AparadorComponent,
    ListadoProductosComponent,
    PresentacionesComponent,
    DetalleProductoComponent,
    CarritoDeComprasComponent,
    RegistroComponent,
    IngresoComponent,
    MiPerfilComponent,
    CrearEditarEtapasDeVidaComponent,
    CargaProductosComponent,
    MisPedidosComponent,
    PedidosComponent,
    RecuperaContraseniaComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    FormsModule,
    NgxPayPalModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
