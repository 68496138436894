<div class="modal fade" id="productosModal" tabindex="-1" aria-labelledby="productosModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        
        <div class="modal-content">            
            <div class="modal-header">
                <h5 class="modal-title" id="productosModalLabel">Productos</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            
            <div class="modal-body">                
                <form [formGroup]="DatosProductoForm">
                    <div class="container mt-3">
                        <div class="form-group">
                            <input type="hidden" formControlName="id">
                            <select *ngIf="marcasActivas as marcas"
                                class="form-control"
                                aria-describedby="marcaHelp"
                                formControlName="marca">
                                <option value="" selected>Seleccionar marca</option>
                                <option [value]="m.id" *ngFor="let m of marcas">
                                    {{m.data.nombre}}
                                </option>
                            </select>
                            <small id="marcaHelp" class="form-text text-muted">Marca del producto.</small>
                        </div>
                        <div class="form-group">
                            <input  type="text"
                                    class="form-control"
                                    formControlName="nombre"
                                    aria-describedby="nombreHelp"
                                    placeholder="Nombre"
                                    autocomplete="off">
                            <small id="nombreHelp" class="form-text text-muted">Nombre del producto.</small>
                        </div>
                        <div class="form-group">
                            <textarea   rows="3"
                                        class="form-control"
                                        formControlName="descripcion"
                                        aria-describedby="descripcionHelp"
                                        placeholder="Descripción"
                                        autocomplete="off">
                            </textarea>
                            <small id="descripcionHelp" class="form-text text-muted">Descripción del producto.</small>
                        </div>
                        <div class="form-group">
                            <select *ngIf="especiesActivas as especies"
                                formControlName="especie"
                                class="form-control"
                                aria-describedby="especieHelp">
                                <option value="" selected>Seleccionar especie</option>
                                <option [value]="e.id" *ngFor="let e of especies">
                                    {{e.data.nombre}}
                                </option>                                
                            </select>
                            <small id="especieHelp" class="form-text text-muted">Especie del animal.</small>
                        </div>
                        <div class="form-group">
                            <select *ngIf="edadesActivas as edades"
                                formControlName="edad"
                                class="form-control"
                                aria-describedby="edadHelp">
                                <option value="" selected>Seleccione etapa de vida</option>
                                <option [value]="d.id" *ngFor="let d of edades">
                                    {{d.data.nombre}}
                                </option>                                
                            </select>
                            <small id="edadHelp" class="form-text text-muted">Edad de animal.</small>
                        </div>
                        <div class="form-group">
                            <select *ngIf="tiposProductoActivos as tiposProducto"
                                formControlName="tipoProducto"
                                class="form-control"
                                aria-describedby="tipoProductoHelp">
                                <option value="" selected>Seleccione tipo de producto</option>
                                <option value="R9n7glIoayHDDdXTwSzH" selected>Seco</option>
                                <option value="9b26ORXTlEhSUAOoSS0W" selected>Semihumedo</option>
                                <option value="K3bxUPPXnhDVpqJD9Fix" selected>Humedo</option>
                                <option value="egElF4YyVIlNQMC7PnD2" selected>Premios</option>
                            </select>
                            <small id="tipoProductoHelp" class="form-text text-muted">Tipo de producto.</small>
                        </div>
                        <div class="form-group">
                            <textarea   rows="3"
                                        class="form-control"
                                        formControlName="beneficios"
                                        aria-describedby="beneficiosHelp"
                                        placeholder="Beneficios"
                                        autocomplete="off">
                                </textarea>
                            <small id="beneficiosHelp" class="form-text text-muted">Beneficios del producto.</small>
                        </div>
                        <div class="form-group">
                            <textarea   rows="3"
                                        class="form-control"
                                        formControlName="ingredientes"
                                        aria-describedby="ingredientesHelp"
                                        placeholder="Ingredientes"
                                        autocomplete="off">
                            </textarea>
                            <small id="ingredientesHelp" class="form-text text-muted">Ingredientes del producto.</small>
                        </div>
                        <div class="form-group">
                            <textarea   rows="3"
                                        class="form-control"
                                        formControlName="analisisGarantizado"
                                        aria-describedby="analisisGarantizadoHelp"
                                        placeholder="Analisis garantizado"
                                        autocomplete="off">
                            </textarea>
                            <small id="analisisGarantizadoHelp" class="form-text text-muted">Analisis garantizado del producto.</small>
                        </div>
                        <div class="form-group">
                            <textarea   rows="3"
                                        class="form-control"
                                        formControlName="instrucciones"
                                        aria-describedby="instruccionesHelp"
                                        placeholder="Instrucciones"
                                        autocomplete="off">
                            </textarea>
                            <small id="instruccionesHelp" class="form-text text-muted">Instrucciones del producto.</small>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" formControlName="estado" class="form-control custom-control-input" id="estado">
                                <label class="custom-control-label" for="estado">¿Activo?</label>
                            </div>        
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button [disabled]="DatosProductoForm.invalid" style="background-color: rgba(34, 142, 157, 1);" class="btn btn-primary" (click)="alEnviar()">
                    <div *ngIf="!productoSeleccionado.id; else Editar">Agregar</div>
                    <ng-template #Editar >Editar</ng-template>
                </button>
                <button style="background-color: rgba(34, 142, 157, 1);" type="button" id='BotonCerrar' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>