import { Component, OnInit } from '@angular/core';
import { TiposProductoService } from './tipos-producto.service';
import { TipoProducto } from './tipo-producto';

@Component({
  selector: 'app-catalogo-tiposproducto',
  templateUrl: './catalogo-tipos-producto.component.html',
  styleUrls: ['./catalogo-tipos-producto.component.css']
})

export class CatalogoTiposProductoComponent implements OnInit {

  constructor(private servicioTipoProducto: TiposProductoService) { }

  public tiposProducto$ = this.servicioTipoProducto.tiposProducto$;
  public tipoProductoSeleccionado: TipoProducto = new TipoProducto();

  ngOnInit(): void {
  }

  alEditar(tipoProducto: TipoProducto): void {
    this.tipoProductoSeleccionado = tipoProducto;
  }
  
  alCrear(): void {
    this.tipoProductoSeleccionado = new TipoProducto();
  }
}
