import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Especie } from './especie';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EspeciesService {

  constructor(private firestore: AngularFirestore, private almacenamiento: AngularFireStorage) { }

  private coleccionEspecies = this.firestore.collection('Especies');
  private coleccionEspeciesActivas = this.firestore.collection('Especies', ref => ref.where('estado', '==', true));
  private especiesActivas: {
    id: string,
    data: Especie
  }[];

  public especies$ = this.coleccionEspecies.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const especie = p.payload.doc;
        const id = especie.id;
        const objEspecie = { id, ...(especie.data() as {}) } as Especie;
        let i = 0;
        if (objEspecie.etapasDeVida){
          objEspecie.etapasDeVida.forEach(element => {
            element.etapaDeVida.get().then(doc => objEspecie.etapasDeVida[i++].nombre = doc.data().nombre);
          });
        }
        return objEspecie;
      });
    })
  );

  public especiesActivas$ = this.coleccionEspeciesActivas.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const especie = p.payload.doc;
        const id = especie.id;
        const objEspecie = { id, ...(especie.data() as {}) } as Especie;
        let i = 0;
        if (objEspecie.etapasDeVida){
          objEspecie.etapasDeVida.forEach(element => {
            element.etapaDeVida.get().then(doc => objEspecie.etapasDeVida[i++].nombre = doc.data().nombre);
          });
        }
        const urlImagen = this.almacenamiento.ref('Imagenes/Especies/' + objEspecie.nombre + '.jpg');
        urlImagen.getDownloadURL().subscribe({ next: x => objEspecie.urlImagen = x });
        return objEspecie;
      });
    })
  );

  async obtenerActivas(): Promise<{id: string, data: Especie}[]>{
    this.especiesActivas = new Array();
    (await this.firestore.collection('Especies', ref => ref.orderBy('orden'))
      .get()
      .toPromise())
      .forEach(element => {
      this.especiesActivas.push({
        id: element.id,
        data: element.data() as Especie
      });
    });
    return this.especiesActivas;
  }

  async agregar(especie: Especie): Promise<void> {
    try {
      await this.coleccionEspecies.add(
        {
        nombre: especie.nombre,
        ruteo: especie.ruteo,
        estado: especie.estado,
        });
    } catch (err) {
      console.log(err);
    }
  }

  async editar(especie: Especie): Promise<void> {
    try {
      await this.coleccionEspecies
        .doc(especie.id)
        .set(
          {
            nombre: especie.nombre,
            ruteo: especie.ruteo,
            etapasDeVida: especie.etapasDeVida ? especie.etapasDeVida : new Array(),
            estado: especie.estado,
          },
          {
            merge: true
          });
    } catch (err) {
      console.log(err);
    }
  }
}
