<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Catálogos</h6></li>
        <li class="breadcrumb-item"><h6>Productos</h6></li>
    </ol>
</nav>

<button class="btn btn-block btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="alCrear()" data-toggle="modal" data-target="#productosModal">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
    Productos
</button>    

<form id='formularioFiltros' [formGroup]="DatosFiltrosForm">
    <div class="form-row">
        <div class="form-group col-lg-3">
            <label for="filtroMarca">Marca</label>
            <select *ngIf="marcas$ | async as marcas" formControlName="filtroMarca" class="custom-select" (change)="renuevaFiltro()">
                <option value="">Seleccione una marca</option>
                <option *ngFor="let marca of marcas" [value]="marca.id">{{marca.nombre}}</option>
            </select>
        </div>
        <div class="form-group col-lg-3">
            <label for="filtroEspecie">Especie</label>
            <select *ngIf="especies$ | async as especies" formControlName="filtroEspecie" class="custom-select" (change)="renuevaFiltro()">
                <option value="">Seleccione una especie</option>
                <option *ngFor="let especie of especies" [value]="especie.id">{{especie.nombre}}</option>
            </select>
        </div>
        <div class="form-group col-lg-3">
            <label for="filtroEtapaVida">Etapa de vida</label>
            <select *ngIf="etapasDeVida$ | async as etapasDeVida" formControlName="filtroEtapaDeVida" class="custom-select" (change)="renuevaFiltro()">
                <option value="">Seleccione una etapa de vida</option>
                <option *ngFor="let etapaDeVida of etapasDeVida" [value]="etapaDeVida.id">{{etapaDeVida.nombre}}</option>
            </select>
        </div>
        <div class="form-group col-lg-3">
            <label for="filtroTipoProducto">Tipo de producto</label>
            <select *ngIf="tiposDeProducto$ | async as tiposDeProducto" formControlName="filtroTipoProducto" class="custom-select" (change)="renuevaFiltro()">
                <option value="">Seleccione un tipo de producto</option>
                <option *ngFor="let tipoDeProducto of tiposDeProducto" [value]="tipoDeProducto.id">{{tipoDeProducto.nombre}}</option>
            </select>
        </div>
    </div>
</form>

<div class="row">
    <div class="col">
        <button [disabled]="disablePrev" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="prevPage()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
            </svg>
            {{paginationClickedCount > 0 ? paginationClickedCount : ''}}
        </button>
    </div>
    <div class="col text-right">
        <button [disabled]="disableNext" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="nextPage()">
            {{(paginationClickedCount + 1)}}
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
            </svg>
        </button>
    </div>
</div>

<div *ngIf="tableData as productos">
    <table class="table table-hover table-sm">
        <thead style="background-color: rgba(34, 142, 157, 1);">
            <tr class="text-light">
                <th scope="col" width="20%">Nombre</th>
                <th scope="col" width="15%">Marca</th>
                <th scope="col" width="15%">Especie</th>
                <th scope="col" width="15%">Etapa de vida</th>
                <th scope="col" width="15%">Tipo</th>
                <th scope="col" width="5%"></th>
                <th scope="col" width="5%"></th>
                <th scope="col" width="5%"></th>
                <th scope="col" width="5%"></th>
            </tr>
        </thead>
        <tbody class="table-light">
            <tr *ngFor="let p of productos">
                <td>{{ p.nombre }}</td>
                <td>{{ p.marcaDoc.nombre }}</td>
                <td>{{ p.especieDoc.nombre }}</td>
                <td>{{ p.edadDoc.nombre }}</td>
                <td>{{ p.tipoProductoDoc.nombre }}</td>
                <td>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" disabled [checked]="p.estado">
                        <label class="custom-control-label"></label>
                    </div>
                </td>
                <td>
                    <button class="btn btn-sm btn-outline-dark" (click)="alEditar(p)" data-toggle="modal" data-target="#productosModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                    </button>
                </td>
                <td>
                    <button class="btn btn-sm btn-outline-dark" (click)="alEditarGaleria(p)" data-toggle="modal" data-target="#galeriaProductosModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-images" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M12.002 4h-10a1 1 0 0 0-1 1v8l2.646-2.354a.5.5 0 0 1 .63-.062l2.66 1.773 3.71-3.71a.5.5 0 0 1 .577-.094l1.777 1.947V5a1 1 0 0 0-1-1zm-10-1a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-10zm4 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            <path fill-rule="evenodd" d="M4 2h10a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1v1a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2h1a1 1 0 0 1 1-1z"/>
                          </svg>
                    </button>
                </td>
                <td>
                    <button class="btn btn-sm btn-outline-dark" (click)="alEditarPresentaciones(p)" data-toggle="modal" data-target="#presentacionesProductosModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-box-seam" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2l-2.218-.887zm3.564 1.426L5.596 5 8 5.961 14.154 3.5l-2.404-.961zm3.25 1.7l-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923l6.5 2.6zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464L7.443.184z"/>
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="row">
    <div class="col">
        <button [disabled]="disablePrev" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="prevPage()">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-left" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M10 12.796L4.519 8 10 3.204v9.592zm-.659.753l-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z"/>
            </svg>
            {{paginationClickedCount > 0 ? paginationClickedCount : ''}}
        </button>
    </div>
    <div class="col text-right">
        <button [disabled]="disableNext" class="btn btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="nextPage()">
            {{(paginationClickedCount + 1)}}
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-caret-right" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M6 12.796L11.481 8 6 3.204v9.592zm.659.753l5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753z"/>
            </svg>
        </button>
    </div>
</div>

<app-crear-editar-producto [productoSeleccionado]="productoSeleccionado" ></app-crear-editar-producto>
<app-imagenes [productoSeleccionado]="productoSeleccionado" ></app-imagenes>
<app-presentaciones [productoSeleccionado]="productoSeleccionado" ></app-presentaciones>

<app-carga-productos></app-carga-productos>


<div *ngIf="productos as listado">
    <div *ngFor="let p of listado">
        <div *ngFor="let pr of p.data.presentaciones">
            {{ p.id + '}'
            + p.data.nombre + '}'
            + p.data.marcaDoc.nombre + '}'
            + p.data.especieDoc.nombre + '}'
            + p.data.edadDoc.nombre + '}'
            + p.data.tipoProductoDoc.nombre + '}'
            + p.data.descripcion + '}'
            + p.data.beneficios + '}'
            + p.data.ingredientes + '}'
            + p.data.analisisGarantizado + '}'
            + p.data.instrucciones + '}'
            + pr.sku + '}'
            + pr.ean + '}'
            + pr.descripcion + '}'
            + pr.costo + '}'
            + pr.precio
            }}            
        </div>
    </div>
</div>
