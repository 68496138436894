import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Especie } from '../especie';
import { EspeciesService } from '../especies.service';
import { ToastrService } from 'ngx-toastr';
import { EdadesService } from '../../catalogo-edades/edades.service';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

@Component({
  selector: 'app-crear-editar-etapas-de-vida',
  templateUrl: './crear-editar-etapas-de-vida.component.html',
  styleUrls: ['./crear-editar-etapas-de-vida.component.css']
})

export class CrearEditarEtapasDeVidaComponent implements OnInit {

  @Input()
  especieSeleccionada: Especie = new Especie();

  public etapaDeVidaSeleccionada: number = -1;
  public listaEdades$ = this.servicioEdad.edades$;
  public listaEdadesActivas$ = this.servicioEdad.edadesActivas$;

  public etapaDeVidaForm = new FormGroup({
    etapaDeVida: new FormControl('', [Validators.required])
  });

  constructor(
    private servicioEspecie: EspeciesService,
    private servicioEdad: EdadesService,
    private toastr: ToastrService,
    private firestore: AngularFirestore
    ) { }

  ngOnInit(): void {
  }

  async alEliminar(i: number){
    let nombreEliminado = this.especieSeleccionada.etapasDeVida[i].nombre;
    try {
      this.especieSeleccionada.etapasDeVida.splice(i, 1);
      this.servicioEspecie.editar(this.especieSeleccionada);
      this.toastr.success('Se elimino la etapa de vida "' + nombreEliminado + '" para la especie "' + this.especieSeleccionada.nombre + '"');  
    } catch (e){
      this.toastr.error(e);
    }
  }

  async alAgregar(){
    const etapaDeVidaForm = this.etapaDeVidaForm.value;
    try {

      if (this.etapaDeVidaSeleccionada == -1){
        if (!this.especieSeleccionada.etapasDeVida){
          this.especieSeleccionada.etapasDeVida = new Array();
        }
        let nombreEtapaDeVida;
        await this.firestore.collection('Edades').doc('' + etapaDeVidaForm.etapaDeVida).ref.get().then(doc => nombreEtapaDeVida = doc.data().nombre);
        this.especieSeleccionada.etapasDeVida.push(
          {
            etapaDeVida: this.firestore.collection('Edades').doc('' + etapaDeVidaForm.etapaDeVida).ref,
            nombre: nombreEtapaDeVida
          });
        this.servicioEspecie.editar(this.especieSeleccionada);
        this.toastr.success('Se agrego la etapa de vida "' + nombreEtapaDeVida + '" para la especie "' + this.especieSeleccionada.nombre + '"');
      } else {
        this.especieSeleccionada.etapasDeVida[this.etapaDeVidaSeleccionada] = etapaDeVidaForm.etapaDeVida;
        this.servicioEspecie.editar(this.especieSeleccionada);
        this.toastr.success('Se edito la etapa de vida "' + etapaDeVidaForm.etapaDeVida + '" para la especie "' + this.especieSeleccionada.nombre + '"');
      }      
    } catch (e) {
      this.toastr.error(e);
    }
    this.etapaDeVidaForm.setValue({etapaDeVida: ''});
    this.etapaDeVidaSeleccionada = -1;
  }
}
