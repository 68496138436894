import { DocumentReference } from '@angular/fire/firestore';
import { CarritoDeCompras } from '../carrito-de-compras/carrito-de-compras';
import { Usuario } from '../usuarios/usuario';

export class Pedido {
    id?: number;
    datosUsuario: Usuario;
    fechaHoraPedido: Date;
    carritoDeCompras: CarritoDeCompras;
    estadoEntrega: string;
    estadoPago: string;
    formaPago: string;
    detallePayPal?: any;
    fechaHoraEntrega?: Date;
    nombreRecibe?: string;

    constructor(){
        this.datosUsuario = new Usuario();
        this.carritoDeCompras = new CarritoDeCompras();
    }
}
