import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CarritoDeCompras } from '../carrito-de-compras/carrito-de-compras';
import { CarritoDeComprasService } from '../carrito-de-compras/carrito-de-compras.service';
import { AutenticacionService } from '../usuarios/autenticacion.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-menu-productos',
  templateUrl: './menu-productos.component.html',
  styleUrls: ['./menu-productos.component.css'],
  providers: [AutenticacionService]
})

export class MenuProductosComponent implements OnInit {

  public user$: Observable<any> = this.authSvc.afAuth.user;
  public carritoDeCompras: CarritoDeCompras;

  constructor(
    private servicioCarritoDeCompras: CarritoDeComprasService,
    private authSvc: AutenticacionService,
    private router: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.carritoDeCompras = this.servicioCarritoDeCompras.consultar();
    this.servicioCarritoDeCompras.carritoDeCompras$.subscribe(data => this.carritoDeCompras = data);
  }

  async onLogout(): Promise<void> {
    try {
      await this.authSvc.logout();
      this.router.navigate(['/']);
    }
    catch (error){
      console.log(error);
    }
  }

  cambiarContrasenia(): void {
    this.user$.subscribe(x =>
      {
        this.authSvc.enviaCorreoCambioContrasenia(x.email);
        this.toastr.info('Hemos enviado un mensaje a: ' + x.email + ' donde encontraras las instrucciones para cambiar tu contraseña.');
      });
  }
}
