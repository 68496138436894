import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TipoProducto } from '../tipo-producto';
import { TiposProductoService } from '../tipos-producto.service';

declare var $: any;

@Component({
  selector: 'app-crear-editar-tipoproducto',
  templateUrl: './crear-editar-tipo-producto.component.html',
  styleUrls: ['./crear-editar-tipo-producto.component.css']
})

export class CrearEditarTipoProductoComponent implements OnInit, OnChanges {

  @Input()
  tipoProductoSeleccionado: TipoProducto = new TipoProducto();

  DatosTipoProductoForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    estado: new FormControl('')
  });

  constructor(private servicioTiposproducto: TiposProductoService) { }

  ngOnChanges(): void {
    if (this.tipoProductoSeleccionado.id){
      this.DatosTipoProductoForm.setValue(this.tipoProductoSeleccionado);
    } else {
      this.DatosTipoProductoForm.reset();
    }
  }

  ngOnInit(): void {
  }

  alEnviar(): void {
    const tipoproducto = this.DatosTipoProductoForm.value;
    if (!this.tipoProductoSeleccionado.id){
      this.servicioTiposproducto.agregar(tipoproducto);
    }
    else {
      this.servicioTiposproducto.editar({...tipoproducto, id: this.tipoProductoSeleccionado.id });
    }
    this.DatosTipoProductoForm.reset();
    this.tipoProductoSeleccionado = new TipoProducto();
    $('#tiposproductoModal').modal('hide');
  }
}