import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { TipoProducto } from './tipo-producto';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TiposProductoService {

  constructor(private firestore: AngularFirestore) { }

  private coleccionTiposProducto = this.firestore.collection('TiposProducto', ref => ref.orderBy('nombre'));
  private coleccionTiposProductoActivos = this.firestore.collection('TiposProducto', ref => ref.where('estado', '==', true));
  private tiposProductoActivos: {
    id: string,
    data: TipoProducto
  }[];

  public tiposProducto$ = this.coleccionTiposProducto.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const tipoProducto = p.payload.doc;
        const id = tipoProducto.id;
        return { id, ...(tipoProducto.data() as {}) } as TipoProducto;
      });
    })
  );

  public tiposProductoActivos$ = this.coleccionTiposProductoActivos.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const tipoProducto = p.payload.doc;
        const id = tipoProducto.id;
        return { id, ...(tipoProducto.data() as {}) } as TipoProducto;
      });
    })
  );

  async obtenerActivos(): Promise<{id: string, data: TipoProducto}[]>{
    this.tiposProductoActivos = new Array();
    (await this.firestore.collection('TiposProducto')
      .get()
      .toPromise())
      .forEach(element => {
        this.tiposProductoActivos.push({
        id: element.id,
        data: element.data() as TipoProducto
      });
    });
    return this.tiposProductoActivos;
  }

  async agregar(tipoProducto: TipoProducto): Promise<void> {
    try {
      await this.coleccionTiposProducto.add
      (
        {
          nombre: tipoProducto.nombre,
          descripcion: tipoProducto.descripcion,
          estado: tipoProducto.estado
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async editar(tipoProducto: TipoProducto): Promise<void> {
    try {
      await this.coleccionTiposProducto
        .doc(tipoProducto.id)
        .set(
          {
            nombre: tipoProducto.nombre,
            descripcion: tipoProducto.descripcion,
            estado: tipoProducto.estado
          },
          {
            merge: true
          });
    } catch (err) {
      console.log(err);
    }
  }
}
