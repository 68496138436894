<nav class="mt-5" aria-label="breadcrumb mt-10">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Usuarios</h6></li>
        <li class="breadcrumb-item"><h6>Mi Perfil</h6></li>
    </ol>
</nav>

<h3 class="mt-3">Mis datos</h3>
<form [formGroup]="DatosUsuarioForm" (ngSubmit)="alEnviar()">
    <input type="hidden" formControlName="id">
    <div class="card card-body">
        <h5>Información general</h5>
    <div class="form-row">
        <div class="form-group col">
            <label for="email">Email</label>
            <input type="email" class="form-control" formControlName="email" readonly>
        </div>
    </div>
        <div class="form-row">
            <div class="form-group col-lg-4">
                <label for="nombre">Nombre(s)</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.nombre.valid ? 'is-valid' : 'is-invalid'" formControlName="nombre">
                <div class="invalid-feedback">
                  Indique su nombre
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label for="apellidoPaterno">Apellido Paterno</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.apellidoPaterno.valid ? 'is-valid' : 'is-invalid'" formControlName="apellidoPaterno">
                <div class="invalid-feedback">
                  Indique su apellido paterno
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label for="apellidoMaterno">Apellido Materno</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.apellidoMaterno.valid ? 'is-valid' : 'is-invalid'" formControlName="apellidoMaterno">
                <div class="invalid-feedback">
                  Indique su apellido materno
                </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6">
                <label for="sexo">Sexo</label>
                <select name="sexo" class="form-control" [class]="DatosUsuarioForm.controls.sexo.valid ? 'is-valid' : 'is-invalid'" formControlName="sexo">
                    <option value="" selected>Seleccione</option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                </select>
                <div class="invalid-feedback">
                    Indique su sexo
                </div>  
            </div>
            <div class="form-group col-lg-6">
                <label for="edad">Edad</label>
                <input type="number" step="1" min="18" [class]="DatosUsuarioForm.controls.edad.valid ? 'is-valid' : 'is-invalid'" class="form-control" formControlName="edad">
                <div class="invalid-feedback">
                    Indique su edad (Mayor de 18 años)
                </div>  
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-6">
                <label for="telefonoFijo">Telefono Fijo</label>
                <input type="number" class="form-control" [class]="DatosUsuarioForm.controls.telefonoFijo.valid ? 'is-valid' : 'is-invalid'" formControlName="telefonoFijo">
                <div class="invalid-feedback">
                  Indique su telefono fijo
                </div>
            </div>
            <div class="form-group col-lg-6">
                <label for="telefonoMovil">Telefono Movil</label>
                <input type="number" class="form-control" [class]="DatosUsuarioForm.controls.telefonoMovil.valid ? 'is-valid' : 'is-invalid'" formControlName="telefonoMovil">
                <div class="invalid-feedback">
                  Indique su telefono movil
                </div>
            </div>
        </div>
    </div>
    <div formGroupName="direccion" class="card card-body mt-2">
        <h5>Dirección</h5>
        <p><small>Por el momento solo contamos con envios dentro del estado de <strong>Jalisco</strong> a los municipios de: <strong>Guadalajara, Zapopan, Tonalá, Tlaquepaque y Tlajomulco de Zúñiga</strong></small></p>
        <div class="form-row">
            <div class="form-group col-lg-4">
                <label for="calle">Calle</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('calle').valid ? 'is-valid' : 'is-invalid'" formControlName="calle">
                <div class="invalid-feedback">
                  Indique la calle
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label for="numeroExterior">Número exterior</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('numeroExterior').valid ? 'is-valid' : 'is-invalid'" formControlName="numeroExterior">
                <div class="invalid-feedback">
                  Indique su numero exterior
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label for="numeroInterior">Número interior</label>
                <input type="text" class="form-control" formControlName="numeroInterior">
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col-lg-2">
                <label for="codigoPostal">Código postal</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('codigoPostal').valid ? 'is-valid' : 'is-invalid'" formControlName="codigoPostal">
                <div class="invalid-feedback">
                  Indique su numero postal (5 digitos numéricos)
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label for="colonia">Colonia</label>
                <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('colonia').valid ? 'is-valid' : 'is-invalid'" formControlName="colonia">
                <div class="invalid-feedback">
                  Indique su colonia
                </div>
            </div>
            <div class="form-group col-lg-4">
                <label for="municipio">Municipio</label>
                <select name="municipio" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('municipio').valid ? 'is-valid' : 'is-invalid'" formControlName="municipio">
                    <option value="" selected>Seleccione</option>
                    <option value="Guadalajara">Guadalajara</option>
                    <option value="Zapopan">Zapopan</option>
                    <option value="Tonalá">Tonalá</option>
                    <option value="Tlaquepaque">Tlaquepaque</option>
                    <option value="Tlajomulco de Zúñiga">Tlajomulco de Zúñiga</option>
                </select>
                <div class="invalid-feedback">
                    Seleccione un municipio
                </div>
            </div>
            <div class="form-group col-lg-2">
                <label for="estado">Estado</label>
                <select name="estado" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('estado').valid ? 'is-valid' : 'is-invalid'" formControlName="estado">
                    <option value="" selected>Seleccione</option>
                    <option value="Jalisco">Jalisco</option>
                </select>
                <div class="invalid-feedback">
                    Seleccione un estado
                  </div>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label for="referencias">Referencias</label>
                <input type="text" class="form-control" formControlName="referencias">
            </div>
        </div>
    </div>
    <button [disabled]="DatosUsuarioForm.invalid" type="submit" class="btn btn-dark btn-lg btn-block mt-3" style="background-color: rgba(34, 142, 157, 1);">Guardar</button>
</form>

<h3 class="mt-3">Mis mascotas</h3>
<div class="row row-cols-1 row-cols-md-3 mt-3">
    <div *ngFor="let mascota of listaMascotas; index as i" class="col mb-4">
      <div class="card h-100 border-dark">
        <div class="card-header text-right">
            <h5>{{mascota.nombre}}</h5>
        </div>
        <div class="card-body">
            <h6 class="card-subtitle mb-2">Especie:</h6>
            <p class="card-text">{{mascota.nombreEspecie}}</p>
            <h6 class="card-subtitle mb-2">Raza:</h6>
            <p class="card-text">{{mascota.raza}}</p>
            <h6 class="card-subtitle mb-2">Etapa de vida:</h6>
            <p class="card-text">{{mascota.edad}}</p>          
            <h6 class="card-subtitle mb-2">Padecimientos:</h6>
            <p class="card-text">{{mascota.padecimientos}}</p>
            <a href="/Usuarios/MiPerfil#formularioMascota">
                <button class="btn btn-dark btn-block btn-sm mt-3" style="background-color: rgba(34, 142, 157, 1);" (click)="alEditarMascota(i)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                    </svg>
                    Editar
                </button>
            </a>
        </div>
      </div>
    </div>
</div>

<form id='formularioMascota' [formGroup]="DatosMascotaForm" (ngSubmit)="alEnviarMascota()">
    <div class="card card-body mt-3">
        <h5 *ngIf="mascotaSeleccionada == -1">Agregar datos de mi mascota</h5>
        <h5 *ngIf="mascotaSeleccionada > -1">Editar datos de {{ listaMascotas[mascotaSeleccionada].nombre }}</h5>
        <div class="form-row">
            <div class="form-group col-lg-3">
                <label for="nombre">Nombre</label>
                <input type="text" class="form-control" [class]="DatosMascotaForm.controls.nombre.valid ? 'is-valid' : 'is-invalid'" formControlName="nombre">
            </div>
            <div class="form-group col-lg-3">
                <label for="especie">Especie</label>
                <select *ngIf="especies$ | async as especies" class="custom-select" [class]="DatosMascotaForm.controls.especie.valid ? 'is-valid' : 'is-invalid'" formControlName="especie">
                    <option value="" selected>Seleccionar especie</option>
                    <option [value]="especie.id" *ngFor="let especie of especies">
                        {{especie.nombre}}
                    </option>
                </select>
            </div>
            <div class="form-group col-lg-3">
                <label for="raza">Raza</label>
                <input type="text" class="form-control" [class]="DatosMascotaForm.controls.raza.valid ? 'is-valid' : 'is-invalid'" formControlName="raza">
            </div>
            <div class="form-group col-lg-3">
                <label for="edad">Etapa de vida</label>
                <select class="custom-select" [class]="DatosMascotaForm.controls.edad.valid ? 'is-valid' : 'is-invalid'" formControlName="edad">
                    <option value="">Seleccionar etapa de vida</option>
                    <option value="1 Mes">1 Mes</option>
                    <option value="2 Meses">2 Meses</option>
                    <option value="3 Meses">3 Meses</option>
                    <option value="4 Meses">4 Meses</option>
                    <option value="5 Meses">5 Meses</option>
                    <option value="6 Meses">6 Meses</option>
                    <option value="7 Meses">7 Meses</option>
                    <option value="8 Meses">8 Meses</option>
                    <option value="9 Meses">9 Meses</option>
                    <option value="10 Meses">10 Meses</option>
                    <option value="11 Meses">11 Meses</option>
                    <option value="12 Meses">12 Meses</option>
                    <option value="1 Año">1 Año</option>
                    <option value="2 Años">2 Años</option>
                    <option value="3 Años">3 Años</option>
                    <option value="4 Años">4 Años</option>
                    <option value="5 Años">5 Años</option>
                    <option value="6 Años">6 Años</option>
                    <option value="7 Años">7 Años</option>
                    <option value="8 Años">8 Años</option>
                    <option value="9 Años">9 Años</option>
                    <option value="10 Años">10 Años</option>
                    <option value="11 Años">11 Años</option>
                    <option value="12 Años">12 Años</option>
                    <option value="13 Años">13 Años</option>
                    <option value="14 Años">14 Años</option>
                    <option value="15 Años">15 Años</option>
                    <option value="16 Años">16 Años</option>
                    <option value="17 Años">17 Años</option>
                    <option value="18 Años">18 Años</option>
                    <option value="19 Años">19 Años</option>
                    <option value="20 Años">20 Años</option>
                </select>
            </div>
        </div>
        <div class="form-row">
            <div class="form-group col">
                <label for="padecimientos">¿Cuenta actualmente con algun padecimiento o enfermedad?</label>
                <select class="custom-select" formControlName="padecimientos" size="5" multiple>
                    <option value="Sobrepeso">Sobrepeso</option>
                    <option value="Obesidad">Obesidad</option>
                    <option value="Diarrea">Diarrea</option>
                    <option value="Enfermedades de la piel">Enfermedades de la piel</option>
                    <option value="Enfermedades de las vias urinarias">Enfermedades de las vias urinarias</option>
                    <option value="Enfermedad renal">Enfermedad renal</option>
                    <option value="Enfermedad cardiaca">Enfermedad cardiaca</option>
                  </select>
            </div>
        </div>
        <button [disabled]="DatosMascotaForm.invalid" type="submit" class="btn btn-dark btn-lg btn-block mt-3" style="background-color: rgba(34, 142, 157, 1);">
            <span *ngIf="mascotaSeleccionada == -1">Agregar</span>
            <span *ngIf="mascotaSeleccionada > -1">Editar</span>
        </button>
    </div>
</form>