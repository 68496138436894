export class Marca {
    id: string;
    nombre: string;
    ruteo: string;
    slogan: string;
    sitioWeb: string;
    urlImagen: string;
    estado: boolean;
    orden: number;
}
