<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Carrito de compras</h6></li>
    </ol>
</nav>

<div class="card" *ngIf="pasoCompra=='carrito'">
  <div class="card-body">
    <div class="card">
      <div class="card-header">
        <strong>Paso 1 de 4</strong> - Confirme su orden de compra.
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col col-lg-3">Productos en su carrito:</div>
          <div class="col">{{ carritoDeCompras.cantidadTotal }}</div>
        </div>
        <div class="row">
          <div class="col col-lg-3">Total de su compra:</div>
          <div class="col">{{ carritoDeCompras.importeTotal | currency:'MXN' }}</div>
        </div>
        <div *ngIf="carritoDeCompras.importeTotal < 500" class="row">
          <div class="col">
            <p><small>Se requiere una compra minima de <strong>MX$500.00</strong> para obtener el envío gratuito de sus productos.</small></p>
          </div>
        </div>
        <div class="mt-3">
          <div *ngFor="let producto of carritoDeCompras.productos" class="card mb-3">
            <div class="row no-gutters">
              <div class="col-lg-2">
                <div class="row">
                  <div class="col-12">
                    <img [src]="producto.urlImagen" class="card-img" style="max-width: 100%; height: auto;">
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-4">
                    <button [disabled]="producto.cantidad < 2" class="btn btn-outline-dark mr-2" (click)="alActualizarCarrito(producto, '-')">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-dash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
                      </svg>
                  </button>                        
                  </div>  
                  <div class="col-4">
                    <button [disabled]="producto.cantidad > 9" class="btn btn-outline-dark ml-2" (click)="alActualizarCarrito(producto, '+')">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                      </svg>
                      </button>  
                  </div>  
                  <div class="col-4">
                    <button class="btn btn-outline-dark ml-2" (click)="alActualizarCarrito(producto, 'x')">
                      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                        <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                      </svg>
                      </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-10">
                <div class="card-body">
                  <h5 class="card-title">{{producto.nombreProducto }}</h5>
                  <div class="row">
                      <div class="col col-lg-2">Presentacion:</div>
                      <div class="col">{{ producto.descripcionPresentacion }}</div>
                  </div>
                  <div class="row">
                    <div class="col col-lg-2">Precio:</div>
                    <div class="col">
                        {{ producto.precio | currency:'MXN'}}
                    </div>
                  </div>
                  <div class="row">
                    <div class="col col-lg-2">Cantidad:</div>
                    <div class="col">
                      {{producto.cantidad}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button (click)="alConfirmarCarrito()" [disabled]="carritoDeCompras.importeTotal < 500" class="btn btn-dark btn-block mt-3" style="background-color: rgba(34, 142, 157, 1);">
          Ir al paso 2 - Sus datos del envio...
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="carritoDeCompras.importeTotal > 500 && pasoCompra=='datosEnvio'" class="card mt-3" id="seccionDireccion">
  <div *ngIf="usuario$ | async as usuario" class="card-body">
    <div class="card">
      <div class="card-header">
        <strong>Paso 1 de 4</strong> - Su orden de compra.
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
        </svg>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col col-lg-3">Productos en su carrito:</div>
          <div class="col">{{ carritoDeCompras.cantidadTotal }}</div>
        </div>
        <div class="row">
          <div class="col col-lg-3">Total de su compra:</div>
          <div class="col">{{ carritoDeCompras.importeTotal | currency:'MXN' }}</div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-header">
        <strong>Paso 2 de 4</strong> - Confirme los datos de su envío.
      </div>
      <div class="card-body">
        <form [formGroup]="DatosUsuarioForm">
          <input type="hidden" formControlName="id">
          <div class="card card-body">
              <h5>Información general</h5>
          <div class="form-row">
              <div class="form-group col">
                  <label for="email">Email</label>
                  <input type="email" class="form-control" formControlName="email" readonly>
                  <p *ngIf="!usuarioVerificado">
                    <small style="color: red;">No hemos podido verificar su cuenta de correo electrónico, para seguir con su proceso de compra, verifique su cuenta de correo electrónico. </small>
                    <a class="link" (click)="enviaCorreoVerificacion()">
                      <small>Has click aqui para recibir nuevamente el correo de verificación de tu cuenta de correo electrónico.</small>
                    </a>
                  </p>
              </div>
          </div>
              <div class="form-row">
                  <div class="form-group col-lg-4">
                      <label for="nombre">Nombre(s)</label>
                      <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.nombre.valid ? 'is-valid' : 'is-invalid'" formControlName="nombre">
                      <div class="invalid-feedback">
                        Indique su nombre
                      </div>
                  </div>
                  <div class="form-group col-lg-4">
                      <label for="apellidoPaterno">Apellido Paterno</label>
                      <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.apellidoPaterno.valid ? 'is-valid' : 'is-invalid'" formControlName="apellidoPaterno">
                      <div class="invalid-feedback">
                        Indique su apellido paterno
                      </div>
                  </div>
                  <div class="form-group col-lg-4">
                      <label for="apellidoMaterno">Apellido Materno</label>
                      <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.apellidoMaterno.valid ? 'is-valid' : 'is-invalid'" formControlName="apellidoMaterno">
                      <div class="invalid-feedback">
                        Indique su apellido materno
                      </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group col-lg-6">
                      <label for="telefonoFijo">Telefono Fijo</label>
                      <input type="number" class="form-control" [class]="DatosUsuarioForm.controls.telefonoFijo.valid ? 'is-valid' : 'is-invalid'" formControlName="telefonoFijo">
                      <div class="invalid-feedback">
                        Indique su telefono fijo
                      </div>
                  </div>
                  <div class="form-group col-lg-6">
                      <label for="telefonoMovil">Telefono Movil</label>
                      <input type="number" class="form-control" [class]="DatosUsuarioForm.controls.telefonoMovil.valid ? 'is-valid' : 'is-invalid'" formControlName="telefonoMovil">
                      <div class="invalid-feedback">
                        Indique su telefono movil
                      </div>
                  </div>
              </div>
          </div>
          <div formGroupName="direccion" class="card card-body mt-2">
              <h5>Dirección</h5>
              <p><small>Por el momento solo contamos con envios dentro del estado de <strong>Jalisco</strong> a los municipios de: <strong>Guadalajara, Zapopan, Tonalá, Tlaquepaque y Tlajomulco de Zúñiga</strong></small></p>
              <div class="form-row">
                  <div class="form-group col-lg-4">
                      <label for="calle">Calle</label>
                      <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('calle').valid ? 'is-valid' : 'is-invalid'" formControlName="calle">
                      <div class="invalid-feedback">
                        Indique la calle
                      </div>
                  </div>
                  <div class="form-group col-lg-4">
                    <label for="numeroExterior">Número exterior</label>
                    <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('numeroExterior').valid ? 'is-valid' : 'is-invalid'" formControlName="numeroExterior">
                    <div class="invalid-feedback">
                      Indique su numero exterior
                    </div>
                </div>
                  <div class="form-group col-lg-4">
                      <label for="numeroInterior">Número interior</label>
                      <input type="text" class="form-control" formControlName="numeroInterior">
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group col-lg-2">
                      <label for="codigoPostal">Código postal</label>
                      <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('codigoPostal').valid ? 'is-valid' : 'is-invalid'" formControlName="codigoPostal">
                      <div class="invalid-feedback">
                        Indique su numero postal (5 digitos numéricos)
                      </div>
                  </div>
                  <div class="form-group col-lg-4">
                      <label for="colonia">Colonia</label>
                      <input type="text" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('colonia').valid ? 'is-valid' : 'is-invalid'" formControlName="colonia">
                      <div class="invalid-feedback">
                        Indique su colonia
                      </div>
                  </div>
                  <div class="form-group col-lg-4">
                      <label for="municipio">Municipio</label>
                      <select name="municipio" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('municipio').valid ? 'is-valid' : 'is-invalid'" formControlName="municipio">
                        <option value="" selected>Seleccione</option>
                        <option value="Guadalajara">Guadalajara</option>
                        <option value="Zapopan">Zapopan</option>
                        <option value="Tonalá">Tonalá</option>
                        <option value="Tlaquepaque">Tlaquepaque</option>
                        <option value="Tlajomulco de Zúñiga">Tlajomulco de Zúñiga</option>
                    </select>
                    <div class="invalid-feedback">
                      Seleccione un municipio
                    </div>
                  </div>
                  <div class="form-group col-lg-2">
                      <label for="estado">Estado</label>
                      <select name="estado" class="form-control" [class]="DatosUsuarioForm.controls.direccion.get('estado').valid ? 'is-valid' : 'is-invalid'" formControlName="estado">
                        <option value="" selected>Seleccione</option>
                        <option value="Jalisco">Jalisco</option>
                    </select>
                  </div>
                  <div class="invalid-feedback">
                    Seleccione un estado
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-group col">
                      <label for="referencias">Referencias</label>
                      <input type="text" class="form-control" formControlName="referencias">
                  </div>
              </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="form-row text-center">
          <div class="col">
              <button (click)="irAlCarrito()" class="btn btn-block btn-dark mt-3" style="background-color: rgba(34, 142, 157, 1);">
                Regresar al paso 1 - Su orden de compra...
              </button>
          </div>
          <div class="col">
            <button (click)="alDatosDeEnvio()" [disabled]="DatosUsuarioForm.invalid || !usuarioVerificado" type="submit" class="btn btn-block btn-dark mt-3" style="background-color: rgba(34, 142, 157, 1);">
              Ir al paso 3 - Su forma de pago...
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!(usuario$ | async)" class="card-body">
    <p>Inicie sesion o registrese con nosotros para continuar con su compra...</p>
    <div class="row">
      <div class="col">
        <a routerLink="/Usuarios/Ingreso" class="btn btn-dark btn-block mt-3" style="background-color: rgba(34, 142, 157, 1);">
          Iniciar sesion
        </a>
      </div>
      <div class="col">
        <a routerLink="/Usuarios/Registro" class="btn btn-dark btn-block mt-3" style="background-color: rgba(34, 142, 157, 1);">
          Registro
        </a>
      </div>
    </div>
  </div>
</div>

<div *ngIf="usuario$ | async" class="card mt-3">
  <div class="card-body" *ngIf="pasoCompra=='datosFormaPago'">
    <div class="card">
      <div class="card-header">
        <strong>Paso 1 de 4</strong> - Su orden de compra.
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
        </svg>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col col-lg-3">Productos en su carrito:</div>
          <div class="col">{{ carritoDeCompras.cantidadTotal }}</div>
        </div>
        <div class="row">
          <div class="col col-lg-3">Total de su compra:</div>
          <div class="col">{{ carritoDeCompras.importeTotal | currency:'MXN' }}</div>
        </div>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-header">
        <strong>Paso 2 de 4</strong> - Sus datos de envío.
        <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
        </svg>
      </div>
      <div class="card-body">
        <p>Nombre: {{ DatosUsuarioForm.value.nombre + ' ' + DatosUsuarioForm.value.apellidoPaterno + ' ' + DatosUsuarioForm.value.apellidoMaterno }}</p>
        <p>Email: {{ DatosUsuarioForm.value.email }} </p>
        <p>
          Dirección:
          {{
            DatosUsuarioForm.value.direccion.calle + ' ' +
            DatosUsuarioForm.value.direccion.numeroInterior + ' ' +
            DatosUsuarioForm.value.direccion.numeroExterior + ' Col ' +
            DatosUsuarioForm.value.direccion.colonia + ' C.P. ' +
            DatosUsuarioForm.value.direccion.codigoPostal + ' ' +
            DatosUsuarioForm.value.direccion.municipio + ' ' +
            DatosUsuarioForm.value.direccion.estado + ' (' +
            DatosUsuarioForm.value.direccion.referencias + ')'
          }}
        </p>
        <p>Telefonos: {{ DatosUsuarioForm.value.telefonoFijo + ' - ' + DatosUsuarioForm.value.telefonoMovil }}</p>
      </div>
    </div>
    <div class="card mt-3">
      <div class="card-header">
        <strong>Paso 3 de 4</strong> - Su forma de pago.
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="formaPago">Forma de pago</label>
          <select class="custom-select" name="formaPago" (change)="seleccionaFormaPago($event.target.value)">
            <option value="">Seleccione su forma de pago</option>
            <option value="Efectivo">Efectivo</option>
            <!-- <option value="Tarjeta">Tarjeta de crédito / Debito</option> -->
          </select>
        </div>
        <div [hidden]="formaPago != 'Tarjeta'">
          <ngx-paypal [config]="payPalConfig"></ngx-paypal>
        </div>    
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col">
            <button (click)="irADatosDeEnvio()" class="btn btn-block btn-dark mt-3" style="background-color: rgba(34, 142, 157, 1);">
              Regresar al paso 2 - Sus datos de envio...
            </button>
          </div>
          <div class="col">
              <button [disabled]="formaPago != 'Efectivo'" (click)="confirmarOrdenDeCompraContraEntrega()" class="btn btn-dark btn-block mt-3" style="background-color: rgba(34, 142, 157, 1);">
                Ir al Paso 4 - Confirmar su orden de compra
              </button>
          </div>
        </div>    
      </div>
    </div>
  </div>
</div>

<div *ngIf="pasoCompra == 'compraConfirmada'" class="card">
  <div class="card-header">
    <strong>Paso 4 de 4</strong> - ¡ Hemos recibido su orden de compra !
    <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-check-all" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M8.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14l.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z"/>
    </svg>
  </div>
  <div class="card-body">
    <h5 *ngIf="pedido" class="card-title">Número de orden: {{pedido.id }}</h5>
    <div class="row">
      <div class="col col-lg-3">Productos en su carrito:</div>
      <div class="col">{{ carritoDeCompras.cantidadTotal }}</div>
    </div>
    <div class="row mb-3">
      <div class="col col-lg-3">Total de su compra:</div>
      <div class="col">{{ carritoDeCompras.importeTotal | currency:'MXN' }}</div>
    </div>
    <div *ngIf="pedido" class="row mb-3">
      <div class="col col-lg-3">Forma de pago:</div>
      <div class="col">{{ pedido.formaPago }}</div>
    </div>
    <h5 class="card-title">Sus datos de envio...</h5>
    <p>Nombre: {{ DatosUsuarioForm.value.nombre + ' ' + DatosUsuarioForm.value.apellidoPaterno + ' ' + DatosUsuarioForm.value.apellidoMaterno }}</p>
    <p>Email: {{ DatosUsuarioForm.value.email }} </p>
    <p>
      Dirección:
      {{
        DatosUsuarioForm.value.direccion.calle + ' ' +
        DatosUsuarioForm.value.direccion.numeroInterior + ' ' +
        DatosUsuarioForm.value.direccion.numeroExterior + ' Col ' +
        DatosUsuarioForm.value.direccion.colonia + ' C.P. ' +
        DatosUsuarioForm.value.direccion.codigoPostal + ' ' +
        DatosUsuarioForm.value.direccion.municipio + ' ' +
        DatosUsuarioForm.value.direccion.estado + ' (' +
        DatosUsuarioForm.value.direccion.referencias + ')'
      }}
    </p>
    <p>Telefonos: {{ DatosUsuarioForm.value.telefonoFijo + ' - ' + DatosUsuarioForm.value.telefonoMovil }}</p>
    <a routerLink='/Usuarios/MisPedidos' class="btn btn-block btn-dark mt-3" style="background-color: rgba(34, 142, 157, 1);">
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-basket" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"/>
      </svg>
      Ir a Mis Pedidos
    </a>
  </div>
</div>