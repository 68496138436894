<div class="modal fade" id="tiposproductoModal" tabindex="-1" aria-labelledby="tiposproductoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="tiposproductoModalLabel">Tipos de producto</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form [formGroup]="DatosTipoProductoForm" (ngSubmit)="alEnviar()">
                <div class="modal-content">
                    <div class="container mt-3">
                        <div class="form-group">
                            <input  type="text"
                                    formControlName="nombre"
                                    class="form-control"
                                    aria-describedby="nombreHelp"
                                    placeholder="Nombre"
                                    autocomplete="off">
                            <small id="nombreHelp" class="form-text text-muted">Nombre de la tipoproducto.</small>
                        </div>
                        <div class="form-group">
                            <input  type="text"
                                    formControlName="descripcion"
                                    class="form-control"
                                    aria-describedby="descripcionHelp"
                                    placeholder="Descripción"
                                    autocomplete="off">
                            <small id="descripcionHelp" class="form-text text-muted">Descripción de la tipoproducto.</small>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" formControlName="estado" class="form-control custom-control-input" id="estado">
                                <label class="custom-control-label" for="estado">¿Activo?</label>
                            </div>        
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button style="background-color: rgba(34, 142, 157, 1);" [disabled]="DatosTipoProductoForm.invalid" class="btn btn-primary" type="submit">
                        <div *ngIf="!tipoProductoSeleccionado.id; else Editar">Agregar</div>
                        <ng-template #Editar >Editar</ng-template>
                    </button>
                    <button style="background-color: rgba(34, 142, 157, 1);" type="button" id='BotonCerrar' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                </div>
            </form>
        </div>
    </div>    
</div>