import { ConditionalExpr } from '@angular/compiler';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Marca } from '../marca';
import { MarcasService } from '../marcas.service';

declare var $: any;

@Component({
  selector: 'app-crear-editar-marca',
  templateUrl: './crear-editar-marca.component.html',
  styleUrls: ['./crear-editar-marca.component.css']
})

export class CrearEditarMarcaComponent implements OnInit, OnChanges {

  @Input()
  marcaSeleccionada: Marca = new Marca();

  DatosMarcaForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', Validators.required),
    ruteo: new FormControl('', Validators.required),
    slogan: new FormControl('', Validators.required),
    sitioWeb: new FormControl('', Validators.required),
    estado: new FormControl(''),
    orden: new FormControl('', Validators.required)
  });

  constructor(private servicioMarcas: MarcasService) { }

  ngOnChanges(): void {
    if (this.marcaSeleccionada.id){
      this.DatosMarcaForm.setValue(this.marcaSeleccionada);
    } else {
      this.DatosMarcaForm.reset();
    }
  }

  ngOnInit(): void {

  }

  alEnviar(): void {
    const marca = this.DatosMarcaForm.value;
    if (!this.marcaSeleccionada.id){
      this.servicioMarcas.agregar(marca);
    }
    else {
      this.servicioMarcas.editar({...marca, id: this.marcaSeleccionada.id });
    }
    this.DatosMarcaForm.reset();
    this.marcaSeleccionada = new Marca();
    $('#marcasModal').modal('hide');
  }
}
