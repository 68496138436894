<nav class="navbar navbar-light bg-light">
  <span class="navbar-text">
    .
  </span>
</nav>

<nav class="navbar fixed-top navbar-expand-lg navbar-dark" style="background-color: rgba(34, 142, 157, 1);">
    <a class="navbar-brand" href="#">
      <img src="https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FAparador%2FiconoMyC.png?alt=media&token=b2e43f86-e3ac-4ccf-a2a7-72e2d8058575" width="35" height="35" class="d-inline-block align-top" alt="" loading="lazy">      
    </a>
    <button class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
      </ul>
      <ul class="navbar-nav">
        <li class="nav-item active">
          <button class="btn btn-outline-light mr-2 my-2 my-sm-0">
            <svg width="1.5em" height="1.5em" viewBox="0 0 16 16" class="bi bi-chat-text" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"/>
              <path fill-rule="evenodd" d="M4 5.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zM4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8zm0 2.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5z"/>
            </svg>
            33 18 75 91 95
          </button>
        </li>
        <li class="nav-item active dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Para perros
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <span class="ml-2">Etapa de Vida</span>
            <a class="dropdown-item" href="ListadoProductos/Perro/Cachorro">Cachorro</a>
            <a class="dropdown-item" href="ListadoProductos/Perro/Adulto">Adulto</a>
            <a class="dropdown-item" href="ListadoProductos/Perro/Adulto_7_mas">Adulto 7+</a>
            <a class="dropdown-item" href="ListadoProductos/Perro/Todas_las_edades">Todas las edades</a>
          </div>
        </li>
        <li class="nav-item active dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Para gatos
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <span class="ml-2">Etapa de Vida</span>
              <a class="dropdown-item" href="ListadoProductos/Gato/Gatito">Gatito</a>
              <a class="dropdown-item" href="ListadoProductos/Gato/Adulto">Adulto</a>
              <a class="dropdown-item" href="ListadoProductos/Gato/Adulto_7_mas">Adulto 7+</a>
              <a class="dropdown-item" href="ListadoProductos/Gato/Todas_las_edades">Todas las edades</a>
            </div>
        </li>
        <li class="nav-item active dropdown">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            Nuestras marcas
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="ListadoProductos/Royal_Canin">Royal Canin</a>
            <a class="dropdown-item" href="ListadoProductos/Pro_Plan">ProPlan</a>
            <a class="dropdown-item" href="ListadoProductos/Hills">Hills</a>
            <a class="dropdown-item" href="ListadoProductos/Diamond">Diamond</a>
            <a class="dropdown-item" href="ListadoProductos/Eukanuba">Eukanuba</a>
            <a class="dropdown-item" href="ListadoProductos/Nupec">Nupec</a>
          </div>
        </li>
        <li class="nav-item active dropdown">
          <button class="btn btn-outline-light mr-2 my-2 my-sm-0 dropdown-toggle" type="button" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-person-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.468 12.37C12.758 11.226 11.195 10 8 10s-4.757 1.225-5.468 2.37A6.987 6.987 0 0 0 8 15a6.987 6.987 0 0 0 5.468-2.63z"/>
            <path fill-rule="evenodd" d="M8 9a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
            <path fill-rule="evenodd" d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8z"/>
          </svg>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a *ngIf="!(user$ | async) as user" class="dropdown-item" href="Usuarios/Registro">Registrarse</a>
          <a *ngIf="!(user$ | async) as user" class="dropdown-item" href="/Usuarios/Ingreso">Iniciar Sesion</a>
          <a *ngIf="user$ | async as user" class="dropdown-item" href="/Usuarios/MiPerfil">Mi Perfil</a>
          <a *ngIf="user$ | async as user" class="dropdown-item" href="/Usuarios/MisPedidos">Mis pedidos</a>
          <a *ngIf="user$ | async as user" class="dropdown-item" (click)="cambiarContrasenia()">Cambiar contraseña</a>
          <a *ngIf="user$ | async as user" class="dropdown-item" href="#" (click)="onLogout()">Cerrar Sesion: {{ user?.email }}</a>
          </div>
        </li>
        <li class="nav-item active">
          <a href="/CarritoDeCompras">
            <button class="btn btn-outline-light mr-2 my-2 my-sm-0">
              <span *ngIf="carritoDeCompras">
                {{carritoDeCompras.cantidadTotal}}
              </span>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cart4" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5zM3.14 5l.5 2H5V5H3.14zM6 5v2h2V5H6zm3 0v2h2V5H9zm3 0v2h1.36l.5-2H12zm1.11 3H12v2h.61l.5-2zM11 8H9v2h2V8zM8 8H6v2h2V8zM5 8H3.89l.5 2H5V8zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0z"/>
              </svg>
            </button>
          </a>
        </li>
        <li *ngIf="user$ | async as user" class="nav-item active dropdown">
          <button *ngIf="user?.email == 'etorresbla@gmail.com' || user?.email == 'bobadilla.gustavo@gmail.com'" class="btn btn-outline-light mr-2 my-2 my-sm-0 dropdown-toggle" type="button" role="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-gear-wide-connected" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M8.932.727c-.243-.97-1.62-.97-1.864 0l-.071.286a.96.96 0 0 1-1.622.434l-.205-.211c-.695-.719-1.888-.03-1.613.931l.08.284a.96.96 0 0 1-1.186 1.187l-.284-.081c-.96-.275-1.65.918-.931 1.613l.211.205a.96.96 0 0 1-.434 1.622l-.286.071c-.97.243-.97 1.62 0 1.864l.286.071a.96.96 0 0 1 .434 1.622l-.211.205c-.719.695-.03 1.888.931 1.613l.284-.08a.96.96 0 0 1 1.187 1.187l-.081.283c-.275.96.918 1.65 1.613.931l.205-.211a.96.96 0 0 1 1.622.434l.071.286c.243.97 1.62.97 1.864 0l.071-.286a.96.96 0 0 1 1.622-.434l.205.211c.695.719 1.888.03 1.613-.931l-.08-.284a.96.96 0 0 1 1.187-1.187l.283.081c.96.275 1.65-.918.931-1.613l-.211-.205a.96.96 0 0 1 .434-1.622l.286-.071c.97-.243.97-1.62 0-1.864l-.286-.071a.96.96 0 0 1-.434-1.622l.211-.205c.719-.695.03-1.888-.931-1.613l-.284.08a.96.96 0 0 1-1.187-1.186l.081-.284c.275-.96-.918-1.65-1.613-.931l-.205.211a.96.96 0 0 1-1.622-.434L8.932.727zM8 12.997a4.998 4.998 0 1 0 0-9.995 4.998 4.998 0 0 0 0 9.996z"/>
              <path fill-rule="evenodd" d="M7.375 8L4.602 4.302l.8-.6L8.25 7.5h4.748v1H8.25L5.4 12.298l-.8-.6L7.376 8z"/>
            </svg>
          </button>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <div>
              <a class="dropdown-item" href="/CatalogoMarcas">Marcas</a>
              <a class="dropdown-item" href="/CatalogoEspecies">Especies</a>
              <a class="dropdown-item" href="/CatalogoEdades">Etapas de vida</a>
              <a class="dropdown-item" href="/CatalogoTiposProducto">Tipos de producto</a>
              <a class="dropdown-item" href="/CatalogoProductos">Productos</a>  
              <a class="dropdown-item" href="/Pedidos">Pedidos</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
</nav>