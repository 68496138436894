import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Producto, Presentacion } from '../catalogos/catalogo-productos/producto';
import { CarritoDeCompras } from './carrito-de-compras';

@Injectable({
  providedIn: 'root'
})

export class CarritoDeComprasService {

  private sujetoCarritoDeCompras = new Subject<CarritoDeCompras>();
  public carritoDeCompras$ = this.sujetoCarritoDeCompras.asObservable();
  public carritoDeCompras: CarritoDeCompras;

  constructor() { }

  consultar(): CarritoDeCompras{
    let carrito: CarritoDeCompras;
    try {
      carrito = JSON.parse(localStorage.getItem('CarritoDeCompras'));
      if (!carrito){
        carrito = new CarritoDeCompras();
        carrito.productos = new Array();
      }
      return carrito;
    }
    catch (error){
      console.log(error);
    }
  }

  reiniciarCarrito(): void {
    localStorage.removeItem('CarritoDeCompras');
    this.recalculaTotales();
  }

  cantidadProductoEnElCarrito(
    pmtProducto: Producto,
    pmtPresentacion: Presentacion): number {
      const carrito: CarritoDeCompras = this.consultar();
      if (!carrito.productos.find(x =>  x.idProducto === pmtProducto.id && x.sku === pmtPresentacion.sku)){
        return 0;
      } else {
        return carrito.productos.find(x =>  x.idProducto === pmtProducto.id && x.sku === pmtPresentacion.sku).cantidad;
      }
  }

  agregarProductoLS(
    pmtProducto: Producto,
    pmtPresentacion: Presentacion): void {

      let carrito: CarritoDeCompras;
      try {
        carrito = this.consultar();

        if (!carrito.productos.find(x =>  x.idProducto === pmtProducto.id && x.sku === pmtPresentacion.sku)){
          carrito.productos.push({
            idProducto: pmtProducto.id,
            nombreProducto: pmtProducto.nombre,
            urlImagen: pmtProducto.urlImagen,
            sku: pmtPresentacion.sku,
            descripcionPresentacion: pmtPresentacion.descripcion,
            cantidad: 1,
            precio: pmtPresentacion.precio
          });
          localStorage.setItem('CarritoDeCompras', JSON.stringify(carrito));
          this.recalculaTotales();
        } else {
          this.actualizarCantidad({
            idProducto: pmtProducto.id,
            sku: pmtPresentacion.sku
          }, '+');
        }
      }
      catch (error){
        console.log(error);
      }
  }

  eliminarProductoLS(pmtProducto): void {
    let carrito: CarritoDeCompras;
    try {
      carrito = this.consultar();
      const indexEliminar = carrito.productos.findIndex(x => x.idProducto === pmtProducto.idProducto && x.sku === pmtProducto.sku);
      carrito.productos.splice(indexEliminar, 1);
      localStorage.setItem('CarritoDeCompras', JSON.stringify(carrito));
      this.recalculaTotales();
    }
    catch (error){
      console.log(error);
    }
  }

  actualizarCantidad(pmtProducto, pmtTipoActualizacion): void {
    let carrito: CarritoDeCompras;
    try {
      carrito = this.consultar();
      if (pmtTipoActualizacion === '+'
          && carrito.productos.find(x => x.idProducto === pmtProducto.idProducto && x.sku === pmtProducto.sku).cantidad < 11){
        carrito.productos.find(x => x.idProducto === pmtProducto.idProducto && x.sku === pmtProducto.sku).cantidad++;
      } else {
        carrito.productos.find(x => x.idProducto === pmtProducto.idProducto && x.sku === pmtProducto.sku).cantidad--;
      }
      localStorage.setItem('CarritoDeCompras', JSON.stringify(carrito));
      this.recalculaTotales();
    }
    catch (error){
      console.log(error);
    }
  }

  recalculaTotales(): void {
    let carrito: CarritoDeCompras;
    try {
      carrito = this.consultar();
      let cantidadTotal = 0;
      let importeTotal = 0;

      carrito.productos.forEach(producto => {
        cantidadTotal += producto.cantidad;
        importeTotal += producto.precio * producto.cantidad;
      });

      carrito.cantidadTotal = cantidadTotal;
      carrito.importeTotal = importeTotal;

      localStorage.setItem('CarritoDeCompras', JSON.stringify(carrito));
      this.sujetoCarritoDeCompras.next(carrito);
    }
    catch (error){
      console.log(error);
    }
  }
}
