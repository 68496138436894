import { Injectable } from '@angular/core';
import { Mascota, Usuario } from './usuario';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class UsuariosService {

  private coleccionUsuarios = this.firestore.collection('Usuarios', ref => ref.orderBy('nombre'));

  constructor(private firestore: AngularFirestore) { }

  obtenerPorEmail(pmtEMail: string): Observable<Usuario[]>{
    const busquedaUsuarioEmail = this.firestore.collection('Usuarios', ref => ref.where('email', '==', pmtEMail));
    const usuario$ = busquedaUsuarioEmail.snapshotChanges().pipe(
      map(actions => {
        return actions.map(p => {
          const usuario = p.payload.doc;
          const id = usuario.id;
          const objUsuario = { id, ...(usuario.data() as {}) } as Usuario;
          if (objUsuario.mascotas){
            objUsuario.mascotas.forEach(element => {
              element.especie.get().then(x => element.nombreEspecie = x.data().nombre);
            });
          }
          return objUsuario;
        });
      })
    );
    return usuario$;
  }

  async agregar(pmtUsuario: Usuario): Promise<void> {
    try {
      await this.coleccionUsuarios.add(
        {
          email: pmtUsuario.email,
          nombre: pmtUsuario.nombre,
          apellidoPaterno: pmtUsuario.apellidoPaterno,
          apellidoMaterno: pmtUsuario.apellidoMaterno,
          sexo: pmtUsuario.sexo,
          telefonoFijo: pmtUsuario.telefonoFijo,
          telefonoMovil: pmtUsuario.telefonoMovil,
          edad: pmtUsuario.edad,
          direccion: {
            calle: pmtUsuario.direccion.calle,
            numeroInterior: pmtUsuario.direccion.numeroInterior,
            numeroExterior: pmtUsuario.direccion.numeroExterior,
            codigoPostal: pmtUsuario.direccion.codigoPostal,
            colonia: pmtUsuario.direccion.colonia,
            municipio: pmtUsuario.direccion.municipio,
            estado: pmtUsuario.direccion.estado,
            referencias: pmtUsuario.direccion.referencias
          }
        }
      );
    } catch (e) {
      throw (e);
    }
  }

  async editar(pmtUsuario: Usuario): Promise<void> {
    try {
      await this.coleccionUsuarios
        .doc(pmtUsuario.id)
        .set(
          {
            email: pmtUsuario.email,
            nombre: pmtUsuario.nombre,
            apellidoPaterno: pmtUsuario.apellidoPaterno,
            apellidoMaterno: pmtUsuario.apellidoMaterno,
            sexo: pmtUsuario.sexo,
            telefonoFijo: pmtUsuario.telefonoFijo,
            telefonoMovil: pmtUsuario.telefonoMovil,
            edad: pmtUsuario.edad,
            direccion: {
              calle: pmtUsuario.direccion.calle,
              numeroInterior: pmtUsuario.direccion.numeroInterior,
              numeroExterior: pmtUsuario.direccion.numeroExterior,
              codigoPostal: pmtUsuario.direccion.codigoPostal,
              colonia: pmtUsuario.direccion.colonia,
              municipio: pmtUsuario.direccion.municipio,
              estado: pmtUsuario.direccion.estado,
              referencias: pmtUsuario.direccion.referencias
            }
          },
          {
            merge: true
          }
        );
    } catch (e) {
      throw (e)
    }
  }

  async agregarEditarMascota(pmtIdUsuario: string, pmtListaMascotas: Mascota[]): Promise<void> {
    await this.coleccionUsuarios.doc(pmtIdUsuario).set(
      {
        mascotas: pmtListaMascotas
      },
      {
        merge: true
      }
    );
  }
}
