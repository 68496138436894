import { Component, OnInit } from '@angular/core';
import { EspeciesService } from '../catalogos/catalogo-especies/especies.service';
import { EdadesService } from '../catalogos/catalogo-edades/edades.service';
import { MarcasService } from '../catalogos/catalogo-marcas/marcas.service';

@Component({
  selector: 'app-aparador',
  templateUrl: './aparador.component.html',
  styleUrls: ['./aparador.component.css']
})

export class AparadorComponent implements OnInit {

  marcas$ = this.servicioMarcas.marcasActivas$;
  especies$ = this.servicioEspecies.especiesActivas$;
  edades$ = this.servicioEdades.edadesActivas$;

  constructor(
    private servicioEspecies: EspeciesService,
    private servicioEdades: EdadesService,
    private servicioMarcas: MarcasService
    ) { }

  ngOnInit(): void {
  }
}
