export const ProductosEukanuba =
{
    Eukanuba: [
        {
            Id: '1',
            Sku: 'E1',
            Ean: '-',
            Nombre: 'PUPPY SB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para cachorros de raza pequeña (hasta 10 kg de peso en la edad adulta) y de los 2 a los 12 meses de edad.',
            Beneficios: 'FÁCIL DIGESTIÓN Combinación de fibra natural y prebióticos para cachorros activos. MENTE NIVELES ÓPTIMOS DE DHA Niveles clínicamente comprobados de DHA para cachorros más listos y fáciles de entrenar. SOPORTE DEL SISTEMA INMUNOLÓGICO NO ÓPTIMO DESARROLLO DE HUESOS Y MÚSCULOS Contenido garantizado de antioxidantes (Vitamina E).',
            Ingredientes: 'Pollo, harina de pollo, maíz, grasa de pollo, trigo, grano molido de sorgo, arroz cervecero, saborizante natural (ave), pulpa de remolacha deshidratada, aceite de pescado, producto de huevo de gallina, fosfato dicálcico, cloruro de potasio, sal, fructooligosacáridos, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 32.0 % Grasa Cruda (mín.) 21.0 % Fibra Cruda (mín.) 3.2 % Humedad (máx.) 10.0% Ácido Docosahexaenoico (DHA) 0.11% Calcio (mín.) 1.3% Fósforo (mín.) 1.02% Vitamina E (mín.) 280 UI/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.700',
            UnidadesCaja: '-',
            Costo: '363',
            Precio: '485'
        },
        {
            Id: '1',
            Sku: 'E2',
            Ean: '-',
            Nombre: 'PUPPY SB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '6.800',
            UnidadesCaja: '-',
            Costo: '880.62',
            Precio: '1179'
        },
        {
            Id: '2',
            Sku: 'E3',
            Ean: '-',
            Nombre: 'PUPPY MB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para cachorros de raza mediana (de los 11 kg a los 24 kg de peso en la edad adulta) y de 2 meses a los 12 meses de edad.',
            Beneficios: 'FÁCIL DIGESTIÓN Combinación de fibra natural y prebióticos para cachorros activos. MENTE NIVELES ÓPTIMOS DE DHA Niveles clínicamente comprobados de DHA para cachorros más listos y fáciles de entrenar. SOPORTE DEL SISTEMA INMUNOLÓGICO NO ÓPTIMO DESARROLLO DE HUESOS Y MÚSCULOS Contenido garantizado de antioxidantes (Vitamina E).',
            Ingredientes: 'Pollo, harina de pollo, maíz, grasa de pollo, trigo, grano molido de sorgo, arroz cervecero, saborizante natural (ave), pulpa de remolacha deshidratada, aceite de pescado, producto de huevo de gallina, cloruro de potasio, sal, fosfato dicálcico, fructoligosacáridos, cloruro de colina, carbonato de calcio, vitaminas [acetato DLalfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (Vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, slenito de sodio, yodato de calcio], óxido de magnesio, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 29.0 % Grasa Cruda (mín.) 18.0 % Fibra Cruda (mín.) 3.4 % Humedad (máx.) 10.0% Ácido Docosahexaenoico (DHA) 0.11% Calcio (mín.) 1.13% Fósforo (mín.) 0.98% Vitamina E (mín.) 280 UI/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.300',
            UnidadesCaja: '-',
            Costo: '332.37',
            Precio: '445'
        },
        {
            Id: '2',
            Sku: 'E4',
            Ean: '-',
            Nombre: 'PUPPY MB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '15.000',
            UnidadesCaja: '-',
            Costo: '1562.22',
            Precio: '2095'
        },
        {
            Id: '3',
            Sku: 'E5',
            Ean: '-',
            Nombre: 'PUPPY LB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para cachorros de raza grande (de los 25 kg a los 40 kg de peso en la edad adulta) y de 2 meses a los 15 meses de edad. Formulado para cachorros de raza gigante (a partir de los 40 kg de peso en la edad adulta) y de 2 meses a los 15 meses de edad.',
            Beneficios: 'FÁCIL DIGESTIÓN Combinación de fibra natural y prebióticos para cachorros activos. MENTE NIVELES ÓPTIMOS DE DHA Niveles clínicamente comprobados de DHA para cachorros más listos y fáciles de entrenar. SOPORTE DEL SISTEMA INMUNOLÓGICO NO ÓPTIMO DESARROLLO DE HUESOS Y MÚSCULOS Contenido garantizado de antioxidantes (Vitamina E).',
            Ingredientes: 'Pollo, harina de pollo, maíz, trigo, grasa de pollo, grano molido de sorgo, arroz cervecero, saborizante natural (ave), pulpa de remolacha deshidratada, aceite de pescado, producto de huevo de gallina, sal, cloruro de potasio, fosfato dicálcico, fructooligosacáridos, cloruro de colina, carbonato de calcio, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 26.0 % Grasa Cruda (mín.) 14.0 % Fibra Cruda (mín.) 3.7 % Humedad (máx.) 10.0% Ácido Docosahexaenoico (DHA) 0.11% Calcio (mín.) 1.08% Fósforo (mín.) 0.94% Vitamina E (mín.) 280 UI/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.300',
            UnidadesCaja: '-',
            Costo: '332.37',
            Precio: '445'
        },
        {
            Id: '3',
            Sku: 'E6',
            Ean: '-',
            Nombre: 'PUPPY LB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '7.300',
            UnidadesCaja: '-',
            Costo: '940.17',
            Precio: '1255'
        },
        {
            Id: '3',
            Sku: 'E7',
            Ean: '-',
            Nombre: 'PUPPY LB',
            Especie: 'Perro',
            Edad: 'Cachorro',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '15.000',
            UnidadesCaja: '-',
            Costo: '1562.22',
            Precio: '2095'
        },
        {
            Id: '4',
            Sku: 'E8',
            Ean: '-',
            Nombre: 'ADULT SB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para cachorros de raza mediana (de los 11 kg a los 24 kg de peso en la edad adulta) y de 2 meses a los 12 meses de edad.',
            Beneficios: 'DIENTES LIMPIOS, ENCÍAS SALUDABLES Está demostrado que Eukanuba® 3D DentaDefense™ reduce la formación de sarro. FÁCIL DIGESTIÓN Combinación de fibra natural y prebióticos para perros activos.',
            Ingredientes: 'Pollo, harina de pollo, maíz, grasa de pollo, trigo, grano molido de sorgo, saborizante natural (ave), pulpa de remolacha deshidratada, producto de huevo de gallina, cloruro de potasio, hexametafosfato de sodio, fructooligosacáridos, sal, carbonato de calcio, aceite de pescado, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], clorhidrato de glucosamina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 28.0 % Grasa Cruda (mín.) 18.0 % Fibra Cruda (mín.) 3.6 % Humedad (máx.) 10.0% Ácido Docosahexaenoico (DHA) 0.01% Vitamina E (mín.) 154 UI/kg Glucosamina* (mín.) 218 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.300',
            UnidadesCaja: '-',
            Costo: '309.26',
            Precio: '415'
        },
        {
            Id: '4',
            Sku: 'E9',
            Ean: '-',
            Nombre: 'ADULT SB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '6.800',
            UnidadesCaja: '-',
            Costo: '789.29',
            Precio: '1059'
        },
        {
            Id: '5',
            Sku: 'E10',
            Ean: '-',
            Nombre: 'ADULT MB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para adultos de raza mediana (de los 11 kg a los 24 kg de peso en la edad adulta) de 1 a 7 años de edad',
            Beneficios: 'DIENTES LIMPIOS, ENCÍAS SALUDABLES Está demostrado que Eukanuba® 3D DentaDefense™ reduce la formación de sarro. MENTE DHA Y ANTIOXIDANTES PARA UNA FUNCIÓN CEREBRAL SALUDABLE DHA y antioxidantes que mantienen una función cerebral saludable. FÁCIL DIGESTIÓN Combinación de fibra natural y prebióticos para perros activos.',
            Ingredientes: 'Pollo, harina de pollo, maíz, trigo, grasa de pollo, grano molido de sorgo, saborizante natural (ave), pulpa de remolacha deshidratada, producto de huevo de gallina, cloruro de potasio, hexametafosfato de sodio, sal, fructooligosacáridos, carbonato de calcio, aceite de pescado, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], clorhidrato de glucosamina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 25.0 % Grasa Cruda (mín.) 16.0 % Fibra Cruda (mín.) 3.8 % Humedad (máx.) 10.0% Ácido Docosahexaenoico (DHA) 0.01% Vitamina E (mín.) 154 UI/kg Glucosamina* (mín.) 218 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.300',
            UnidadesCaja: '-',
            Costo: '285.06',
            Precio: '385'
        },
        {
            Id: '5',
            Sku: 'E11',
            Ean: '-',
            Nombre: 'ADULT MB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '7.300',
            UnidadesCaja: '-',
            Costo: '801.75',
            Precio: '1075'
        },
        {
            Id: '6',
            Sku: 'E12',
            Ean: '-',
            Nombre: 'ADULT LB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para adultos de raza grande (de los 25 kg a los 40 kg de peso en la edad adulta) a partir de los 15 meses de edad. Formulado para adultos de raza gigante (a partir de los 40 kg de peso en la edad adulta) a partir de los 2 años de edad.',
            Beneficios: 'DIENTES LIMPIOS, ENCÍAS SALUDABLES Está demostrado que Eukanuba® 3D DentaDefense™ reduce la formación de sarro. FÁCIL DIGESTIÓN Combinación de fibra natural y prebióticos para perros activos.',
            Ingredientes: 'Pollo, maíz, harina de pollo, trigo, grano molido de sorgo, grasa de pollo, saborizante natural (ave), pulpa de remolacha deshidratada, producto de huevo de gallina, cloruro de potasio, carbonato de calcio, hexametafosfato de sodio, sal, fructooligosacáridos, aceite de pescado, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], DL-metionina, clorhidrato de glucosamina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 23.0 % Grasa Cruda (mín.) 13.0 % Fibra Cruda (mín.) 3.9 % Humedad (máx.) 10.0% Ácido Docosahexaenoico (DHA) 0.01% Vitamina E (mín.) 154 UI/kg Glucosamina* (mín.) 368 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '15.000',
            UnidadesCaja: '-',
            Costo: '1442.22',
            Precio: '1935'
        },
        {
            Id: '7',
            Sku: 'E13',
            Ean: '-',
            Nombre: 'SENIOR SB',
            Especie: 'Perro',
            Edad: 'Senior',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para perros maduros de raza pequeña (hasta 10 kg de peso en la edad adulta) y a partir de los 7 años de edad',
            Beneficios: 'DIENTES LIMPIOS, ENCÍAS SALUDABLES Está demostrado que Eukanuba® 3D DentaDefense™ reduce la formación de sarro. REFUERZO DEL SISTEMA INMUNOLÓGICO Niveles garantizados de antioxidantes para perros senior activos',
            Ingredientes: 'Pollo, harina de pollo, trigo, grasa de pollo, maíz, grano molido de sorgo, saborizante natural (ave), pulpa de remolacha deshidratada, aceite vegetal, producto de huevo de gallina, cloruro de potasio, carbonato de calcio, hexametafosfato de sodio, fructooligosacáridos, sal, aceite de pescado, cloruro de colina, vitaminas [acetato DLalfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], clorhidrato de glucosamina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, caroteno, sulfato de condroitina, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 29.0 % Grasa Cruda (mín.) 17.0 % Fibra Cruda (mín.) 3.7 % Humedad (máx.) 10.0 % Ácido Docosahexaenoico (DHA) 0.01 % Vitamina E (mín.) 280 UI/kg Caroteno* 10 mg/kg Glucosamina* (mín.) 368 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.300',
            UnidadesCaja: '-',
            Costo: '319.02',
            Precio: '429'
        },
        {
            Id: '8',
            Sku: 'E14',
            Ean: '-',
            Nombre: 'SENIOR MB',
            Especie: 'Perro',
            Edad: 'Senior',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para perros maduros de raza mediana (de los 11 kg a los 24 kg de peso en la edad adulta) a partir de los 7 años de edad.',
            Beneficios: 'DIENTES LIMPIOS, ENCÍAS SALUDABLES Está demostrado que Eukanuba® 3D DentaDefense™ reduce la formación de sarro. REFUERZO DEL SISTEMA INMUNOLÓGICO Niveles garantizados de antioxidantes para perros senior activos.',
            Ingredientes: 'Pollo, harina de pollo, trigo, maíz, grasa de pollo, grano molido de sorgo, saborizante natural (ave), pulpa de remolacha deshidratada, aceite vegetal, producto de huevo de gallina, cloruro de potasio, carbonato de calcio, hexametafosfato de sodio, sal, fructooligosacáridos, aceite de pescado, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], clorhidrato de glucosamina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, caroteno, sulfato de condroitina, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 27.0 % Grasa Cruda (mín.) 12.0 % Fibra Cruda (mín.) 3.8 % Humedad (máx.) 10.0 % Ácido Docosahexaenoico (DHA) 0.01 % Vitamina E (mín.) 280 UI/kg Caroteno* 10 mg/kg Glucosamina* (mín.) 368 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '13.600',
            UnidadesCaja: '-',
            Costo: '1356.75',
            Precio: '1819'
        },
        {
            Id: '9',
            Sku: 'E15',
            Ean: '-',
            Nombre: 'WEIGHT CONTROL SB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para adultos de raza pequeña (hasta 10 kg de peso en la edad adulta) y de 1 a 7 años de edad.',
            Beneficios: 'MENTE DHA y antioxidantes que mantienen una función cerebral saludable',
            Ingredientes: 'Pollo, harina de pollo, trigo, maíz, cebada, grano molido de sorgo, grasa de pollo, saborizante natural (ave), pulpa de remolacha deshidratada, aceite vegetal, producto de huevo de gallina, carbonato de calcio, cloruro de potasio, hexametafosfato de sodio, fructooligosacáridos, sal, aceite de pescado, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], clorhidrato de glucosamina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, L-carnitina, sulfato de condroitina, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico.',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 29.0 % Grasa Cruda (mín.) 10.0 % Fibra Cruda (mín.) 3.9 % Humedad (máx.) 10.0 % Ácido Docosahexaenoico (DHA) 0.01 % Vitamina E (mín.) 154 UI/kg L-Carnitina* 35 mg/kg Glucosamina* (mín.) 368 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '2.300',
            UnidadesCaja: '-',
            Costo: '337.84',
            Precio: '455'
        },
        {
            Id: '10',
            Sku: 'E16',
            Ean: '-',
            Nombre: 'WEIGHT CONTROL MB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Descripcion: 'Formulado para adultos de raza mediana (de los 11 kg a los 24 kg de peso en la edad adulta) de 1 a 7 años de edad.',
            Beneficios: 'MENTE DHA y antioxidantes que mantienen una función cerebral saludable.',
            Ingredientes: 'Pollo, harina de pollo, trigo, maíz, cebada, grano molido de sorgo, saborizante natural (ave), grasa de pollo, pulpa de remolacha deshidratada, aceite vegetal, producto de huevo de gallina, cloruro de potasio, carbonato de calcio, hexametafosfato de sodio, fructooligosacáridos, sal, aceite de pescado, cloruro de colina, vitaminas [acetato DL-alfa-tocoferol (fuente de vitamina E), biotina, D-pantotenato de calcio, acetato de vitamina A, suplemento de niacina, clorhidrato de piridoxina (vitamina B6), mononitrato de tiamina (vitamina B1), suplemento de vitamina B12, suplemento de riboflavina, suplemento de vitamina D3, ácido fólico], clorhidrato de glucosamina, DL-metionina, oligoelementos [óxido de zinc, sulfato ferroso, sulfato de cobre, selenito de sodio, yodato de calcio], óxido de magnesio, L-carnitina, sulfato de condroitina, extracto de romero, conservado con mezcla de tocoferoles y ácido cítrico',
            AnalisisGarantizado: 'Proteina Cruda (mín.) 27.0 % Grasa Cruda (mín.) 9.0 % Fibra Cruda (mín.) 3.9 % Humedad (máx.) 10.0 % Ácido Docosahexaenoico (DHA) 0.01 % Vitamina E (mín.) 154 UI/kg L-Carnitina* 35 mg/kg Glucosamina* (mín.) 368 mg/kg Sulfato de Condroitina* (mín.) 8 mg/kg',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '6.800',
            UnidadesCaja: '-',
            Costo: '801.75',
            Precio: '1075'
        },
        {
            Id: '10',
            Sku: 'E17',
            Ean: '-',
            Nombre: 'WEIGHT CONTROL MB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '13.600',
            UnidadesCaja: '-',
            Costo: '1311.75',
            Precio: '1759'
        },
        {
            Id: '11',
            Sku: 'E18',
            Ean: '-',
            Nombre: 'PREMIUM PERFORMANCE',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Seco',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '13.200',
            UnidadesCaja: '-',
            Costo: '1349.25',
            Precio: '1809'
        },
        {
            Id: '12',
            Sku: 'E19',
            Ean: '-',
            Nombre: 'HEALTY EXT PP',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Premios',
            Descripcion: 'Los premios Eukanuba® Healthy Extras Puppy están recomendadas para cachorros de 1 a 12 meses de cualquier talla y raza.',
            Beneficios: 'Óptimo contenido de Grasas, Carbohidratos, Vitaminas, Minerales y Proteínas para promover el peso saludable. Contiene calcio para el cuidado de huesos.',
            Ingredientes: 'Harina de trigo, harina de sub-productos (menudencias y/o vísceras) de pollo, grasa de pollo (preservada con tocoferoles mixtos, fuente de vitamina E), pulpa de remolacha seca, sabor natural a pollo, harina de pescado (fuente de aceite de pescado), sal, levadura de cerveza seca (no viable), producto de huevo seco, fosfato dicálcico, cloruro de potasio, harina de lino, vitaminas (suplemento de vitamina E, ácido ascórbico, beta caroteno, acetato de vitamina A, pantotenato de calcio, biotina, suplemento de vitamina B12, mononitrato de tiamina (fuente de vitamina B1), niacina, suplemento de riboflavina (fuente de vitamina B2), inositol, hidrocloruro de piridoxina (fuente de vitamina B6), suplemento de vitamina D3, ácido fólico), cloruro de colina, carbonato de calcio, minerales (sulfato ferroso, óxido de zinc, sulfato de manganeso, sulfato de cobre, óxido manganoso, yoduro de potasio), sorbato de potasio (como conservador), ácido cítrico, extracto de romero.',
            AnalisisGarantizado: 'Proteína cruda mínimo 27.0%, Grasa cruda mínimo 14.0%, Fibra cruda máximo 3.0%, Humedad máximo 11.0%, cenizas máximo 9.0%, vitamina E mínimo 140 UI/kg, Ácido graso Omega-6 mínimo 1.5%*, Ácido graso Omega-3 mínimo 0.25%*. Energía metabolizable: 3,586 kcal/kg. *No reconocidos como nutrientes esenciales por el perfil de nutrientes de alimentos para perros de la AAFCO.',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '0.397',
            UnidadesCaja: '-',
            Costo: '138',
            Precio: '185'
        },
        {
            Id: '13',
            Sku: 'E20',
            Ean: '-',
            Nombre: 'HEALTY EXT AD SB',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Premios',
            Descripcion: 'Las croquetas de Eukanuba® Healthy Extras Small Breed están recomendados para perros adultos de raza pequeña que pesan menos de 10 Kg.',
            Beneficios: 'Óptimo contenido de Grasas, Carbohidratos, Vitaminas, Minerales y Proteínas para promover el peso saludable. Contiene calcio para el cuidado de huesos.',
            Ingredientes: 'Harina de trigo, harina de sub-productos (menudencias y/o vísceras) de pollo, pulpa de remolacha seca, grasa de pollo (preservada con tocoferoles mixtos, una fuente de vitamina E), sabor natural a pollo, harina de pescado (fuente de aceite de pescado), levadura de cerveza seca (no viable), producto de huevo seco, cloruro de potasio, sal, carbonato de calcio, vitaminas (suplemento de vitamina E, ácido ascórbico, beta caroteno, acetato de vitamina A, pantotenato de calcio, biotina, mononitrato de tiamina (fuente de vitamina B1), suplemento de vitamina B12, niacina, suplemento de riboflavina (fuente de vitamina B2), inositol, hidrocloruro de piridoxina (fuente de vitamina B6), suplemento de vitamina D3, ácido fólico), harina de lino, cloruro de colina, fosfato dicálcico, minerales (sulfato ferroso, óxido de zinc, sulfato de manganeso, sulfato de cobre, óxido de manganeso, yoduro de potasio), sorbato de potasio (como conservador), ácido cítrico, DL-metionina, extracto de romero',
            AnalisisGarantizado: 'Proteína cruda mínimo 25,0%, Grasa cruda mínimo 9.0%, Fibra cruda máximo 3,0%, Humedad máxima 11,0%, Vitamina E mínimo 140 UI / kg, Ácidos grasos Omega-6 mínimo 1.3%*; Ácidos grasos Omega-3 mínimo 0,15%*. Energía metabolizable: 3,390 kcal/kg. *No reconocido como nutriente esencial en los perfiles nutricionales de alimentos para perros de la AAFCO.',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '0.397',
            UnidadesCaja: '-',
            Costo: '139.62',
            Precio: '187'
        },
        {
            Id: '14',
            Sku: 'E21',
            Ean: '-',
            Nombre: 'HEALTY EXT AD T',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Premios',
            Descripcion: 'Las croquetas de Eukanuba® Healthy Extras Adult Maintenance están recomendados para perros adultos de talla mediana y grande que pesan más de 10 kg.',
            Beneficios: 'Óptimo contenido de Grasas, Carbohidratos, Vitaminas, Minerales y Proteínas para promover el peso saludable. Contiene calcio para el cuidado de huesos.',
            Ingredientes: 'Harina de trigo, harina de sub-productos (menudencias y/o vísceras) de pollo, pulpa de remolacha seca, grasa de pollo (preservada con tocoferoles mixtos, una fuente de vitamina E), sabor natural a pollo, harina de pescado (fuente de aceite de pescado), levadura de cerveza seca (no viable), producto de huevo seco, cloruro de potasio, sal, carbonato de calcio, vitaminas (suplemento de vitamina E, ácido ascórbico, beta caroteno, acetato de vitamina A, pantotenato de calcio, biotina, mononitrato de tiamina (fuente de vitamina B1), suplemento de vitamina B12, niacina, suplemento de riboflavina (fuente de vitamina B2), inositol, hidrocloruro de piridoxina (fuente de vitamina B6), suplemento de vitamina D3, ácido fólico), harina de lino, cloruro de colina, fosfato dicálcico, minerales (sulfato ferroso, óxido de zinc, sulfato de manganeso, sulfato de cobre, óxido manganoso, yoduro de potasio), sorbato de potasio (como conservador), ácido cítrico, DL-metionina, extracto de romero.',
            AnalisisGarantizado: 'Proteína cruda mínimo 25.0%; Grasa cruda mínimo 9.0%; Fibra cruda máximo 3.0%; Humedad máximo 11.0%; Vitamina E mínimo 140 UI/kg; Ácido graso Omega-6 mínimo 1.30%*; Ácido graso Omega-3 mínimo 0.15%*. Energía metabolizable: 3,390 kcal/kg. *No reconocidos como nutrientes esenciales por el perfil de nutrientes de alimentos para perros de la AAFCO.',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '0.341',
            UnidadesCaja: '-',
            Costo: '124.02',
            Precio: '167'
        },
        {
            Id: '15',
            Sku: 'E21',
            Ean: '-',
            Nombre: 'HEALTY EXT AD T',
            Especie: 'Perro',
            Edad: 'Adulto',
            TipoDeProducto: 'Premios',
            Descripcion: 'Las croquetas de Eukanuba® Healthy Extras Adult Maintenance están recomendados para perros adultos de talla mediana y grande que pesan más de 10 kg.',
            Beneficios: 'Óptimo contenido de Grasas, Carbohidratos, Vitaminas, Minerales y Proteínas para promover el peso saludable. Contiene calcio para el cuidado de huesos.',
            Ingredientes: 'Harina de trigo, harina de sub-productos (menudencias y/o vísceras) de pollo, pulpa de remolacha seca, grasa de pollo (preservada con tocoferoles mixtos, una fuente de vitamina E), sabor natural a pollo, harina de pescado (fuente de aceite de pescado), levadura de cerveza seca (no viable), producto de huevo seco, cloruro de potasio, sal, carbonato de calcio, vitaminas (suplemento de vitamina E, ácido ascórbico, beta caroteno, acetato de vitamina A, pantotenato de calcio, biotina, mononitrato de tiamina (fuente de vitamina B1), suplemento de vitamina B12, niacina, suplemento de riboflavina (fuente de vitamina B2), inositol, hidrocloruro de piridoxina (fuente de vitamina B6), suplemento de vitamina D3, ácido fólico), harina de lino, cloruro de colina, fosfato dicálcico, minerales (sulfato ferroso, óxido de zinc, sulfato de manganeso, sulfato de cobre, óxido manganoso, yoduro de potasio), sorbato de potasio (como conservador), ácido cítrico, DL-metionina, extracto de romero.',
            AnalisisGarantizado: 'Proteína cruda mínimo 25.0%; Grasa cruda mínimo 9.0%; Fibra cruda máximo 3.0%; Humedad máximo 11.0%; Vitamina E mínimo 140 UI/kg; Ácido graso Omega-6 mínimo 1.30%*; Ácido graso Omega-3 mínimo 0.15%*. Energía metabolizable: 3,390 kcal/kg. *No reconocidos como nutrientes esenciales por el perfil de nutrientes de alimentos para perros de la AAFCO.',
            Instrucciones: '-',
            PesoUnitarioLB: '-',
            PesoUnitarioKg: '0.341',
            UnidadesCaja: '-',
            Costo: '124.02',
            Precio: '167'
        }
    ]
};
