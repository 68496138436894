import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AutenticacionService } from '../autenticacion.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css'],
  providers: [AutenticacionService]
})

export class RegistroComponent implements OnInit {

  registerForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
    passwordConfirma: new FormControl('')
  });

  constructor(
    private authSvc: AutenticacionService,
    private router: Router,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
  }

  async onRegister(): Promise<void> {
    const {email, password, passwordConfirma} = this.registerForm.value;
    try {
      if (password !== passwordConfirma){
        throw new Error(('Verifique la confirmación de su password.'));
      }
      const user = await this.authSvc.register(email, password);
      if (user){
        this.toastr.info('¡Bienvenido! Hemos enviado un correo electrónico a ' + email + '. Sigue las instrucciones que encontraras en él, para confirmar tu cuenta de correo electrónico.');
        this.router.navigate(['/']);
      }
    }
    catch (error){
      this.toastr.error(error);
    }
  }
}
