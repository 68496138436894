import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { Presentacion, Producto } from '../catalogos/catalogo-productos/producto';
import { ProductosService } from '../catalogos/catalogo-productos/productos.service';
import { CarritoDeComprasService } from '../carrito-de-compras/carrito-de-compras.service';
import { ToastrService } from 'ngx-toastr';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Edad } from '../catalogos/catalogo-edades/edad';
import { Especie } from '../catalogos/catalogo-especies/especie';
import { Marca } from '../catalogos/catalogo-marcas/marca';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-listado-productos',
  templateUrl: './listado-productos.component.html',
  styleUrls: ['./listado-productos.component.css']
})

export class ListadoProductosComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private servicioProductos: ProductosService,
    private carritoDeCompras: CarritoDeComprasService,
    private toastr: ToastrService,
    private angularFirestore: AngularFirestore,
    private almacenamiento: AngularFireStorage,
    private deviceService: DeviceDetectorService
    ) { }

  ruta$ = this.route.url;
  ruta = '';
  productos$: any;
  coleccionProductosPaginada: AngularFirestoreCollection<Producto>;
  coleccionProductosPaginadaSiguiente: AngularFirestoreCollection<Producto>;
  coleccionProductosPaginadaAnterior: AngularFirestoreCollection<Producto>;
  segmentos: UrlSegment[];
  productoSeleccionado: Producto = new Producto();
  presentacionSeleccionada: Presentacion = new Presentacion();
  public esUnDispositivoMovil = false;
  public reset: any[] = [{}];

  /* Filtros */
  idEspecie: string;
  idEdad: string;
  idMarca: string;

  ruta1Str: string;
  ruta2Str: string;

  /* Paginador */
  tableData: any[] = [];
  firstInResponse: any = [];
  lastInResponse: any = [];
  prevStrtAt: any = [];
  paginationClickedCount = 0;
  disableNext = true;
  disablePrev = true;

  public onRecreate(): void {
    this.reset[0] = {};
  }

  ngOnInit(): void {
    // await this.servicioProductos.actualizarDoctosRelacionados();
    this.esUnDispositivoMovil = this.deviceService.isMobile();
    const myObserver = { next: (x: UrlSegment[]) => this.segmentos = x };
    this.route.url.subscribe(myObserver);
    this.segmentos.forEach(element => {
      this.ruta += '/' + element;
    });

    if (this.segmentos.length === 2){
      this.idEspecie = null;
      this.idEdad = null;
      this.idMarca = this.segmentos[1].path;
      this.ruta1Str = this.idMarca.replace(/_/g, ' ');
      this.getItems();
    }
    else {
      this.idEspecie = this.segmentos[1].path;
      this.idEdad = this.segmentos[2].path;
      this.idMarca = null;
      this.ruta1Str = this.idEspecie.replace(/_/g, ' ');
      this.ruta2Str = this.idEdad.replace(/_/g, ' ');
      this.getItems();
    }
    this.productos$ = this.servicioProductos.productos$;
  }

  alConsultarProducto(pmtProducto: Producto): void{
    this.onRecreate();
    this.productoSeleccionado = pmtProducto;
  }

  alAgregarAlCarrito(pmtProducto: Producto, pmtPresentacion: Presentacion): void{
    if (this.carritoDeCompras.cantidadProductoEnElCarrito(pmtProducto, pmtPresentacion) > 9){
      this.toastr.warning('Solo puede agregar hasta 10 unidades de ' + pmtProducto.nombre + ' (' + pmtPresentacion.descripcion + ')');
      return;
    }
    if (confirm('¿Confima que desea agregar ' + pmtProducto.nombre + ' a su carrito de compras?')){
      this.carritoDeCompras.agregarProductoLS(pmtProducto, pmtPresentacion);
      this.toastr.info(pmtProducto.nombre + ' (' + pmtPresentacion.descripcion +  ')', 'Usted agrego a su carrito:');
    }
  }

  getItems(): void {

    let documentoEspecie: AngularFirestoreDocument<Especie>;
    let documentoEdad: AngularFirestoreDocument<Edad>;
    let documentoMarca: AngularFirestoreDocument<Marca>;

    if (this.idMarca){
      this.angularFirestore.collection('Marcas', ref => ref
      .where('ruteo', '==', this.idMarca)
      .where('estado', '==', true))
      .snapshotChanges()
      .subscribe(response => {
        documentoMarca = this.angularFirestore.doc<Marca>('Marcas/' + response[0].payload.doc.id);
        this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
        .where('marca', '==', documentoMarca.ref)
        .where('estado', '==', true)
        .orderBy('especieDoc.orden')
        .orderBy('edadDoc.orden')
        .limit(20)
        );
        this.llenaTablaInicial();
      });
    }
    else if (this.idEspecie && this.idEdad) {
      this.angularFirestore.collection('Especies', ref => ref
      .where('ruteo', '==', this.idEspecie))
      .snapshotChanges()
      .subscribe(response => {
        documentoEspecie = this.angularFirestore.doc<Especie>('Especies/' + response[0].payload.doc.id);
        this.angularFirestore.collection('Edades', ref => ref
        .where('ruteo', '==', this.idEdad))
        .snapshotChanges()
        .subscribe(responseEdad => {
          documentoMarca = this.angularFirestore.doc<Marca>('Marcas/IESGi5bIy3rt4p6Tbkg4');
          documentoEdad = this.angularFirestore.doc<Edad>('Edades/' + responseEdad[0].payload.doc.id);
          this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
          .where('especie', '==', documentoEspecie.ref )
          .where('edad', '==', documentoEdad.ref)
          .where('estado', '==', true)
          .where('marca', '!=', documentoMarca.ref)
          .limit(20)
          );
          this.llenaTablaInicial();
        });
      });
    } else {
      this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
      .orderBy('nombre')
      .limit(20)
      );
    }
  }

  llenaTablaInicial(): void{
    this.coleccionProductosPaginada
    .snapshotChanges()
    .subscribe((response: string | any[]) => {

        if (!response.length) {
          console.log('No Data Available (inicial)');
          return false;
        }

        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (const item of response) {
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          const urlImagen = this.almacenamiento.ref('Imagenes/Productos/' + id + '/Principal.png');
          this.almacenamiento.ref('Imagenes/Productos/' + id).listAll().subscribe({
            next: x => {
              if (x.items.length === 0){
                objProducto.urlImagen = 'https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FProductos%2FDefault%2FproductoDefault.png?alt=media&token=557e8705-315c-4df2-beef-4d974136990d';
              }
              else {
                urlImagen.getDownloadURL().subscribe(
                  {
                    next: urlImagenDescarga => objProducto.urlImagen = urlImagenDescarga
                  });
              }
            }
          });
          this.tableData.push(objProducto);
        }

        this.prevStrtAt = [];
        this.paginationClickedCount = 0;
        this.disablePrev = true;

        if (response.length === 20){
          this.disableNext = false;
        }

        this.prevStrtAt.push(this.firstInResponse);
      }, (error: any) => {
        console.log(error);
      });
  }

  get_prev_startAt(): void {
    if (this.prevStrtAt.length > (this.paginationClickedCount + 1)) {
      this.prevStrtAt.splice(this.prevStrtAt.length - 2, this.prevStrtAt.length - 1);
      if (!this.prevStrtAt){
        this.prevStrtAt = [];
      }
    }
    return this.prevStrtAt[this.paginationClickedCount - 1];
  }

  nextPage(): void {

    let documentoEspecie: AngularFirestoreDocument<Especie>;
    let documentoEdad: AngularFirestoreDocument<Edad>;
    let documentoMarca: AngularFirestoreDocument<Marca>;

    if (this.idMarca){

      this.angularFirestore.collection('Marcas', ref => ref
      .where('ruteo', '==', this.idMarca)
      .where('estado', '==', true))
      .snapshotChanges()
      .subscribe(response => {
        documentoMarca = this.angularFirestore.doc<Marca>('Marcas/' + response[0].payload.doc.id);
        this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
        .where('marca', '==', documentoMarca.ref)
        .where('estado', '==', true)
        .orderBy('especieDoc.orden')
        .orderBy('edadDoc.orden')
        .limit(20)
        .startAfter(this.lastInResponse)
        );
        this.llenaTablaSiguiente();
      });
    } else if (this.idEspecie && this.idEdad) {
      this.angularFirestore.collection('Especies', ref => ref
      .where('ruteo', '==', this.idEspecie))
      .snapshotChanges()
      .subscribe(response => {
        documentoEspecie = this.angularFirestore.doc<Especie>('Especies/' + response[0].payload.doc.id);
        this.angularFirestore.collection('Edades', ref => ref
        .where('ruteo', '==', this.idEdad))
        .snapshotChanges()
        .subscribe(responseEdad => {
          documentoMarca = this.angularFirestore.doc<Marca>('Marcas/IESGi5bIy3rt4p6Tbkg4');
          documentoEdad = this.angularFirestore.doc<Edad>('Edades/' + responseEdad[0].payload.doc.id);
          this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
          .where('especie', '==', documentoEspecie.ref )
          .where('edad', '==', documentoEdad.ref)
          .where('marca', '!=', documentoMarca.ref)
          .where('estado', '==', true)
          .limit(20)
          .startAfter(this.lastInResponse)
          );
          this.llenaTablaSiguiente();
        });
      });
    } else {
      this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
      .orderBy('nombre')
      .limit(20)
      .startAfter(this.lastInResponse)
      );
    }
  }

  llenaTablaSiguiente(): void{
    this.disableNext = true;
    this.coleccionProductosPaginadaSiguiente
      .snapshotChanges()
      .subscribe(response => {

        if (!response.length) {
          console.log('No Data Available (Siguiente) ');
          this.disableNext = true;
          return false;
        }

        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (const item of response) {
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          const urlImagen = this.almacenamiento.ref('Imagenes/Productos/' + id + '/Principal.png');
          this.almacenamiento.ref('Imagenes/Productos/' + id).listAll().subscribe({
            next: x => {
              if (x.items.length === 0){
                objProducto.urlImagen = 'https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FProductos%2FDefault%2FproductoDefault.png?alt=media&token=557e8705-315c-4df2-beef-4d974136990d';
              }
              else {
                urlImagen.getDownloadURL().subscribe(
                  {
                    next: urlImagenDescarga => objProducto.urlImagen = urlImagenDescarga
                  });
              }
            }
          });
          this.tableData.push(objProducto);
        }

        this.paginationClickedCount++;
        this.prevStrtAt.push(this.firstInResponse);
        if (response.length < 20) {
            this.disableNext = true;
        } else {
            this.disableNext = false;
        }
        this.disablePrev = false;
      }, error => {
        this.disableNext = false;
      });
  }

  prevPage(): void {

    let documentoEspecie: AngularFirestoreDocument<Especie>;
    let documentoEdad: AngularFirestoreDocument<Edad>;
    let documentoMarca: AngularFirestoreDocument<Marca>;

    if (this.idMarca){
      this.angularFirestore.collection('Marcas', ref => ref
      .where('ruteo', '==', this.idMarca)
      .where('estado', '==', true))
      .snapshotChanges()
      .subscribe(response => {
        documentoMarca = this.angularFirestore.doc<Marca>('Marcas/' + response[0].payload.doc.id);
        this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
        .where('marca', '==', documentoMarca.ref)
        .where('estado', '==', true)
        .orderBy('especieDoc.orden')
        .orderBy('edadDoc.orden')
        .limit(20)
        .startAt(this.get_prev_startAt())
        .endBefore(this.firstInResponse)
        );
        this.llenaTablaAnterior();
      });
    } else if (this.idEspecie && this.idEdad) {
      this.angularFirestore.collection('Especies', ref => ref
      .where('ruteo', '==', this.idEspecie))
      .snapshotChanges()
      .subscribe(response => {
        documentoEspecie = this.angularFirestore.doc<Especie>('Especies/' + response[0].payload.doc.id);
        this.angularFirestore.collection('Edades', ref => ref
        .where('ruteo', '==', this.idEdad))
        .snapshotChanges()
        .subscribe(responseEdad => {
          documentoMarca = this.angularFirestore.doc<Marca>('Marcas/IESGi5bIy3rt4p6Tbkg4');
          documentoEdad = this.angularFirestore.doc<Edad>('Edades/' + responseEdad[0].payload.doc.id);
          this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
          .where('especie', '==', documentoEspecie.ref )
          .where('edad', '==', documentoEdad.ref)
          .where('marca', '!=', documentoMarca.ref)
          .where('estado', '==', true)
          .limit(20)
          .startAt(this.get_prev_startAt())
          .endBefore(this.firstInResponse)
          );
          this.llenaTablaAnterior();
        });
      });
    } else {
      this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
      .orderBy('nombre')
      .limit(20)
      .startAt(this.get_prev_startAt())
      .endBefore(this.firstInResponse)
      );
    }
  }

  llenaTablaAnterior(): void{
    this.disablePrev = true;
    this.coleccionProductosPaginadaAnterior
      .snapshotChanges()
      .subscribe(response => {
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (const item of response) {
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          const urlImagen = this.almacenamiento.ref('Imagenes/Productos/' + id + '/Principal.png');
          this.almacenamiento.ref('Imagenes/Productos/' + id).listAll().subscribe({
            next: x => {
              if (x.items.length === 0){
                objProducto.urlImagen = 'https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FProductos%2FDefault%2FproductoDefault.png?alt=media&token=557e8705-315c-4df2-beef-4d974136990d';
              }
              else {
                urlImagen.getDownloadURL().subscribe(
                  {
                    next: urlImagenDescarga => objProducto.urlImagen = urlImagenDescarga
                  });
              }
            }
          });
          this.tableData.push(objProducto);
        }

        this.paginationClickedCount--;
        this.prevStrtAt.pop();
        if (this.paginationClickedCount === 0) {
            this.disablePrev = true;
        } else {
            this.disablePrev = false;
        }
        this.disableNext = false;
      }, error => {
        this.disablePrev = false;
      });
  }
}
