import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { first, map } from 'rxjs/operators';
import { Edad } from '../catalogo-edades/edad';
import { Especie } from '../catalogo-especies/especie';
import { TipoProducto } from '../catalogo-tipos-producto/tipo-producto';
import { Producto } from './producto';

@Injectable({
  providedIn: 'root'
})

export class ProductosService {

  constructor(private firestore: AngularFirestore) { }

  public coleccionProductos = this.firestore.collection('Productos', ref => ref.orderBy('nombre'));
  public productos$;
  public productos: {
    id: string,
    data: Producto
  }[];

  async agregar(producto: Producto): Promise<void> {
    try {
      await this.coleccionProductos.add(
        {
          marca: this.firestore.collection('Marcas').doc('' + producto.marca).ref,
          marcaDoc: producto.marcaDoc,
          nombre: producto.nombre,
          descripcion: producto.descripcion,
          especie: this.firestore.collection('Especies').doc('' + producto.especie).ref,
          especieDoc: producto.especieDoc,
          edad: this.firestore.collection('Edades').doc('' + producto.edad).ref,
          edadDoc: producto.edadDoc,
          tipoProducto: this.firestore.collection('TiposProducto').doc('' + producto.tipoProducto).ref,
          tipoProductoDoc: producto.tipoProductoDoc,
          beneficios: producto.beneficios,
          ingredientes: producto.ingredientes,
          analisisGarantizado: producto.analisisGarantizado,
          instrucciones: producto.instrucciones,
          estado: producto.estado
        });
    } catch (err) {
      console.log(err);
    }
  }

  async agregar2(producto: Producto): Promise<void> {

    let EspecieDoc: Especie;
    let EdadDoc: Edad;
    let TipoProductoDoc: TipoProducto;

    await this.firestore.collection('Especies').doc(producto.especie.id)
    .get()
    .toPromise()
    .then(async x =>
      {
        EspecieDoc = x.data() as Especie;
        await this.firestore.collection('Edades').doc(producto.edad.id)
        .get()
        .toPromise()
        .then(async y =>
          {
            EdadDoc = y.data() as Edad;
            await this.firestore.collection('TiposProducto').doc(producto.tipoProducto.id)
            .get()
            .toPromise()
            .then(async z =>
              {
                TipoProductoDoc = z.data() as TipoProducto;
                console.log(EspecieDoc);
                console.log(EdadDoc);
                console.log(TipoProductoDoc);
                producto.especieDoc = EspecieDoc;
                producto.edadDoc = EdadDoc;
                producto.tipoProductoDoc = TipoProductoDoc;
                console.log(producto);

                try {
                  await this.coleccionProductos.add(
                    {
                    ...producto,
                    });
                } catch (err) {
                  console.log(err);
                }

              })
            .catch(e => console.log(e));
          })
        .catch(e => console.log(e));
      })
    .catch(e => console.log('MiError: ' + e));
  }

  async editar(producto: Producto): Promise<void> {
    try {
      await this.coleccionProductos
        .doc(producto.id)
        .set(
          {
            nombre: producto.nombre,
            descripcion: producto.descripcion,
            marca: this.firestore.collection('Marcas').doc('' + producto.marca).ref,
            marcaDoc: producto.marcaDoc,
            especie: this.firestore.collection('Especies').doc('' + producto.especie).ref,
            especieDoc: producto.especieDoc,
            edad: this.firestore.collection('Edades').doc('' + producto.edad).ref,
            edadDoc: producto.edadDoc,
            tipoProducto: this.firestore.collection('TiposProducto').doc('' + producto.tipoProducto).ref,
            tipoProductoDoc: producto.tipoProductoDoc,
            beneficios: producto.beneficios,
            ingredientes: producto.ingredientes,
            analisisGarantizado: producto.analisisGarantizado,
            instrucciones: producto.instrucciones,
            estado: producto.estado
          },
          {
            merge: true
          });
    } catch (err) {
      console.log(err);
    }
  }

  async agregarPresentacion(pmtIdProducto, pmtPresentaciones): Promise<void> {
    try {
      await this.coleccionProductos
        .doc(pmtIdProducto)
        .set(
          {
            presentaciones: pmtPresentaciones
          },
          {
            merge: true
          });
    } catch (err) {
      console.log(err);
    }
  }

  async actualizarDoctosRelacionados(): Promise<void>{

    let especies: Especie[] = new Array();
    let edades: Edad[] = new Array();
    let productos: Producto[] = new Array();

    await (new Promise(resolve =>
      this.firestore.collection('Especies')
      .snapshotChanges()
      .pipe(first())
      .pipe(
        map(actions => {
        return actions.map(p => {
          const especie = p.payload.doc;
          const id = especie.id;
          return { id, ...(especie.data() as {}) } as Especie;
        });
      }))
      .subscribe(x => resolve(x))))
      .then(x => especies = x as Especie[]);

    await (new Promise(resolve =>
      this.firestore.collection('Edades')
      .snapshotChanges()
      .pipe(first())
      .pipe(
        map(actions => {
          return actions.map(p => {
            const edad = p.payload.doc;
            const id = edad.id;
            return { id, ...(edad.data() as {}) } as Edad;
          });
        }))
      .subscribe(x => resolve(x))))
      .then(x => edades = x as Edad[]);

    await (new Promise(resolve =>
      this.firestore.collection('Productos')
      .snapshotChanges()
      .pipe(first())
      .pipe(
        map(actions => {
          return actions.map(p => {
            const producto = p.payload.doc;
            const id = producto.id;
            return { id, ...(producto.data() as {}) } as Producto;
          });
        }))
      .subscribe(x => resolve(x))))
      .then(x => productos = x as Producto[]);

    console.log(especies);
    console.log(edades);
    console.log(productos);

    productos.forEach(async x => {
      const especieProducto = especies.find(y => y.id === x.especie.id);
      const edadProducto = edades.find(y => y.id === x.edad.id);
      x.especieDoc = especieProducto;
      x.edadDoc = edadProducto;
      console.log(x);
      try {
        await this.firestore.collection('Productos')
        .doc(x.id)
        .set(
          {
            especieDoc: x.especieDoc,
            edadDoc: x.edadDoc
          },
          {
            merge: true
          }
          );
      } catch (err) {
        console.log(err);
      }
    });
  }

  async obtenerListado(): Promise<{id: string, data: Producto}[]>{
    this.productos = new Array();
    (await this.firestore.collection('Productos').get().toPromise()).forEach(element => {
      this.productos.push({
        id: element.id,
        data: element.data() as Producto
      });
    });
    return this.productos;
  }
}
