import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Edad } from '../edad';
import { EdadesService } from '../edades.service';

declare var $: any;

@Component({
  selector: 'app-crear-editar-edad',
  templateUrl: './crear-editar-edad.component.html',
  styleUrls: ['./crear-editar-edad.component.css']
})

export class CrearEditarEdadComponent implements OnInit, OnChanges {

  @Input()
  edadSeleccionada: Edad = new Edad();

  DatosEtapaDeVidaForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', Validators.required),
    ruteo: new FormControl('', Validators.required),
    estado: new FormControl('')
  });

  constructor(private servicioEdades: EdadesService) { }

  ngOnChanges(): void {
    if (this.edadSeleccionada.id){
      this.DatosEtapaDeVidaForm.patchValue({
        id: this.edadSeleccionada.id,
        nombre: this.edadSeleccionada.nombre,
        ruteo: this.edadSeleccionada.ruteo,
        estado: this.edadSeleccionada.estado
      });
    } else {
      this.DatosEtapaDeVidaForm.reset();
    }
  }

  ngOnInit(): void {

  }

  alEnviar(): void {
    const edad = this.DatosEtapaDeVidaForm.value;
    if (!this.edadSeleccionada.id){
      this.servicioEdades.agregar(edad);
    }
    else {
      this.servicioEdades.editar({...edad, id: this.edadSeleccionada.id });
    }
    this.DatosEtapaDeVidaForm.reset()
    this.edadSeleccionada = new Edad();
    $('#edadesModal').modal('hide');
  }
}
