import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { auth } from 'firebase';

@Injectable({
  providedIn: 'root'
})

export class AutenticacionService {

  constructor(public afAuth: AngularFireAuth) { }

  async login(email: string, password: string): Promise<auth.UserCredential>{
    try {
      const result = await this.afAuth.signInWithEmailAndPassword(email, password);
      return result;
    }
    catch (error){
      throw error;
    }
  }

  async register(email: string, password: string): Promise<auth.UserCredential>{
    try {
      const result = await this.afAuth.createUserWithEmailAndPassword(email, password);
      (await this.afAuth.currentUser).sendEmailVerification();
      return result;
    }
    catch (error){
      throw error;
    }
  }

  async logout(): Promise<void>{
    try {
      await this.afAuth.signOut();
    }
    catch (error){
      throw error;
    }
  }

  async enviaCorreoVerificacion(): Promise<void> {
    return (await this.afAuth.currentUser).sendEmailVerification();
  }

  async enviaCorreoCambioContrasenia(pmtEmail: string): Promise<void> {
    await this.afAuth.sendPasswordResetEmail(pmtEmail);
  }
}
