import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { AutenticacionService } from '../autenticacion.service';
import { UsuariosService } from '../usuarios.service';
import { ToastrService } from 'ngx-toastr';
import { Mascota } from '../usuario';
import { EspeciesService } from '../../catalogos/catalogo-especies/especies.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-mi-perfil',
  templateUrl: './mi-perfil.component.html',
  styleUrls: ['./mi-perfil.component.css']
})

export class MiPerfilComponent implements OnInit {

  public usuario$: Observable<any> = this.servicioAutenticacion.afAuth.user;
  public listaMascotas: Mascota[];
  public mascotaSeleccionada = -1;
  public especies$ = this.servicioEspecies.especiesActivas$;

  DatosUsuarioForm = new FormGroup({
    id: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    apellidoPaterno: new FormControl('', [Validators.required, Validators.minLength(3)]),
    apellidoMaterno: new FormControl('', [Validators.required, Validators.minLength(3)]),
    sexo: new FormControl('', Validators.required),
    edad: new FormControl('', [Validators.required, Validators.min(18)]),
    telefonoFijo: new FormControl('', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]),
    telefonoMovil: new FormControl('', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]),
    direccion: new FormGroup({
      calle: new FormControl('', [Validators.required, Validators.minLength(3)]),
      numeroExterior: new FormControl('', Validators.required),
      numeroInterior: new FormControl(''),
      codigoPostal: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern('^[0-9]*$')]),
      colonia: new FormControl('', [Validators.required, Validators.minLength(3)]),
      municipio: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      referencias: new FormControl('')
    })
  });

  DatosMascotaForm = new FormGroup({
    nombre: new FormControl('', [Validators.required]),
    especie: new FormControl('', [Validators.required]),
    raza: new FormControl('', [Validators.required]),
    edad: new FormControl('', [Validators.required]),
    padecimientos: new FormControl('')
  });

  constructor(
    private servicioAutenticacion: AutenticacionService,
    private servicioUsuarios: UsuariosService,
    private toastr: ToastrService,
    private servicioEspecies: EspeciesService,
    private firestore: AngularFirestore
    ) { }

  ngOnInit(): void {
    this.usuario$.subscribe(x => {
      this.servicioUsuarios.obtenerPorEmail(x.email).subscribe(y => {
        if (y.length > 0){
          this.DatosUsuarioForm.patchValue({
            id: y[0].id,
            email: y[0].email,
            nombre: y[0].nombre,
            apellidoPaterno: y[0].apellidoPaterno,
            apellidoMaterno: y[0].apellidoMaterno,
            sexo: y[0].sexo,
            telefonoFijo: y[0].telefonoFijo,
            telefonoMovil: y[0].telefonoMovil,
            edad: y[0].edad,
            direccion: y[0].direccion
          });
          this.listaMascotas = y[0].mascotas;
        } else {
          this.DatosUsuarioForm.patchValue({
            email: x.email,
          });
        }
      });
    });
  }

  alEnviar(): void{
    const datosUsuario = this.DatosUsuarioForm.value;
    if (datosUsuario.id !== '') {
      try{
        this.servicioUsuarios.editar(datosUsuario);
        this.toastr.success('El usuario ha sido actualizado exitosamente');
      } catch (e) {
        this.toastr.error(e);
      }

    } else {
      try {
        this.servicioUsuarios.agregar(datosUsuario);
        this.toastr.success('El usuario ha sido creado exitosamente');
      }
      catch (e){
        this.toastr.error(e);
      }
    }
  }

  alEnviarMascota(): void{
    const datosUsuario = this.DatosUsuarioForm.value;
    const datosMascota = this.DatosMascotaForm.value;

    if (this.mascotaSeleccionada === -1) {
      try {

        if (!this.listaMascotas){
          this.listaMascotas = new Array();
        }

        this.listaMascotas.push({
          nombre: datosMascota.nombre,
          especie: this.firestore.doc('Especies/' + datosMascota.especie).ref,
          raza: datosMascota.raza,
          edad: datosMascota.edad,
          padecimientos: datosMascota.padecimientos
        });

        this.servicioUsuarios.agregarEditarMascota(datosUsuario.id, this.listaMascotas);
        this.toastr.success('Los datos de ' + datosMascota.nombre + ' se han registrado exitosamente');
      } catch (e) {
        this.toastr.error(e);
      }
    } else {
      try {

        this.listaMascotas[this.mascotaSeleccionada] = {
          nombre: datosMascota.nombre,
          especie: this.firestore.doc('Especies/' + datosMascota.especie).ref,
          raza: datosMascota.raza,
          edad: datosMascota.edad,
          padecimientos: datosMascota.padecimientos
        };

        this.servicioUsuarios.agregarEditarMascota(datosUsuario.id, this.listaMascotas);
        this.toastr.success('Los datos de ' + datosMascota.nombre + ' se han actualizado exitosamente');
      } catch (e) {
        this.toastr.error(e);
      }
      this.mascotaSeleccionada = -1;
    }
    this.DatosMascotaForm.patchValue({
      nombre: '',
      especie: '',
      raza: '',
      edad: '',
      padecimientos: ''
    });
  }

  alEditarMascota(i: number): void {
    this.mascotaSeleccionada = i;
    this.DatosMascotaForm.patchValue({
      nombre: this.listaMascotas[i].nombre,
      especie: this.listaMascotas[i].especie.id,
      raza: this.listaMascotas[i].raza,
      edad: this.listaMascotas[i].edad,
      padecimientos: this.listaMascotas[i].padecimientos
    });
  }
}
