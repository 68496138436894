export class CarritoDeCompras {
    productos: {
        idProducto: string;
        nombreProducto: string;
        urlImagen: string;
        sku: string;
        descripcionPresentacion: string;
        cantidad: number;
        precio: number;
    }[];
    cantidadTotal: number;
    importeTotal: number;
}
