import { Component, OnInit } from '@angular/core';
import { EspeciesService } from './especies.service';
import { Especie } from './especie';

@Component({
  selector: 'app-catalogo-especies',
  templateUrl: './catalogo-especies.component.html',
  styleUrls: ['./catalogo-especies.component.css']
})

export class CatalogoEspeciesComponent implements OnInit {

  constructor(private servicioEspecie: EspeciesService) { }

  public especies$ = this.servicioEspecie.especies$;
  public especieSeleccionada: Especie = new Especie();

  ngOnInit(): void {
  }

  alEditar(especie: Especie): void {
    this.especieSeleccionada = especie;
  }

  alCrear(): void {
    this.especieSeleccionada = new Especie();
  }

  alEditarEtapasDeVida(especie: Especie): void {
    this.especieSeleccionada = especie;
  }
}
