<div *ngIf="versionMovil" class="card">
    <div class="card">
        <div *ngIf="listaImagenes as imagenes" id="carouselExampleControls" class="carousel slide w-100" data-ride="carousel">
            <div class="carousel-inner">
                <div *ngFor="let imagen of imagenes" class="carousel-item">
                    <img [src]="imagen.url | async" class="d-block img-fluid" style="width: 350px; max-width: 100%; height: auto;">
                </div>
                <div class="carousel-item active">
                    <img [src]="productoSeleccionado.urlImagen" class="d-block img-fluid" style="width: 350px; max-width: 100%; height: auto;">
                </div>
            </div>
            <a style="background-color: rgba(34, 142, 157, 0.3);" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
            </a>
            <a style="background-color: rgba(34, 142, 157, 0.3);" class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </div>
        <div class="card-body">
            <h5 class="card-title">{{productoSeleccionado.nombre}}</h5>
            <h6>Descripcion</h6>
            <p>{{productoSeleccionado.descripcion}}</p>
            <h6>Beneficios</h6>
            <p>{{productoSeleccionado.beneficios}}</p>
            <h6>Ingredientes</h6>
            <p>{{productoSeleccionado.ingredientes}}</p>
            <h6>Análisis garantizado</h6>
            <p>{{productoSeleccionado.analisisGarantizado}}</p>
            <h6>Instrucciones</h6>
            <p>{{productoSeleccionado.instrucciones}}</p>
        </div>
        <ul class="list-group list-group-flush">
            <li *ngFor="let presentacion of productoSeleccionado.presentaciones" class="list-group-item">
                <button (click)='alAgregar(productoSeleccionado, presentacion)' type="button" class="btn btn-block btn-secondary">
                    {{ presentacion.descripcion }} {{ presentacion.precio | currency:'MXN' }}
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cart-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        <path fill-rule="evenodd" d="M8.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </button>        
            </li>
        </ul>
      </div>
</div>

<div class="modal fade" id="detalleProductoModal" tabindex="-1" aria-labelledby="detalleProductoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header" style="background-color: beige;">
                <h5 class="modal-title" id="detalleProductoModalLabel">{{productoSeleccionado.nombre}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row row-cols-1 row-cols-lg-2">
                    <div class="col col-lg-3">
                        <div *ngIf="listaImagenes as imagenes" id="carouselExampleControls" class="carousel slide w-100" data-ride="carousel">
                            <div class="carousel-inner">
                                <div *ngFor="let imagen of imagenes" class="carousel-item">
                                    <img [src]="imagen.url | async" class="d-block img-fluid" style="width: 350px; max-width: 100%; height: auto;">
                                </div>
                                <div class="carousel-item active">
                                    <img [src]="productoSeleccionado.urlImagen" class="d-block img-fluid" style="width: 350px; max-width: 100%; height: auto;">
                                </div>
                            </div>
                            <a style="background-color: rgba(34, 142, 157, 0.3);" class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a style="background-color: rgba(34, 142, 157, 0.3);" class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                    <div class="col col-lg-9">
                        <div class="row row-cols-1">
                            <div class="col-5 col-lg-3">
                               <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true">Descripción</a>
                                    <a class="nav-link" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-profile" role="tab" aria-controls="v-pills-profile" aria-selected="false">Beneficios</a>
                                    <a class="nav-link" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-messages" role="tab" aria-controls="v-pills-messages" aria-selected="false">Ingredientes</a>
                                    <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-settings" role="tab" aria-controls="v-pills-settings" aria-selected="false">Análisis garantizado</a>
                                    <a class="nav-link" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-instrucciones" role="tab" aria-controls="v-pills-instrucciones" aria-selected="false">Instrucciones</a>
                                </div>
                            </div>
                            <div class="col-7 col-lg-9">
                                <div class="tab-content" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">{{productoSeleccionado.descripcion}}</div>
                                    <div style="white-space: pre-wrap" class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">{{productoSeleccionado.beneficios}}</div>
                                    <div class="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">{{productoSeleccionado.ingredientes}}</div>
                                    <div style="white-space: pre-wrap" class="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab">{{productoSeleccionado.analisisGarantizado}}</div>
                                    <div class="tab-pane fade" id="v-pills-instrucciones" role="tabpanel" aria-labelledby="v-pills-instrucciones-tab">{{productoSeleccionado.instrucciones}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" style="background-color: beige;">
                <button *ngFor="let presentacion of productoSeleccionado.presentaciones" (click)='alAgregar(productoSeleccionado, presentacion)' type="button" class="btn btn-secondary">
                    {{ presentacion.descripcion }} {{ presentacion.precio | currency:'MXN' }}
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-cart-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm7 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2z"/>
                        <path fill-rule="evenodd" d="M8.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 .5-.5z"/>
                    </svg>
                </button>
                <button type="button" id='BotonCerrar' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>    
</div>