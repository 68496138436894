import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AutenticacionService } from '../../autenticacion.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-recupera-contrasenia',
  templateUrl: './recupera-contrasenia.component.html',
  styleUrls: ['./recupera-contrasenia.component.css'],
  providers: [AutenticacionService]
})

export class RecuperaContraseniaComponent implements OnInit {

  contraseniaForm = new FormGroup({
    email: new FormControl('', [Validators.email, Validators.required]),
  });

  constructor(
    private authSvc: AutenticacionService,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
  }

  alEnviar(): void {
    this.authSvc.enviaCorreoCambioContrasenia(this.contraseniaForm.value.email);
    this.toastr.info('Hemos enviado un correo electrónico a ' + this.contraseniaForm.value.email + ' con las instrucciones para actualizar tu contraseña.');
  }

}
