import { DocumentReference } from '@angular/fire/firestore';

export class Especie {
    id: string;
    nombre: string;
    ruteo: string;
    urlImagen: string;
    etapasDeVida: {
        etapaDeVida: DocumentReference,
        nombre: string
    }[];
    estado: boolean;
}