import { Component, OnInit } from '@angular/core';
import { Producto } from './producto';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, CollectionReference } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Especie } from '../catalogo-especies/especie';
import { Edad } from '../catalogo-edades/edad';
import { Marca } from '../catalogo-marcas/marca';
import { FormControl, FormGroup } from '@angular/forms';
import { MarcasService } from '../catalogo-marcas/marcas.service';
import { EspeciesService } from '../catalogo-especies/especies.service';
import { EdadesService } from '../catalogo-edades/edades.service';
import { TiposProductoService } from '../catalogo-tipos-producto/tipos-producto.service';
import { TipoProducto } from '../catalogo-tipos-producto/tipo-producto';
import { ProductosService } from './productos.service';

@Component({
  selector: 'app-catalogo-productos',
  templateUrl: './catalogo-productos.component.html',
  styleUrls: ['./catalogo-productos.component.css']
})

export class CatalogoProductosComponent implements OnInit {

  constructor(
    private angularFirestore: AngularFirestore,
    private almacenamiento: AngularFireStorage,
    private servicioMarcas: MarcasService,
    private servicioEspecies: EspeciesService,
    private servicioEdades: EdadesService,
    private servicioTiposDeProducto: TiposProductoService,
    private servicioProducto: ProductosService
    ) { }

  DatosFiltrosForm = new FormGroup({
    filtroMarca: new FormControl(''),
    filtroEspecie: new FormControl(''),
    filtroEtapaDeVida: new FormControl(''),
    filtroTipoProducto: new FormControl('')
  });


  public marcas$ = this.servicioMarcas.marcas$;
  public especies$ = this.servicioEspecies.especies$;
  public etapasDeVida$ = this.servicioEdades.edades$;
  public tiposDeProducto$ = this.servicioTiposDeProducto.tiposProducto$;
  public productoSeleccionado: Producto = new Producto();
  public productos: {
    id: string,
    data: Producto
  }[];

  /* Filtros */
  idEspecie: string;
  idEdad: string;
  idMarca: string;

  documentoMarca: AngularFirestoreDocument<Marca>;
  documentoEspecie: AngularFirestoreDocument<Especie>;
  documentoEtapaVida: AngularFirestoreDocument<Edad>;
  documentoTipoDeProducto: AngularFirestoreDocument<TipoProducto>;


  /* Paginador */
  tableData: any[] = [];
  firstInResponse: any = [];
  lastInResponse: any = [];
  prevStrtAt: any = [];
  paginationClickedCount = 0;
  disableNext = true;
  disablePrev = true;
  coleccionProductosPaginada: AngularFirestoreCollection<Producto>;
  coleccionProductosPaginadaSiguiente: AngularFirestoreCollection<Producto>;
  coleccionProductosPaginadaAnterior: AngularFirestoreCollection<Producto>;


  async ngOnInit(): Promise<void> {
    this.getItems();
    // await this.servicioProducto.obtenerListado().then(x => this.productos = x);
  }

  getItems(): void {
    if (this.documentoMarca || this.documentoEspecie || this.documentoEtapaVida || this.documentoTipoDeProducto){

      if (this.documentoMarca){
        this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .where ('marca', '==', this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'marca', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'marca', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'marca', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoMarca.ref)
        );
      }

      if (this.documentoEspecie){
        this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'especie', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoEspecie.ref)
        .where ('especie', '==', this.documentoEspecie.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'especie', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoEspecie.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'especie', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoEspecie.ref)
        );
      }

      if (this.documentoEtapaVida){
        this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'edad', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoEtapaVida.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'edad', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoEtapaVida.ref)
        .where ('edad', '==', this.documentoEtapaVida.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'edad', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoEtapaVida.ref)
        );
      }

      if (this.documentoTipoDeProducto){
        this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'tipoProducto', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoTipoDeProducto.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'tipoProducto', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoTipoDeProducto.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'tipoProducto', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoTipoDeProducto.ref)
        .where ('tipoProducto', '==', this.documentoTipoDeProducto.ref)
        );
      }
    } else {
      this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
      .limit(20)
      .orderBy('nombre')
      );
    }
    this.llenaTablaInicial();
  }

  llenaTablaInicial(): void{
    this.coleccionProductosPaginada
    .snapshotChanges()
    .subscribe((response: string | any[]) => {

        if (!response.length) {
          console.log('No Data Available (inicial)');
          this.tableData = [];
          return false;
        }

        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (const item of response) {
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          const urlImagen = this.almacenamiento.ref('Imagenes/Productos/' + id + '/Principal.png');
          this.almacenamiento.ref('Imagenes/Productos/' + id).listAll().subscribe({
            next: x => {
              if (x.items.length === 0){
                objProducto.urlImagen = 'https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FProductos%2FDefault%2FproductoDefault.png?alt=media&token=557e8705-315c-4df2-beef-4d974136990d';
              }
              else {
                urlImagen.getDownloadURL().subscribe(
                  {
                    next: urlImagenDescarga => objProducto.urlImagen = urlImagenDescarga
                  });
              }
            }
          });
          this.tableData.push(objProducto);
        }

        this.prevStrtAt = [];
        this.paginationClickedCount = 0;
        this.disablePrev = true;

        if (response.length === 20){
          this.disableNext = false;
        }

        this.push_prev_startAt(this.firstInResponse);
      }, (error: any) => {
        console.log(error);
      });
  }

  push_prev_startAt(prevFirstDoc): void {
    this.prevStrtAt.push(prevFirstDoc);
  }

  pop_prev_startAt(prevFirstDoc): void {
    this.prevStrtAt.forEach(element => {
      if (prevFirstDoc.data().id === element.data().id) {
        element = null;
      }
    });
  }

  get_prev_startAt(): void {
    if (this.prevStrtAt.length > (this.paginationClickedCount + 1)) {
      this.prevStrtAt.splice(this.prevStrtAt.length - 2, this.prevStrtAt.length - 1);
      if (!this.prevStrtAt){
        this.prevStrtAt = [];
      }
    }
    return this.prevStrtAt[this.paginationClickedCount - 1];
  }

  nextPage(): void {
    if (this.documentoMarca || this.documentoEspecie || this.documentoEtapaVida || this.documentoTipoDeProducto){

      if (this.documentoMarca){
        this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAfter(this.lastInResponse)
        .where ('marca', '==', this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'marca', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'marca', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'marca', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoMarca.ref)
        );
      }

      if (this.documentoEspecie){
        this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAfter(this.lastInResponse)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'especie', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoEspecie.ref)
        .where ('especie', '==', this.documentoEspecie.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'especie', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoEspecie.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'especie', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoEspecie.ref)
        );
      }

      if (this.documentoEtapaVida){
        this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAfter(this.lastInResponse)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'edad', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoEtapaVida.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'edad', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoEtapaVida.ref)
        .where ('edad', '==', this.documentoEtapaVida.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'edad', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoEtapaVida.ref)
        );
      }

      if (this.documentoTipoDeProducto){
        this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAfter(this.lastInResponse)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'tipoProducto', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoTipoDeProducto.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'tipoProducto', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoTipoDeProducto.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'tipoProducto', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoTipoDeProducto.ref)
        .where ('tipoProducto', '==', this.documentoTipoDeProducto.ref)
        );
      }
    } else {
      this.coleccionProductosPaginadaSiguiente = this.angularFirestore.collection('Productos', ref => ref
      .limit(20)
      .orderBy('nombre')
      .startAfter(this.lastInResponse)
      );
    }
    this.llenaTablaSiguiente();
  }

  llenaTablaSiguiente(): void{
    this.disableNext = true;
    this.coleccionProductosPaginadaSiguiente
      .snapshotChanges()
      .subscribe(response => {

        if (!response.length) {
          console.log('No Data Available (Siguiente) ');
          this.disableNext = true;
          return false;
        }

        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (const item of response) {
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          const urlImagen = this.almacenamiento.ref('Imagenes/Productos/' + id + '/Principal.png');
          this.almacenamiento.ref('Imagenes/Productos/' + id).listAll().subscribe({
            next: x => {
              if (x.items.length === 0){
                objProducto.urlImagen = 'https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FProductos%2FDefault%2FproductoDefault.png?alt=media&token=557e8705-315c-4df2-beef-4d974136990d';
              }
              else {
                urlImagen.getDownloadURL().subscribe(
                  {
                    next: urlImagenDescarga => objProducto.urlImagen = urlImagenDescarga
                  });
              }
            }
          });
          this.tableData.push(objProducto);
        }

        this.paginationClickedCount++;
        this.push_prev_startAt(this.firstInResponse);
        if (response.length < 20) {
            this.disableNext = true;
        } else {
            this.disableNext = false;
        }
        this.disablePrev = false;
      }, error => {
        this.disableNext = false;
      });
  }

  prevPage(): void {
    if (this.documentoMarca || this.documentoEspecie || this.documentoEtapaVida || this.documentoTipoDeProducto){

      if (this.documentoMarca){
        this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAt(this.get_prev_startAt())
        .endBefore(this.firstInResponse)
        .where ('marca', '==', this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'marca', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'marca', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoMarca.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'marca', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoMarca.ref)
        );
      }

      if (this.documentoEspecie){
        this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAt(this.get_prev_startAt())
        .endBefore(this.firstInResponse)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'especie', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoEspecie.ref)
        .where ('especie', '==', this.documentoEspecie.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'especie', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoEspecie.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'especie', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoEspecie.ref)
        );
      }

      if (this.documentoEtapaVida){
        this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAt(this.get_prev_startAt())
        .endBefore(this.firstInResponse)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'edad', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoEtapaVida.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'edad', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoEtapaVida.ref)
        .where ('edad', '==', this.documentoEtapaVida.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoTipoDeProducto ? 'tipoProducto' : 'edad', '==', this.documentoTipoDeProducto ? this.documentoTipoDeProducto.ref : this.documentoEtapaVida.ref)
        );
      }

      if (this.documentoTipoDeProducto){
        this.coleccionProductosPaginadaAnterior = this.angularFirestore.collection('Productos', ref => ref
        .limit(20)
        .orderBy('nombre')
        .startAt(this.get_prev_startAt())
        .endBefore(this.firstInResponse)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoMarca ? 'marca' : 'tipoProducto', '==', this.documentoMarca ? this.documentoMarca.ref : this.documentoTipoDeProducto.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEspecie ? 'especie' : 'tipoProducto', '==', this.documentoEspecie ? this.documentoEspecie.ref : this.documentoTipoDeProducto.ref)
        // tslint:disable-next-line: max-line-length
        .where (this.documentoEtapaVida ? 'edad' : 'tipoProducto', '==', this.documentoEtapaVida ? this.documentoEtapaVida.ref : this.documentoTipoDeProducto.ref)
        .where ('tipoProducto', '==', this.documentoTipoDeProducto.ref)
        );
      }
    } else {
      this.coleccionProductosPaginada = this.angularFirestore.collection('Productos', ref => ref
      .limit(20)
      .orderBy('nombre')
      );
    }
    this.llenaTablaAnterior();
  }

  llenaTablaAnterior(): void{
    this.disablePrev = true;
    this.coleccionProductosPaginadaAnterior
      .snapshotChanges()
      .subscribe(response => {
        this.firstInResponse = response[0].payload.doc;
        this.lastInResponse = response[response.length - 1].payload.doc;

        this.tableData = [];
        for (const item of response) {
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          const urlImagen = this.almacenamiento.ref('Imagenes/Productos/' + id + '/Principal.png');
          this.almacenamiento.ref('Imagenes/Productos/' + id).listAll().subscribe({
            next: x => {
              if (x.items.length === 0){
                objProducto.urlImagen = 'https://firebasestorage.googleapis.com/v0/b/morros-y-colas.appspot.com/o/Imagenes%2FProductos%2FDefault%2FproductoDefault.png?alt=media&token=557e8705-315c-4df2-beef-4d974136990d';
              }
              else {
                urlImagen.getDownloadURL().subscribe(
                  {
                    next: urlImagenDescarga => objProducto.urlImagen = urlImagenDescarga
                  });
              }
            }
          });
          this.tableData.push(objProducto);
        }

        this.paginationClickedCount--;
        this.pop_prev_startAt(this.firstInResponse);
        if (this.paginationClickedCount === 0) {
            this.disablePrev = true;
        } else {
            this.disablePrev = false;
        }
        this.disableNext = false;
      }, error => {
        this.disablePrev = false;
      });
  }

  alEditar(producto: Producto): void{
    this.productoSeleccionado = producto;
  }

  alCrear(): void{
    this.productoSeleccionado = new Producto();
  }

  alEditarGaleria(producto: Producto): void{
    this.productoSeleccionado = producto;
  }

  alEditarPresentaciones(producto: Producto): void{
    this.productoSeleccionado = producto;
  }

  renuevaFiltro(): void{
    if (this.DatosFiltrosForm.value.filtroMarca !== ''){
      this.documentoMarca = this.angularFirestore.doc<Marca>
      ('Marcas/' + this.DatosFiltrosForm.value.filtroMarca);
    } else {
      this.documentoMarca = undefined;
    }

    if (this.DatosFiltrosForm.value.filtroEspecie !== ''){
      this.documentoEspecie = this.angularFirestore.doc<Especie>
      ('Especies/' + this.DatosFiltrosForm.value.filtroEspecie);
    } else {
      this.documentoEspecie = undefined;
    }

    if (this.DatosFiltrosForm.value.filtroEtapaDeVida !== ''){
      this.documentoEtapaVida = this.angularFirestore.doc<Edad>
      ('Edades/' + this.DatosFiltrosForm.value.filtroEtapaDeVida);
    } else {
      this.documentoEtapaVida = undefined;
    }

    if (this.DatosFiltrosForm.value.filtroTipoProducto !== ''){
      this.documentoTipoDeProducto = this.angularFirestore.doc<TipoProducto>
      ('TiposProducto/' + this.DatosFiltrosForm.value.filtroTipoProducto);
    } else {
      this.documentoTipoDeProducto = undefined;
    }
    this.getItems();
  }
}
