<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Usuarios</h6></li>
        <li class="breadcrumb-item"><h6>Registro</h6></li>
    </ol>
</nav>

<div class="row">
    <div class="col-md-6 mx-auto mt-3">
        <div class="card card-body">
            <form [formGroup]="registerForm" (ngSubmit)="onRegister()">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" formControlName="email">
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" formControlName="password">
                </div>
                <div class="form-group">
                    <label for="passwordConfirma">Confirmar password</label>
                    <input type="password" class="form-control" formControlName="passwordConfirma">
                </div>
                <button type="submit" class="btn btn-dark btn-lg btn-block" style="background-color: rgba(34, 142, 157, 1);">Registrar</button>
            </form>
            <div class="form-group mt-3">
                <p>¿Tienes una cuenta de usuario con nosotros? <a [routerLink]="['/Usuarios/Ingreso']" >Ingresar</a></p>
            </div>
        </div>
    </div>
</div>