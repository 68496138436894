import { Component, OnInit } from '@angular/core';
import { Pedido } from '../../pedidos/pedido';
import { AutenticacionService } from '../autenticacion.service';
import { Observable } from 'rxjs';
import { Usuario } from '../usuario';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Component({
  selector: 'app-mis-pedidos',
  templateUrl: './mis-pedidos.component.html',
  styleUrls: ['./mis-pedidos.component.css']
})

export class MisPedidosComponent implements OnInit {

  usuario$: Observable<any> = this.servicioAutenticacion.afAuth.user;
  pedidos: Pedido[];
  pedidoSeleccionado = new Pedido();

  constructor(
    private servicioAutenticacion: AutenticacionService,
    private firestore: AngularFirestore
    ) { }

  ngOnInit(): void {
    this.usuario$.subscribe(x =>
      {
        this.porCliente(x.email);
      });
    this.pedidoSeleccionado = new Pedido();
  }

  porCliente(pmtEmailUsuario: string): void{
    this.firestore.collection('Usuarios', ref => ref.where('email', '==', pmtEmailUsuario))
    .get()
    .toPromise()
    .then(y => {
      this.firestore.collection('Usuarios')
      .doc(y.docs[0].id)
      .collection('Pedidos', ref => ref.orderBy('fechaHoraPedido', 'desc'))
      .get()
      .toPromise()
      .then(z => {
        this.pedidos = new Array();
        z.docs.forEach(element => {
          this.pedidos.push(element.data() as Pedido);
        });
      });
    });
  }

  alDetallar(pmtPedido: Pedido): void {
    this.pedidoSeleccionado = pmtPedido;
  }

}
