<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Usuarios</h6></li>
        <li class="breadcrumb-item"><h6>Ingreso</h6></li>
        <li class="breadcrumb-item"><h6>Recuperar contraseña</h6></li>
    </ol>
</nav>

<div class="row">
    <div class="col-md-6 mx-auto mt-5">
        <div class="card card-body">
            <form [formGroup]="contraseniaForm" (ngSubmit)="alEnviar()">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" formControlName="email">
                </div>
                <button [disabled]="contraseniaForm.invalid" type="submit" class="btn btn-dark btn-lg btn-block" style="background-color: rgba(34, 142, 157, 1);">Enviar correo electrónico de restablecimiento de contraseña</button>
            </form>
        </div>
    </div>
</div>