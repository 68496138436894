import { Component, OnInit } from '@angular/core';
import { EdadesService } from './edades.service';
import { Edad } from './edad';

@Component({
  selector: 'app-catalogo-edades',
  templateUrl: './catalogo-edades.component.html',
  styleUrls: ['./catalogo-edades.component.css']
})

export class CatalogoEdadesComponent implements OnInit {

  constructor(private servicioEdad: EdadesService) { }

  public edades$ = this.servicioEdad.edades$;
  public edadSeleccionada: Edad = new Edad();

  ngOnInit(): void {
  }

  alEditar(edad: Edad): void {
    this.edadSeleccionada = edad;
  }

  alCrear(): void {
    this.edadSeleccionada = new Edad();
  }
}
