import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { Producto } from '../producto';

import { ProductosService } from '../productos.service';
import { MarcasService } from '../../catalogo-marcas/marcas.service';
import { EspeciesService } from '../../catalogo-especies/especies.service';
import { EdadesService } from '../../catalogo-edades/edades.service';
import { TiposProductoService } from '../../catalogo-tipos-producto/tipos-producto.service';
import { Marca } from '../../catalogo-marcas/marca';
import { Especie } from '../../catalogo-especies/especie';
import { Edad } from '../../catalogo-edades/edad';
import { TipoProducto } from '../../catalogo-tipos-producto/tipo-producto';

declare var $: any;

@Component({
  selector: 'app-crear-editar-producto',
  templateUrl: './crear-editar-producto.component.html',
  styleUrls: ['./crear-editar-producto.component.css']
})

export class CrearEditarProductoComponent implements OnInit, OnChanges {

  @Input()
  productoSeleccionado: Producto = new Producto();

  DatosProductoForm = new FormGroup({
    id: new FormControl(''),
    marca: new FormControl('', Validators.required),
    nombre: new FormControl('', Validators.required),
    descripcion: new FormControl('', Validators.required),
    especie: new FormControl('', Validators.required),
    edad: new FormControl('', Validators.required),
    tipoProducto: new FormControl('', Validators.required),
    beneficios: new FormControl('', Validators.required),
    ingredientes: new FormControl('', Validators.required),
    analisisGarantizado: new FormControl('', Validators.required),
    instrucciones: new FormControl('', Validators.required),
    estado: new FormControl('')
  });

  public marcas$ = this.servicioMarcas.marcas$;
  public marcasActivas$ = this.servicioMarcas.marcasActivas$;
  public especies$ = this.servicioEspecies.especies$;
  public especiesActivas$ = this.servicioEspecies.especiesActivas$;
  public edades$ = this.servicioEdades.edades$;
  public edadesActivas$ = this.servicioEdades.edadesActivas$;
  public tiposProducto$ = this.servicioTiposProducto.tiposProducto$;
  public tiposProductosActivos$ = this.servicioTiposProducto.tiposProductoActivos$;

  public marcasActivas: {
    id: string,
    data: Marca
  }[];

  public especiesActivas: {
    id: string,
    data: Especie
  }[];

  public edadesActivas: {
    id: string,
    data: Edad
  }[];

  public tiposProductoActivos: {
    id: string,
    data: TipoProducto
  }[];

  constructor(
    private servicioProductos: ProductosService,
    private servicioMarcas: MarcasService,
    private servicioEspecies: EspeciesService,
    private servicioEdades: EdadesService,
    private servicioTiposProducto: TiposProductoService
    ) { }

    async ngOnChanges(): Promise<void> {

      await this.servicioMarcas.obtenerActivas().then(x => this.marcasActivas = x);
      await this.servicioEspecies.obtenerActivas().then(x => this.especiesActivas = x);
      await this.servicioEdades.obtenerActivas().then(x => this.edadesActivas = x);
      await this.servicioTiposProducto.obtenerActivos().then(x => this.tiposProductoActivos = x);

      if (this.productoSeleccionado.id){
        this.DatosProductoForm.patchValue({
           id: this.productoSeleccionado.id,
           marca: this.productoSeleccionado.marca.id,
           nombre: this.productoSeleccionado.nombre,
           descripcion: this.productoSeleccionado.descripcion,
           especie: this.productoSeleccionado.especie.id,
           edad: this.productoSeleccionado.edad.id,
           tipoProducto: this.productoSeleccionado.tipoProducto.id,
           beneficios: this.productoSeleccionado.beneficios,
           ingredientes: this.productoSeleccionado.ingredientes,
           analisisGarantizado: this.productoSeleccionado.analisisGarantizado,
           instrucciones: this.productoSeleccionado.instrucciones,
           estado: this.productoSeleccionado.estado
          });
      }
    }

  ngOnInit(): void {
  }

  alEnviar(): void{
    const producto = this.DatosProductoForm.value;
    if (!this.productoSeleccionado.id){
      this.servicioProductos.agregar(
        {
          marcaDoc: this.marcasActivas.find(x => x.id === producto.marca).data,
          especieDoc: this.especiesActivas.find(x => x.id === producto.especie).data,
          edadDoc: this.edadesActivas.find(x => x.id === producto.edad).data,
          tipoProductoDoc: this.tiposProductoActivos.find(x => x.id === producto.tipoProducto).data,
          ...producto
        });
    }
    else {
      this.servicioProductos.editar(
        {
          id: this.productoSeleccionado.id,
          marcaDoc: this.marcasActivas.find(x => x.id === producto.marca).data,
          especieDoc: this.especiesActivas.find(x => x.id === producto.especie).data,
          edadDoc: this.edadesActivas.find(x => x.id === producto.edad).data,
          tipoProductoDoc: this.tiposProductoActivos.find(x => x.id === producto.tipoProducto).data,
          ...producto
        });
    }
    this.DatosProductoForm.reset();
    this.productoSeleccionado = new Producto();
    $('#productosModal').modal('hide');
  }
}
