import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { CarritoDeCompras } from '../carrito-de-compras/carrito-de-compras';
import { Pedido } from '../pedidos/pedido';
import { Usuario } from '../usuarios/usuario';

@Injectable({
  providedIn: 'root'
})

export class PedidosService {

  constructor(private firestore: AngularFirestore) { }

  private coleccionPedidos = this.firestore.collectionGroup('Pedidos', ref => ref.orderBy('fechaHoraPedido', 'desc'));

  public pedidos$ = this.coleccionPedidos.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const pedido = p.payload.doc;
        const objPedido = pedido.data() as Pedido;
        return objPedido;
      });
    })
  );

  agregar(
    pmtUsuario: Usuario,
    pmtCarritoDeCompras: CarritoDeCompras,
    pmtFormaPago: string,
    pmtdetallePayPal: any
    ): Promise<DocumentReference> {
    try {
      let pmtPedido: Pedido;
      pmtPedido = {
        datosUsuario: pmtUsuario,
        fechaHoraPedido: new Date(),
        carritoDeCompras: pmtCarritoDeCompras,
        estadoEntrega: 'Pendiente',
        estadoPago: 'Pagado',
        formaPago: pmtFormaPago
      };
      if (pmtdetallePayPal){
        pmtPedido.detallePayPal = pmtdetallePayPal;
      }

      return this.firestore.collectionGroup('Pedidos', ref => ref.orderBy('id', 'desc').limit(1))
      .get()
      .toPromise()
      .then(x => {
        if (x.docs.length === 0){
          return 1;
        } else {
          return x.docs[0].data().id + 1;
        }
      })
      .then(y => {
        pmtPedido.id = y;
        return this.firestore.collection('Usuarios').doc(pmtUsuario.id).collection('Pedidos').add(pmtPedido);
      });
    } catch (err) {
      console.log(err);
    }
  }
}
