<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Pedidos</h6></li>
    </ol>
</nav>

<table class="table table-hover table-sm">
    <thead style="background-color: rgba(34, 142, 157, 1);">
        <tr class="text-light">
            <th scope="col" width="15%">Orden</th>
            <th scope="col" width="40%" class="text-right">Importe</th>
            <th scope="col" width="40%" class="text-right">Fecha</th>
            <th scope="col" width="5%"></th>
        </tr>
    </thead>
    <tbody class="table-light">
        <tr *ngFor="let p of pedidos$ | async">
            <td>{{ p.id }}</td>
            <td class="text-right">{{ p.carritoDeCompras.importeTotal | currency: 'MXN' }}</td>
            <td class="text-right">{{ p.fechaHoraPedido.toDate() | date: 'dd/MM/yyyy HH:mm' }}</td>
            <td>
                <a [href]="'/Pedidos#detallePedido'" class="btn btn-sm btn-outline-dark" (click)="alDetallar(p)">
                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-eye" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.134 13.134 0 0 0 1.66 2.043C4.12 11.332 5.88 12.5 8 12.5c2.12 0 3.879-1.168 5.168-2.457A13.134 13.134 0 0 0 14.828 8a13.133 13.133 0 0 0-1.66-2.043C11.879 4.668 10.119 3.5 8 3.5c-2.12 0-3.879 1.168-5.168 2.457A13.133 13.133 0 0 0 1.172 8z"/>
                        <path fill-rule="evenodd" d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                    </svg>
                </a>
            </td>
        </tr>
    </tbody>
</table>

<div id="detallePedido" *ngIf="pedidoSeleccionado.datosUsuario.email">
    <div class="card h-100">
        <div class="card-header">
            <strong>Datos de la orden: {{pedidoSeleccionado.id}}</strong>
        </div>
        <div class="card-body">
            <div class="row row-cols-1 row-cols-md-2">
                <div class="col mb-4">
                  <div class="card h-100">
                    <div class="card-header">
                        <strong>Datos del pedido</strong>
                    </div>
                    <div class="card-body">
                      <table class="table table-sm">
                          <tbody>
                            <tr> 
                                <th>Numero de orden</th>
                                <td>{{pedidoSeleccionado.id}}</td>
                            </tr>
                            <tr> 
                                <th>Importe de la compra</th>
                                <td>{{pedidoSeleccionado.carritoDeCompras.importeTotal | currency: 'MXN'}}</td>
                            </tr>
                            <tr> 
                                <th>Cantidad de articulos</th>
                                <td>{{pedidoSeleccionado.carritoDeCompras.cantidadTotal}}</td>
                            </tr>
                            <tr>
                                <th>Nombre</th>
                                <td>{{pedidoSeleccionado.datosUsuario.nombre + ' ' + pedidoSeleccionado.datosUsuario.apellidoMaterno + ' ' + pedidoSeleccionado.datosUsuario.apellidoPaterno}}</td>                        
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td>{{pedidoSeleccionado.datosUsuario.email}}</td>
                            </tr>
                            <tr>
                                <th>Telefono fijo</th>
                                <td>{{pedidoSeleccionado.datosUsuario.telefonoFijo}}</td>
                            </tr>
                            <tr> 
                                <th>Telefono movil</th>
                                <td>{{pedidoSeleccionado.datosUsuario.telefonoMovil}}</td>
                            </tr>
                          </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="col mb-4">
                  <div class="card">
                    <div class="card-header">
                        <strong>Datos de envío</strong>
                    </div>
                    <div class="card-body">
                        <table class="table table-sm">
                            <tbody>
                                <tr>
                                    <th>Calle</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.calle}}</td>
                                </tr>
                                <tr>
                                    <th>Número exterior</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.numeroExterior}}</td>
                                </tr>
                                <tr>
                                    <th>Número interior</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.numeroInterior}}</td>
                                </tr>
                                <tr> 
                                    <th>Colonia</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.colonia}}</td>
                                </tr>
                                <tr> 
                                    <th>Codigo postal</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.codigoPostal}}</td>
                                </tr>
                                <tr> 
                                    <th>Municipio</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.municipio}}</td>
                                </tr>
                                <tr> 
                                    <th>Estado</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.estado}}</td>
                                </tr>
                                <tr> 
                                    <th>Refencias</th>
                                    <td>{{pedidoSeleccionado.datosUsuario.direccion.referencias}}</td>
                                </tr>
                            </tbody>
                        </table>              
                    </div>
                  </div>
                </div>
            </div>        
        </div>
    </div>
    <div class="card mt-3 h-100">
        <div class="card-header">
            <strong>Productos de la orden: {{pedidoSeleccionado.id}}</strong>
        </div>
        <div class="card-body">
            <div class="row row-cols-1 row-cols-md-3 mt-3">
                <div *ngFor="let producto of pedidoSeleccionado.carritoDeCompras.productos; index as i" class="col mb-4">
                  <div class="card h-100 border-dark">
                    <div class="card-header text-right">
                        <h5>SKU: {{producto.sku}}</h5>
                    </div>
                    <div class="card-body">
                        <h6 class="card-subtitle mb-2">Cantidad:</h6>
                        <p class="card-text">{{producto.cantidad}} x {{producto.precio | currency: 'MXN' }} = {{ (producto.cantidad * producto.precio) | currency: 'MXN' }}</p>
                        <h6 class="card-subtitle mb-2">Presentacion:</h6>
                        <p class="card-text">{{producto.descripcionPresentacion}}</p>
                        <h6 class="card-subtitle mb-2">Producto:</h6>
                        <p class="card-text">{{producto.nombreProducto}}</p>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>