<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Catálogos</h6></li>
        <li class="breadcrumb-item"><h6>Marcas</h6></li>
    </ol>
</nav>

<button class="btn btn-block btn-dark mt-3 mb-3" style="background-color: rgba(34, 142, 157, 1);" (click)="alCrear()" data-toggle="modal" data-target="#marcasModal">
    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus-square" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M14 1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
      </svg>
    Marcas
</button>    

<div *ngIf="marcas$ | async as marcas">
    <table class="table table-hover table-sm">
        <thead style="background-color: rgba(34, 142, 157, 1);">
            <tr class="text-light">
                <th scope="col" width="20%">Nombre</th>
                <th scope="col" width="70%">Slogan</th>
                <th scope="col" width="5%"></th>
                <th scope="col" width="5%"></th>
            </tr>
        </thead>
        <tbody class="table-light">
            <tr *ngFor="let marca of marcas">
                <td>{{ marca.nombre }}</td>
                <td>{{ marca.slogan }}</td>
                <td>
                    <div class="custom-control custom-switch">
                        <input type="checkbox" class="custom-control-input" [checked]="marca.estado">
                        <label class="custom-control-label"></label>
                    </div>
                </td>
                <td>
                    <button class="btn btn-sm btn-outline-dark" (click)="alEditar(marca)" data-toggle="modal" data-target="#marcasModal">
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                        </svg>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-crear-editar-marca [marcaSeleccionada]="marcaSeleccionada"></app-crear-editar-marca>