import { Component, OnInit } from '@angular/core';
import { PedidosService } from '../pedidos/pedidos.service';
import { Pedido } from './pedido';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {

  pedidos$ = this.servicioPedidos.pedidos$;
  pedidoSeleccionado = new Pedido();

  constructor(private servicioPedidos: PedidosService) { }

  ngOnInit(): void {
    this.pedidoSeleccionado = new Pedido();
  }

  alDetallar(pmtPedido: Pedido): void {
    this.pedidoSeleccionado = pmtPedido;
  }
}
