import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AutenticacionService } from '../autenticacion.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-ingreso',
  templateUrl: './ingreso.component.html',
  styleUrls: ['./ingreso.component.css'],
  providers: [AutenticacionService]
})

export class IngresoComponent implements OnInit {

  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl('')
  });

  constructor(private authSvc: AutenticacionService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  async onLogin(): Promise<void>{
    const {email, password} = this.loginForm.value;
    try {
      const user = await this.authSvc.login(email, password);
      if (user){
        this.toastr.success('¡Que gusto tenerte de regreso!', 'Bienvenido');
        this.router.navigate(['/']);
      }
    }
    catch (error){
      this.toastr.error(error);
    }
  }
}
