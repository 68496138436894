import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Producto, Presentacion } from '../producto';
import { ProductosService } from '../productos.service';

@Component({
  selector: 'app-presentaciones',
  templateUrl: './presentaciones.component.html',
  styleUrls: ['./presentaciones.component.css']
})

export class PresentacionesComponent implements OnInit, OnChanges {

  @Input()
  productoSeleccionado: Producto;

  DatosPresentacionForm = new FormGroup({
    descripcion: new FormControl('', Validators.required),
    ean: new FormControl('', Validators.required),
    sku: new FormControl('', Validators.required),
    costo: new FormControl('', Validators.required),
    precio: new FormControl('', Validators.required)
  });

  public presentacionSeleccionada = -1;

  constructor(private servicioProductos: ProductosService) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.DatosPresentacionForm.reset();
    this.presentacionSeleccionada = -1;
  }

  async alEnviar(): Promise<void>{
    const presentacion = this.DatosPresentacionForm.value;

    if (this.presentacionSeleccionada === -1){
      if (!this.productoSeleccionado.presentaciones){
        this.productoSeleccionado.presentaciones = new Array();
      }
      this.productoSeleccionado.presentaciones.push(presentacion);
    } else {
      this.productoSeleccionado.presentaciones[this.presentacionSeleccionada] = presentacion;
    }

    this.servicioProductos.agregarPresentacion(this.productoSeleccionado.id, this.productoSeleccionado.presentaciones);
    this.DatosPresentacionForm.reset();
    this.presentacionSeleccionada = -1;
  }

  alEditar(i: number): void{
    this.presentacionSeleccionada = i;
    this.DatosPresentacionForm.patchValue({
      descripcion: this.productoSeleccionado.presentaciones[i].descripcion,
      ean: this.productoSeleccionado.presentaciones[i].ean,
      sku: this.productoSeleccionado.presentaciones[i].sku,
      costo: this.productoSeleccionado.presentaciones[i].costo,
      precio: this.productoSeleccionado.presentaciones[i].precio
    });
  }
}
