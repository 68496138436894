<div class="modal fade" id="presentacionesProductosModal" tabindex="-1" aria-labelledby="presentacionesProductosModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="presentacionesProductosModalLabel">Presentaciones: {{ productoSeleccionado.nombre }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div *ngIf="productoSeleccionado.presentaciones as presentaciones">
                    <table class="table table-hover table-sm">
                        <thead style="background-color: rgba(34, 142, 157, 1);">
                            <tr class="text-light">
                                <th scope="col" width="19%">Descripcion</th>
                                <th scope="col" width="19%" class="text-right">EAN</th>
                                <th scope="col" width="19%" class="text-right">SKU</th>
                                <th scope="col" width="19%" class="text-right">Costo</th>
                                <th scope="col" width="19%"  class="text-right">Precio</th>
                                <th scope="col" width="5%"></th>
                            </tr>
                        </thead>
                        <tbody class="table-light">
                            <tr *ngFor="let e of presentaciones; index as i">
                                <td>{{ e.descripcion }}</td>
                                <td  class="text-right">{{ e.ean }}</td>
                                <td  class="text-right">{{ e.sku }}</td>
                                <td  class="text-right">{{ e.costo | currency: 'MXN' }}</td>
                                <td  class="text-right">{{ e.precio | currency: 'MXN' }}</td>
                                <td>
                                    <button class="btn btn-sm btn-outline-dark" (click)="alEditar(i)">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                        </svg>
                                    </button>                
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="card card-body mt-3">
                    <h5 *ngIf="presentacionSeleccionada == -1">Agregar presentacion</h5>
                    <h5 *ngIf="presentacionSeleccionada > -1">Editar datos de {{ productoSeleccionado.presentaciones[presentacionSeleccionada].descripcion }}</h5>            
                    <form [formGroup]="DatosPresentacionForm">
                    <div class="form-row">
                        <div class="form-group col">
                            <input  type="text"
                                    class="form-control"
                                    formControlName="descripcion"
                                    aria-describedby="descripcionHelp"
                                    placeholder="Descripcion"
                                    autocomplete="off">
                            <small id="descripcionHelp" class="form-text text-muted">Descripcion de la presentación.</small>
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-group col-lg-3">
                            <input  type="text"
                                    class="form-control"
                                    formControlName="ean"
                                    aria-describedby="eanHelp"
                                    placeholder="EAN"
                                    autocomplete="off">
                            <small id="eanHelp" class="form-text text-muted">EAN del producto</small>
                        </div>
                        <div class="form-group col-lg-3">
                            <input  type="text"
                                    class="form-control"
                                    formControlName="sku"
                                    aria-describedby="skuHelp"
                                    placeholder="SKU"
                                    autocomplete="off">
                            <small id="skuHelp" class="form-text text-muted">SKU del producto</small>
                        </div>
                        <div class="form-group col-lg-3">
                            <input  type="number"
                                    min="0"
                                    step="0.01"
                                    class="form-control"
                                    formControlName="costo"
                                    aria-describedby="costoHelp"
                                    placeholder="Costo de la presentacion"
                                    autocomplete="off">
                            <small id="costoHelp" class="form-text text-muted">Costo del producto.</small>
                        </div>
                        <div class="form-group col-lg-3">
                            <input  type="number"
                                    min="0"
                                    step="0.01"
                                    class="form-control"
                                    formControlName="precio"
                                    aria-describedby="precioHelp"
                                    placeholder="Precio de la presentacion"
                                    autocomplete="off">
                            <small id="precioHelp" class="form-text text-muted">Precio de la presentación.</small>
                        </div>    
                    </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="DatosPresentacionForm.invalid" class="btn btn-primary" type="button" (click)="alEnviar()">
                    <span *ngIf="presentacionSeleccionada == -1">Agregar</span>
                    <span *ngIf="presentacionSeleccionada > -1">Editar</span>        
                </button>
                <button type="button" id='BotonCerrar' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>