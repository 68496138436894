import { Component, OnInit } from '@angular/core';
import { ProductosHills } from '../fuenteCargaProductos/hills';
import { ProductosRoyalCanin } from '../fuenteCargaProductos/royalCanin';
import { ProductosProPlan } from '../fuenteCargaProductos/proPlan';
import { ProductosDiamond } from '../fuenteCargaProductos/diamond';
import { ProductosEukanuba } from '../fuenteCargaProductos/eukanuba';
import { ProductosNupec } from '../fuenteCargaProductos/nupec';
import { ProductosService } from '../productos.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Producto } from '../producto';
import { Marca } from '../../catalogo-marcas/marca';

@Component({
  selector: 'app-carga-productos',
  templateUrl: './carga-productos.component.html',
  styleUrls: ['./carga-productos.component.css']
})
export class CargaProductosComponent implements OnInit {

  constructor(
    private servicioProductos: ProductosService,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage
    ) { }

  ngOnInit(): void {
  }

  async CargarProductos(): Promise<void>{
    alert('Proceso de carga de productos');

    let nuevoProducto;
    let nuevoIdProducto = '0';
    let anteriorIdProducto = '0';
    let nuevaPresentacion;
    let MarcaDoc: Marca;

    // Hills
    // const docRefMarca = 'BqIEgUefClq3EhBS1NOZ';

    // RoyalCanin
    // const docRefMarca = 'IESGi5bIy3rt4p6Tbkg4';
    // const docRefMarca = 'LocUM2X1VCE8GyoHSi35';

    // ProPlan
    // const docRefMarca = 'x2ys5Ip7C0D8nhml2sOJ';

    // Diamond
    // const docRefMarca = '3C1hIlK0uQSZJY17ZPiH';

    // Eukanuba
    const docRefMarca = 'yB5xVV2rdMCyfAwGpKIg';

    // Nupec
    // const docRefMarca = '77BjLegdKQypyXQVCCVp';

    await this.firestore.collection('Marcas').doc(docRefMarca)
    .get()
    .toPromise()
    .then(x => { console.log(x.data()); MarcaDoc = x.data() as Marca; })
    .catch(e => console.log(e));

    let docRefEspecie: string;
    let docRefEdad: string;
    let docRefTipoProducto: string;

    // ProductosHills.Hills.forEach(async unProducto => {
    // ProductosRoyalCanin['Royal Canin'].forEach(async unProducto => {
    // ProductosProPlan.Proplan.forEach(async unProducto => {
    // ProductosDiamond.DIAMOND.forEach(unProducto => {
    ProductosEukanuba.Eukanuba.forEach(unProducto => {
    // ProductosNupec.NUPEC.forEach(unProducto => {
      if (unProducto.Especie === 'Perro'){
        docRefEspecie = '9agBUJ0zGd3iJVYLnpqZ';
      } else if (unProducto.Especie === 'Gato'){
        docRefEspecie = 'IjL8JsB0jbcscQaLBCXA';
      } else {
        docRefEspecie = '3LEjYeEuT0Ov7R3ift3M';
      }

      if (unProducto.Edad === 'Puppy' || unProducto.Edad === 'Cachorro'){
        docRefEdad = 'KahFDSdXxE0dB3u38pau';
      } else if (unProducto.Edad === 'Kitten' || unProducto.Edad === 'Gatito'){
        docRefEdad = 'snqkJoHnxC7vXdpATpnC';
      } else if (unProducto.Edad === 'Adulto'){
        docRefEdad = 'vp80NK7ZCu84iB3idibV';
      } else if (unProducto.Edad === 'Adulto 7+'){
        docRefEdad = 'Jo4QV98tJqAqVFQMyHnk';
      } else if (unProducto.Edad === 'Senior'){
        docRefEdad = 'LGjjQDk1aIoO3nKPYDVh';
      } else if (unProducto.Edad === 'Especialidades'){
        docRefEdad = 'UbRjLq5sEKworBsysq47';
      } else {
        docRefEdad = 'GhamnQJqzwMq52X9kZQT';
      }

      if (unProducto.TipoDeProducto === 'Húmedo'){
        docRefTipoProducto = 'K3bxUPPXnhDVpqJD9Fix';
      } else if (unProducto.TipoDeProducto === 'Seco'){
        docRefTipoProducto = 'R9n7glIoayHDDdXTwSzH';
      } else if (unProducto.TipoDeProducto === 'Premios' || unProducto.TipoDeProducto === 'Treats'){
        docRefTipoProducto = 'egElF4YyVIlNQMC7PnD2';
      } else if (unProducto.TipoDeProducto === 'Semihumedo'){
        docRefTipoProducto = '9b26ORXTlEhSUAOoSS0W';
      } else {
        docRefTipoProducto = 'n8LTpxoROUyWUK5XkJwD';
      }

      nuevoIdProducto = unProducto.Id;

      let descripcionPresentacion = '';
      if (Number(unProducto.PesoUnitarioKg) < 1){
        descripcionPresentacion = (Number(unProducto.PesoUnitarioKg) * 1000).toString() + 'gr';
      } else if (Number(unProducto.PesoUnitarioKg) >= 1) {
        descripcionPresentacion = Number(unProducto.PesoUnitarioKg).toString() + 'kg';
      }

      if (Number(unProducto.PesoUnitarioLB) > 0){
        if (Number(unProducto.PesoUnitarioKg) > 0){
          descripcionPresentacion += ' | ';
        }
        descripcionPresentacion += Number(unProducto.PesoUnitarioLB).toString() + 'lb';
      }

      nuevaPresentacion = {
        ean: unProducto.Ean,
        sku: unProducto.Sku,
        descripcion: descripcionPresentacion,
        costo: Number(unProducto.Costo),
        precio: Number(unProducto.Precio)
      };

      if (nuevoIdProducto === anteriorIdProducto){
        nuevoProducto.presentaciones.push(
          {
          ean: unProducto.Ean,
          sku: unProducto.Sku,
          descripcion: descripcionPresentacion,
          costo: Number(unProducto.Costo),
          precio: Number(unProducto.Precio)
          }
        );

      } else {

        if (anteriorIdProducto !== '0'){
          console.log('Insertando: ' + anteriorIdProducto + ' - ' + nuevoProducto.nombre + ' ' + nuevoProducto.presentaciones[0].sku);
          this.servicioProductos.agregar2(nuevoProducto);
        }

        nuevoProducto = {
          marca: this.firestore.collection('Marcas').doc(docRefMarca).ref,
          marcaDoc: MarcaDoc,
          nombre: MarcaDoc.nombre + ' - ' + unProducto.Nombre,
          // nombre: unProducto.Nombre,
          especie: this.firestore.collection('Especies').doc(docRefEspecie).ref,
          edad: this.firestore.collection('Edades').doc(docRefEdad).ref,
          tipoProducto: this.firestore.collection('TiposProducto').doc(docRefTipoProducto).ref,
          descripcion: unProducto.Descripcion ? unProducto.Descripcion : '',
          beneficios: unProducto.Beneficios ? unProducto.Beneficios : '',
          ingredientes: unProducto.Ingredientes ? unProducto.Ingredientes : '',
          analisisGarantizado: unProducto.AnalisisGarantizado ? unProducto.AnalisisGarantizado : '',
          instrucciones: unProducto.Instrucciones ? unProducto.Instrucciones : '',
          estado: true
        };

        nuevoProducto.presentaciones = new Array();
        nuevoProducto.presentaciones.push(
          {
          ean: unProducto.Ean,
          sku: unProducto.Sku,
          descripcion: descripcionPresentacion,
          costo: Number(unProducto.Costo),
          precio: Number(unProducto.Precio)
          }
        );
      }
      anteriorIdProducto = nuevoIdProducto;
    });
  }

  eliminarProductoMarca(): void {
    // Hills
    const docRefMarca = 'BqIEgUefClq3EhBS1NOZ';

    // RoyalCanin
    // const docRefMarca = 'IESGi5bIy3rt4p6Tbkg4';

    // ProPlan
    // const docRefMarca = 'x2ys5Ip7C0D8nhml2sOJ';

    // Diamond
    // const docRefMarca = '3C1hIlK0uQSZJY17ZPiH';

    // Eukanuba
    // const docRefMarca = 'yB5xVV2rdMCyfAwGpKIg';

    // Nupec
    // const docRefMarca = '77BjLegdKQypyXQVCCVp';

    try {
      const coleccionParaBorrar = this.firestore.collection('Productos', ref => ref.where(
        'marca',
        '==',
        this.firestore.collection('Marcas').doc(docRefMarca).ref));
      coleccionParaBorrar.snapshotChanges().subscribe(x => {
        let i = 0;
        for (const item of x){
          const producto = item.payload.doc;
          const id = producto.id;
          const objProducto = { id, ...(producto.data() as {}) } as Producto;
          this.storage.ref('Imagenes/Productos/' + id ).listAll().subscribe( y => {
            y.items.forEach(elemento => {
              console.log(elemento.name);
              this.storage.ref('Imagenes/Productos/' + id + '/' + elemento.name)
                .delete()
                .toPromise()
                .then(z => console.log('Borrado: ' + id + ' ' + elemento.name))
                .catch(e => console.log('Error: ' + e));
            });
            item.payload.doc.ref.delete();
          });
          console.log(i++ + ' ' + id + ' ' + objProducto.nombre);
        }
      });
    } catch (e){
      console.log(e);
    }
  }
}
