import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {

  constructor(private almacenamiento: AngularFireStorage) { }

  obtenerImagenesProducto(producto: string): Observable<any> {
    const miOtroAlmacen = this.almacenamiento.ref('Imagenes/Productos/' + producto);
    const listaImagenes$ = miOtroAlmacen.listAll().pipe(
      map(actions => {
      return actions.items.map(p => {
        const url = p.getDownloadURL();
        const nombre = p.name;
        return { nombre, url };
      })
    }));
    return listaImagenes$;
  }

  cargarArchivo(pmtArchivo: File, pmtIdProducto: string): Observable<any> {
    try {
      const rutaArchivo = 'Imagenes/Productos/' + pmtIdProducto + '/' + pmtArchivo.name;
      const referenciaArchivo = this.almacenamiento.ref(rutaArchivo);
      const tarea = referenciaArchivo.put(pmtArchivo);
      return tarea.percentageChanges();
    }
    catch (err) {
      console.log(err);
    }
  }

  eliminarArchivo(pmtIdProducto, pmtNombreArchivo): Observable<any> {
    const rutaArchivo = 'Imagenes/Productos/' + pmtIdProducto + '/' + pmtNombreArchivo;
    const referenciaArchivo = this.almacenamiento.ref(rutaArchivo);
    return referenciaArchivo.delete();
  }
}
