import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Producto } from '../producto';
import { Observable } from 'rxjs';
import { ImagenesService } from './imagenes.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-imagenes',
  templateUrl: './imagenes.component.html',
  styleUrls: ['./imagenes.component.css']
})

export class ImagenesComponent implements OnInit, OnChanges {

  @Input()
  productoSeleccionado: Producto = new Producto();

  DatosImagenForm = new FormGroup({
    archivoPaTrepar: new FormControl('', Validators.required)
  });

  public leyendaArchivoSeleccionado: string;
  public archivo: File;
  public uploadPercent: Observable<number>;
  public listaImagenes$: Observable<any>;
  public nuevaImagenCargada: false;

  constructor(
    private servicioImagenes: ImagenesService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.listaImagenes$ = this.servicioImagenes.obtenerImagenesProducto(this.productoSeleccionado.id);
    this.uploadPercent = null;
    this.DatosImagenForm.reset();
    this.leyendaArchivoSeleccionado = 'Haga click aquí para seleccionar un archivo...';
  }

  agregar(event: { target: { files: any[]; }; }): void {
    this.archivo = event.target.files[0];
    this.leyendaArchivoSeleccionado = this.archivo.name;
  }

  cargarImagen(): void {
    try {
      const myObserver =
      {
        next: (x: any) =>
        {
          if (x === 100){
            setTimeout(() => {
              this.listaImagenes$ = this.servicioImagenes.obtenerImagenesProducto(this.productoSeleccionado.id);
              this.uploadPercent = null;
            }, 2000);
          }
        }
      };
      this.uploadPercent = this.servicioImagenes.cargarArchivo(this.archivo, this.productoSeleccionado.id);
      this.uploadPercent.subscribe(myObserver);
      this.DatosImagenForm.reset();
      this.leyendaArchivoSeleccionado = 'Haga click aquí para seleccionar un archivo...';
    } catch (err) {
      console.log(err);
    }
  }

  recargar(): void{
    this.listaImagenes$ = this.servicioImagenes.obtenerImagenesProducto(this.productoSeleccionado.id);
    this.uploadPercent = null;
  }

  eliminar(pmtImagen: any): void {
    const myObserver =
    {
      next: () =>
      {
        this.listaImagenes$ = this.servicioImagenes.obtenerImagenesProducto(this.productoSeleccionado.id);
      }
    };
    this.servicioImagenes.eliminarArchivo(this.productoSeleccionado.id, pmtImagen.nombre)
    .subscribe(myObserver);
  }
}
