<div class="modal fade" id="etapaVidaModal" tabindex="-1" aria-labelledby="etapaVidaModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="etapaVidaModalLabel">Etapas de vida: {{especieSeleccionada.nombre}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                    <table class="table table-hover table-sm">
                        <thead style="background-color: rgba(34, 142, 157, 1);">
                            <tr class="text-light">
                                <th scope="col" width="95%">Etapa</th>
                                <th scope="col" width="5%"></th>
                            </tr>
                        </thead>
                        <tbody class="table-light">
                            <tr *ngFor="let e of especieSeleccionada.etapasDeVida; index as i">
                                <td>{{ e.nombre }}</td>
                                <td>
                                    <button class="btn btn-sm btn-outline-dark" (click)="alEliminar(i)">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-x" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <form id="etapaDeVidaForm" [formGroup]="etapaDeVidaForm">
                                        <div *ngIf="listaEdadesActivas$ | async as listaEdades">
                                            <select name="etapaDeVida" class="form-control" formControlName="etapaDeVida">
                                                <option value="" selected>Seleccione la etapa de vida</option>
                                                <option [value]="e.id" *ngFor="let e of listaEdades">
                                                    {{e.nombre}}
                                                </option>
                                            </select>    
                                        </div>
                                    </form>
                                </td>
                                <td>
                                    <button *ngIf="etapaDeVidaSeleccionada == -1" [disabled]="etapaDeVidaForm.invalid" (click)="alAgregar()" class="btn btn-sm btn-outline-dark">
                                    <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-plus" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                                      </svg>
                                    </button>
                                    <button *ngIf="etapaDeVidaSeleccionada > -1" [disabled]="etapaDeVidaForm.invalid" (click)="alAgregar()" class="btn btn-sm btn-outline-dark">
                                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-pencil" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5L13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175l-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"/>
                                        </svg>
                                    </button>    
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
            <div class="modal-footer">
                <button type="button" id='BotonCerrar' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>        
        </div>
    </div>    
</div>