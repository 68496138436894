import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ImagenesService } from 'src/app/catalogos/catalogo-productos/imagenes/imagenes.service';
import { Presentacion, Producto } from 'src/app/catalogos/catalogo-productos/producto';
import { CarritoDeComprasService } from '../../carrito-de-compras/carrito-de-compras.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-detalle-producto',
  templateUrl: './detalle-producto.component.html',
  styleUrls: ['./detalle-producto.component.css']
})

export class DetalleProductoComponent implements OnInit, OnChanges {

  @Input()
  productoSeleccionado: Producto;

  @Input()
  versionMovil: boolean;

  public presentacionSeleccionada: Presentacion = new Presentacion();
  public listaImagenes;

  constructor(
    private servicioImagenes: ImagenesService,
    private carritoDeCompras: CarritoDeComprasService,
    private toastr: ToastrService
    ) { }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.servicioImagenes.obtenerImagenesProducto(this.productoSeleccionado.id)
    .toPromise().then(x => {
      this.listaImagenes = new Array();
      x.forEach(element => {
        if (element.nombre !== 'Principal.png'){
          this.listaImagenes.push(element);
        }
      });
    });
  }

  alAgregar(  pmtProducto: Producto,
              pmtPresentacion: Presentacion): void {
    this.carritoDeCompras.agregarProductoLS(pmtProducto, pmtPresentacion);
    this.presentacionSeleccionada = pmtPresentacion;
    this.toastr.info(pmtProducto.nombre + ' (' + pmtPresentacion.descripcion +  ')', 'Usted agrego a su carrito:');
  }
}
