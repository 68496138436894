<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><h6>Usuarios</h6></li>
        <li class="breadcrumb-item"><h6>Ingreso</h6></li>
    </ol>
</nav>

<div class="row">
    <div class="col-md-6 mx-auto mt-5">
        <div class="card card-body">
            <form [formGroup]="loginForm" (ngSubmit)="onLogin()">
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="email" class="form-control" formControlName="email">
                </div>
                <div class="form-group">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" formControlName="password">
                </div>
                <button type="submit" class="btn btn-dark btn-lg btn-block" style="background-color: rgba(34, 142, 157, 1);">Ingresar</button>
            </form>
            <div class="form-group mt-3">
                <p>¿No tienes una cuenta con nosotros? <a [routerLink]="['/Usuarios/Registro']" >Registrate</a></p>
                <p>¿Olvidaste tu contraseña? <a [routerLink]="['RecuperarContrasenia']" >Recupera tu contraseña</a></p>
            </div>
        </div>
    </div>
</div>