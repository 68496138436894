import { DocumentReference } from '@angular/fire/firestore';
import { Edad } from '../catalogo-edades/edad';
import { Especie } from '../catalogo-especies/especie';
import { Marca } from '../catalogo-marcas/marca';
import { TipoProducto } from '../catalogo-tipos-producto/tipo-producto';

export class Producto {
    id: string;
    marca: DocumentReference;
    marcaDoc: Marca;
    nombre: string;
    especie: DocumentReference;
    especieDoc: Especie;
    edad: DocumentReference;
    edadDoc: Edad;
    tipoProducto: DocumentReference;
    tipoProductoDoc: TipoProducto;
    descripcion: string;
    presentaciones: Presentacion[];
    beneficios: string;
    ingredientes: string;
    analisisGarantizado: string;
    instrucciones: string;
    palabrasClave: string[];
    urlImagen: string;
    estado: boolean;
}

export class Presentacion{
    ean: string;
    sku: string;
    descripcion: string;
    costo: number;
    precio: number;
  }
