import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Marca } from './marca';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MarcasService {

  constructor(private firestore: AngularFirestore, private almacenamiento: AngularFireStorage) { }

  private coleccionMarcas = this.firestore.collection('Marcas', ref => ref.orderBy('orden'));
  private coleccionMarcasActivas = this.firestore.collection('Marcas', ref => ref.where('estado', '==', true).orderBy('orden'));
  private marcasActivas: {
    id: string,
    data: Marca
  }[];

  public marcas$ = this.coleccionMarcas.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const marca = p.payload.doc;
        const id = marca.id;
        const objMarca = { id, ...(marca.data() as {}) } as Marca;
        return objMarca;
      })
    })
  );

  public marcasActivas$ = this.coleccionMarcasActivas.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const marca = p.payload.doc;
        const id = marca.id;
        const objMarca = { id, ...(marca.data() as {}) } as Marca;
        const urlImagen = this.almacenamiento.ref('Imagenes/Marcas/' + objMarca.ruteo + '.jpg');
        urlImagen.getDownloadURL().subscribe({ next: x => objMarca.urlImagen = x });
        return objMarca;
      });
    })
  );

  async obtenerActivas(): Promise<{id: string, data: Marca}[]>{
    this.marcasActivas = new Array();
    (await this.firestore.collection('Marcas', ref => ref.orderBy('orden'))
      .get()
      .toPromise())
      .forEach(element => {
      this.marcasActivas.push({
        id: element.id,
        data: element.data() as Marca
      });
    });
    return this.marcasActivas;
  }

  async agregar(marca: Marca): Promise<void> {
    try {
      await this.coleccionMarcas.add
      (
        {
          nombre: marca.nombre,
          ruteo: marca.ruteo,
          slogan: marca.slogan,
          sitioWeb: marca.sitioWeb,
          estado: marca.estado,
          orden: marca.orden
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async editar(marca: Marca): Promise<void> {
    try {
      await this.coleccionMarcas
        .doc(marca.id)
        .set(
          {
            nombre: marca.nombre,
            ruteo: marca.ruteo,
            slogan: marca.slogan,
            sitioWeb: marca.sitioWeb,
            estado: marca.estado,
            orden: marca.orden
          },
          {
            merge: true
          });
    } catch (err) {
      console.log(err);
    }
  }
}