import { Component, OnInit } from '@angular/core';
import { IPayPalConfig, ICreateOrderRequest  } from 'ngx-paypal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AutenticacionService } from '../usuarios/autenticacion.service';
import { UsuariosService } from '../usuarios/usuarios.service';
import { Observable } from 'rxjs';
import { CarritoDeCompras } from './carrito-de-compras';
import { CarritoDeComprasService } from './carrito-de-compras.service';
import { ToastrService } from 'ngx-toastr';
import { PedidosService } from '../pedidos/pedidos.service';
import { DocumentReference } from '@angular/fire/firestore';
import { Pedido } from '../pedidos/pedido';

@Component({
  selector: 'app-carrito-de-compras',
  templateUrl: './carrito-de-compras.component.html',
  styleUrls: ['./carrito-de-compras.component.css']
})

export class CarritoDeComprasComponent implements OnInit {

  public carritoDeCompras: CarritoDeCompras;
  public usuario$: Observable<any> = this.servicioAutenticacion.afAuth.user;
  public payPalConfig?: IPayPalConfig;
  public confirmoDatos = false;
  public formaPago: string;
  public pasoCompra: string;
  public pedidoConfirmado: DocumentReference;
  public pedido: Pedido;
  public usuarioVerificado: boolean;

  DatosUsuarioForm = new FormGroup({
    id: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
    apellidoPaterno: new FormControl('', [Validators.required, Validators.minLength(3)]),
    apellidoMaterno: new FormControl('', [Validators.required, Validators.minLength(3)]),
    sexo: new FormControl(''),
    edad: new FormControl(''),
    telefonoFijo: new FormControl('', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]),
    telefonoMovil: new FormControl('', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]),
    direccion: new FormGroup({
      calle: new FormControl('', [Validators.required, Validators.minLength(3)]),
      numeroExterior: new FormControl('', Validators.required),
      numeroInterior: new FormControl(''),
      codigoPostal: new FormControl('', [Validators.required, Validators.minLength(5), Validators.pattern('^[0-9]*$')]),
      colonia: new FormControl('', [Validators.required, Validators.minLength(3)]),
      municipio: new FormControl('', Validators.required),
      estado: new FormControl('', Validators.required),
      referencias: new FormControl('')
    })
  });

  constructor(
    private servicioCarritoDeCompras: CarritoDeComprasService,
    private servicioAutenticacion: AutenticacionService,
    private servicioUsuarios: UsuariosService,
    private toastr: ToastrService,
    private servicioPedidos: PedidosService,
    ) { }

  ngOnInit(): void {
    this.initConfig();
    this.pasoCompra = 'carrito';
    this.carritoDeCompras = this.servicioCarritoDeCompras.consultar();
    this.usuario$.subscribe(x => {
      if (x){
        this.usuarioVerificado = x.emailVerified;
        this.servicioUsuarios.obtenerPorEmail(x.email).subscribe(y => {
          if (y.length > 0){
            this.DatosUsuarioForm.patchValue({
              id: y[0].id,
              email: y[0].email,
              nombre: y[0].nombre,
              apellidoPaterno: y[0].apellidoPaterno,
              apellidoMaterno: y[0].apellidoMaterno,
              sexo: y[0].sexo,
              telefonoFijo: y[0].telefonoFijo,
              telefonoMovil: y[0].telefonoMovil,
              edad: y[0].edad,
              direccion: y[0].direccion
            });
          } else {
            this.DatosUsuarioForm.patchValue({
              email: x.email,
            });
          }
        });
      }
    });
  }

  alActualizarCarrito(pmtProducto, pmtTipoActualizacion): void{
    if (pmtTipoActualizacion === '+'){
      this.servicioCarritoDeCompras.actualizarCantidad(pmtProducto, pmtTipoActualizacion);
    } else if (pmtTipoActualizacion === '-' && pmtProducto.cantidad > 1) {
      this.servicioCarritoDeCompras.actualizarCantidad(pmtProducto, pmtTipoActualizacion);
    } else if (pmtTipoActualizacion === 'x') {
      this.servicioCarritoDeCompras.eliminarProductoLS(pmtProducto);
    }
    this.carritoDeCompras = this.servicioCarritoDeCompras.consultar();
  }

  alConfirmarCarrito(): void {
    this.pasoCompra = 'datosEnvio';
  }

  alDatosDeEnvio(): void{
    this.pasoCompra = 'datosFormaPago';
    const datosUsuario = this.DatosUsuarioForm.value;
    try{
      if (datosUsuario.id !== '') {
        this.servicioUsuarios.editar(datosUsuario);
      } else {
        this.servicioUsuarios.agregar(datosUsuario);
      }
      this.confirmoDatos = true;
    } catch (e) {
      this.toastr.error(e);
    }
  }

  private initConfig(): void {
    const datosUsuario = this.DatosUsuarioForm.value;
    this.payPalConfig = {
        currency: 'MXN',
        clientId: 'AaKYtPeRLmupnI57SDyc9FFRoz4fTRnagHELkWfIM1aFfjmGQ3VjElZk-10xH1kIO8qzznU9CWSpIqln',
        // tslint:disable-next-line: no-angle-bracket-type-assertion
        createOrderOnClient: (data) => <ICreateOrderRequest>
        {
            intent: 'CAPTURE',
            purchase_units: [{
                amount: {
                    currency_code: 'MXN',
                    value: this.carritoDeCompras.importeTotal.toString(),
                    breakdown: {
                        item_total: {
                            currency_code: 'MXN',
                            value: this.carritoDeCompras.importeTotal.toString(),
                        }
                    }
                },
                items: [{
                    name: 'Alimento para Mascotas',
                    quantity: '1',
                    category: 'PHYSICAL_GOODS',
                    unit_amount: {
                        currency_code: 'MXN',
                        value: this.carritoDeCompras.importeTotal.toString(),
                    },
                }]
            }]
        },
        advanced: {
            commit: 'true'
        },
        style: {
            label: 'paypal',
            layout: 'vertical'
        },
        onApprove: (data, actions) => {
            actions.order.get().then(details => {
            });
        },
        onClientAuthorization: (data) => {
          try {
            console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
            this.servicioPedidos.agregar(
              this.DatosUsuarioForm.value,
              this.carritoDeCompras,
              'Tarjeta',
              data
              ).then(x =>
                {
                  this.pedidoConfirmado = x;
                  this.pedidoConfirmado.get().then(y => this.pedido = y.data() as Pedido);
                  this.servicioCarritoDeCompras.reiniciarCarrito();
                  this.toastr.info('Su operación ha sido completada, puede consultar el estado de su pedido en la sección "Mis Pedidos"');
                  this.pasoCompra = 'compraConfirmada';
                });
          } catch (e){
            this.toastr.error(e);
          }
        },
        onCancel: (data, actions) => {
            // console.log('OnCancel', data, actions);
        },
        onError: err => {
            console.log('OnError', err);
            this.toastr.error('Error en el proceso de pago');
        },
        onClick: (data, actions) => {
            // console.log('onClick', data, actions);
        }
    };
  }

  irAlCarrito(): void{
    this.pasoCompra =   'carrito';
  }

  irADatosDeEnvio(): void {
    this.pasoCompra =   'datosEnvio';
  }

  seleccionaFormaPago(pmtFormaPago): void{
    this.formaPago = pmtFormaPago;
  }

  confirmarOrdenDeCompraContraEntrega(): void{
    this.servicioPedidos.agregar(
      this.DatosUsuarioForm.value,
      this.carritoDeCompras,
      'Efectivo',
      null
      ).then(x =>
        {
          this.pedidoConfirmado = x;
          this.pedidoConfirmado.get().then(y =>
            {
              this.pedido = y.data() as Pedido;
            });
          this.servicioCarritoDeCompras.reiniciarCarrito();
          this.toastr.info('Su operación ha sido completada, puede consultar el estado de su pedido en la sección "Mis Pedidos"');
          this.pasoCompra = 'compraConfirmada';
        });
  }

  enviaCorreoVerificacion(): void {
    this.toastr.info('Hemos enviado un mensaje a ' + this.DatosUsuarioForm.get('email').value + ', en él encontraras las instrucciones para verificar tu cuenta de correo electrónico.');
    this.servicioAutenticacion.enviaCorreoVerificacion();
  }
}
