import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Especie } from '../especie';
import { EspeciesService } from '../especies.service';

declare var $: any;

@Component({
  selector: 'app-crear-editar-especie',
  templateUrl: './crear-editar-especie.component.html',
  styleUrls: ['./crear-editar-especie.component.css']
})

export class CrearEditarEspecieComponent implements OnInit, OnChanges {

  @Input()
  especieSeleccionada: Especie = new Especie();

  DatosEspecieForm = new FormGroup({
    id: new FormControl(''),
    nombre: new FormControl('', Validators.required),
    ruteo: new FormControl('', Validators.required),
    estado: new FormControl('')
  });

  constructor(private servicioEspecies: EspeciesService) { }

  ngOnInit(): void {

  }

  ngOnChanges(): void {
    if (this.especieSeleccionada.id){
      this.DatosEspecieForm.patchValue({
        id: this.especieSeleccionada.id,
        nombre: this.especieSeleccionada.nombre,
        ruteo: this.especieSeleccionada.ruteo,
        estado: this.especieSeleccionada.estado
      });
    } else {
      this.DatosEspecieForm.reset();
    }
  }

  alEnviar(): void {
    const especie = this.DatosEspecieForm.value;
    if (!this.especieSeleccionada.id){
      this.servicioEspecies.agregar({...especie });
    }
    else {
      this.servicioEspecies.editar({...especie, id: this.especieSeleccionada.id });
    }
    this.DatosEspecieForm.reset();
    this.especieSeleccionada = new Especie();
    $('#especiesModal').modal('hide');
  }
}
