import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Edad } from './edad';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class EdadesService {

  constructor(private firestore: AngularFirestore) { }

  private coleccionEdades = this.firestore.collection('Edades', ref => ref.orderBy('nombre'));
  private coleccionEdadesActivas = this.firestore.collection('Edades', ref => ref.where('estado', '==', true));
  private edadesActivas: {
    id: string,
    data: Edad
  }[];


  public edades$ = this.coleccionEdades.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const edad = p.payload.doc;
        const id = edad.id;
        return { id, ...(edad.data() as {}) } as Edad;
      });
    })
  );

  public edadesActivas$ = this.coleccionEdadesActivas.snapshotChanges().pipe(
    map(actions => {
      return actions.map(p => {
        const edad = p.payload.doc;
        const id = edad.id;
        return { id, ...(edad.data() as {}) } as Edad;
      });
    })
  );

  async obtenerActivas(): Promise<{id: string, data: Edad}[]>{
    this.edadesActivas = new Array();
    (await this.firestore.collection('Edades', ref => ref.orderBy('orden'))
      .get()
      .toPromise())
      .forEach(element => {
      this.edadesActivas.push({
        id: element.id,
        data: element.data() as Edad
      });
    });
    return this.edadesActivas;
  }

  async agregar(edad: Edad): Promise<void> {
    try {
      await this.coleccionEdades.add
      (
        {
          nombre: edad.nombre,
          ruteo: edad.ruteo,
          estado: edad.estado
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  async editar(edad: Edad): Promise<void> {
    try {
      await this.coleccionEdades
        .doc(edad.id)
        .set(
          {
            nombre: edad.nombre,
            ruteo: edad.ruteo,
            estado: edad.estado
          },
          {
            merge: true
          });
    } catch (err) {
      console.log(err);
    }
  }
}
