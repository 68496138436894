<div class="modal fade" id="galeriaProductosModal" tabindex="-1" aria-labelledby="galeriaProductosModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="galeriaProductosModalLabel">Galeria: {{ productoSeleccionado.nombre }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <form [formGroup]="DatosImagenForm">
                <div class="custom-file">
                  <input  type="file"
                          class="custom-file-input"
                          id="customFileLang"
                          formControlName="archivoPaTrepar"
                          (change)="agregar($event)">
                  <label class="custom-file-label" for="customFileLang">{{leyendaArchivoSeleccionado}}</label>
              </div>
              <div *ngIf="uploadPercent | async as porcentaje" class="progress">
                <div class="progress-bar" role="progressbar" [attr.style]="'width: ' +  porcentaje.toFixed() + '%'" style="width: 50%;" [attr.aria-valuenow]="porcentaje.toFixed()" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{porcentaje.toFixed()}}%</div>
              </div>
              </form>
                <div *ngIf="listaImagenes$ | async as listaImagenes" class="mt-3">
                  <div class="row row-cols-1 row-cols-lg-6">
                    <div class="col mb-4" *ngFor="let e of listaImagenes">
                      <div class="card h-100 text-center">
                        <img [src]="e.url | async" class="card-img-top" width="10%">
                        <div class="card-body">
                          <small class="text-muted">{{e.nombre}}</small>
                        </div>
                        <ul class="list-group list-group-flush">
                          <li class="list-group-item">
                            <button class="btn btn-dark" style="background-color: rgba(34, 142, 157, 1);" (click)='eliminar(e)'>
                              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trash" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
                              </svg>
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>  
              </div>
            </div>
            <div class="modal-footer">
                <button [disabled]="DatosImagenForm.invalid" type="button" id='BotonCargar' class="btn btn-secondary" (click)="cargarImagen()">Cargar archivo</button>
                <button type="button" id='BotonCerrar' class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
            </div>
        </div>
    </div>
</div>