import { DocumentReference } from '@angular/fire/firestore';

export class Usuario {
    id: string;
    email: string;
    nombre: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    sexo: string;
    telefonoFijo: number;
    telefonoMovil: number;
    edad: number;
    direccion: Direccion;
    mascotas: Mascota[];

    constructor(){
        this.direccion = new Direccion();
    }
}

export class Direccion {
    calle: string;
    numeroInterior: string;
    numeroExterior: string;
    colonia: string;
    codigoPostal: string;
    municipio: string;
    estado: string;
    referencias: string;
}

export class Mascota {
    nombre: string;
    especie: DocumentReference;
    nombreEspecie?: string;
    raza: string;
    edad: string;
    padecimientos: string[];
}
