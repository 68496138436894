import { Component, OnInit } from '@angular/core';
import { MarcasService } from './marcas.service';
import { Marca } from './marca';

@Component({
  selector: 'app-catalogo-marcas',
  templateUrl: './catalogo-marcas.component.html',
  styleUrls: ['./catalogo-marcas.component.css']
})

export class CatalogoMarcasComponent implements OnInit {

  constructor(
    private servicioMarca: MarcasService
    ) { }

  public marcas$ = this.servicioMarca.marcas$;
  public marcaSeleccionada: Marca = new Marca();

  ngOnInit(): void {
  }

  alEditar(marca: any): void{
    this.marcaSeleccionada = {
      id: marca.id,
      nombre: marca.nombre,
      ruteo: marca.ruteo,
      slogan: marca.slogan,
      sitioWeb: marca.sitioWeb,
      estado: marca.estado,
      orden: marca.orden,
    } as Marca;
  }

  alCrear(): void{
    this.marcaSeleccionada = new Marca();
  }
}
